import { IconApi, IconBrowser, IconCirclesRelation, IconCodeOff, IconHome, IconHome2, IconKeyframes, IconPhoto, IconPlayerPlay, IconPlugConnected, IconTemplate } from "@tabler/icons-react";
import { NavLink, useNavigate } from "react-router-dom";
import { homePaths } from "../util/constants";
import Logo from "./Logo";
import { useRecoilValue } from "recoil";
import { userState } from "../state/atoms";

export default function DashboardAside() {
  const navigate = useNavigate();
  const user = useRecoilValue(userState);


  return (
    <aside className="hidden lg:flex flex-col justify-between w-64 bg-white text-center border-r dashboard-aside">
      <div className="">
        <div className="flex justify-center border-b h-16 items-center">
          <Logo className="w-36" />
        </div>

        <ul className="list-none p-4">
          <li className="cursor-pointer templates">
            <NavLink to={`/`} className={`flex items-center rounded gap-2 group py-2 px-3 mb-2 text-gray-700 hover:bg-gray-100 font-medium text-sm ${homePaths.includes(window.location.pathname) ? "bg-gray-100" : ""}`}>
              <IconTemplate width={23} height={23} stroke={1.5} className="text-gray-500" /> My Templates
            </NavLink>
          </li>

          <li className="cursor-pointer api-integration">
            <NavLink to={`/api-integration`} className={`flex items-center rounded gap-2 group py-2 px-3 mb-2 text-gray-700 hover:bg-gray-100 border border-dashed ${window.location.pathname.indexOf("api-integration") > -1 ? "bg-gray-100" : ""}`}>
              <IconPlugConnected width={23} height={23} stroke={1.5} className="text-gray-500" />
              <span className="bg-gradient-to-r from-primary to-accent inline-block text-transparent bg-clip-text font-medium text-sm">API Integration</span>
            </NavLink>
          </li>

          <li className="cursor-pointer renders">
            <NavLink to={`/renders`} className={`flex items-center rounded gap-2 group py-2 px-3 mb-2 text-gray-700 hover:bg-gray-100 font-medi font-medium text-sm ${window.location.pathname.indexOf("renders") > -1 ? "bg-gray-100" : ""}`}>
              <IconPhoto width={23} height={23} stroke={1.5} className="text-gray-500" /> Renders
            </NavLink>
          </li>

          <li className="cursor-pointer no-code-integration">
            <NavLink to={`/no-code-integration`} className={`flex items-center rounded gap-2 group py-2 px-3 mb-2 text-gray-700 hover:bg-gray-100 font-medi font-medium text-sm ${window.location.pathname.indexOf("no-code-integration") > -1 ? "bg-gray-100" : ""}`}>
              <IconCodeOff width={23} height={23} stroke={1.5} className="text-gray-500" />
              No-code
              {/* <span className="bg-gradient-to-r from-[#FF4F01] to-[#6C02CE] inline-block text-transparent bg-clip-text font-medi font-medium text-sm">No-code</span> */}
            </NavLink>
          </li>

          <li className="cursor-pointer playground">
            <div className={`flex items-center rounded gap-2 group py-2 px-3 mb-2 text-gray-700 hover:bg-gray-100 font-medi font-medium text-sm cursor-not-allowed`}>
              <IconPlayerPlay width={23} height={23} stroke={1.5} className="text-gray-500" />
              Playground
              <span className="leading-snug text-[10px] font-medium text-xxs px-2 py-0.5 rounded bg-blue-100 text-primary">SOON</span>
              {/* <span className="bg-gradient-to-r from-[#FF4F01] to-[#6C02CE] inline-block text-transparent bg-clip-text font-medi font-medium text-sm">No code</span> */}
            </div>
          </li>
          
          {/* <li className="cursor-pointer embed">
            <NavLink to={user.isAdmin ? `/embed` : ''} className={`flex items-center rounded gap-2 group py-2 px-3 mb-2 text-gray-700 hover:bg-gray-100 font-medi font-medium text-sm ${window.location.pathname.indexOf("embed") > -1 ? "bg-gray-100" : ""}`}>
              <div className={`flex items-center rounded gap-2 group py-2 px-3 mb-2 text-gray-700 hover:bg-gray-100 font-medi font-medium text-sm cursor-not-allowed`}>
                <IconBrowser width={23} height={23} stroke={1.5} className="text-gray-500" />
                Embed
                <span className="leading-snug text-[10px] font-medium text-sm px-2 py-0.5 rounded bg-blue-100 text-primary">SOON</span>
              </div>
            </NavLink>
          </li> */}
        </ul>
      </div>

      {/* <div className="mx-auto flex min-h-screen max-w-screen-sm items-center justify-center">
        <div className="h-36 w-full rounded-md bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 p-1">
          <div className="flex h-full w-full items-center justify-center bg-gray-800 back">
            <h1 className="text-2xl font-black text-white">the quick brown fox jumps over the lazy dog</h1>
          </div>
        </div>
      </div> */}

      <div>
        {/* <div className="w-auto rounded-lg cursor-pointer transition-all ease-in-out duration-500 mx-5
        background-animate bg-gradient-to-r from-primary to-accent leading-tight
        p-0.5 flex items-center justify-center bg-[length:_400%_400%] hover:scale-110"
          onClick={() => { navigate("/upgrade") }}>
          <span className="rounded-md bg-white flex flex-col items-center justify-center gap-1 py-2 w-full h-full">
            Pick a plan to unlock <br />more credits<br />
            <IconCirclesRelation className="text-accent" />
          </span>
        </div> */}

        <div className="w-auto m-5 text-sm leading-tight p-0.5 px-2 flex items-center justify-center">
          <span className="">
            Need help? Contact us at <br /><a href="email:support@templated.io">support@templated.io</a><br />
          </span>
        </div>
      </div>
    </aside>
  );
}
