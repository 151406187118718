import "./AuthPage.scss";
import { Button, Card, Col, Form, Input, Layout, Row } from "antd";
import { useState } from "react";
// import MembersApi from "../api/MembersApi";
import { LeftOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { LogRegLogo } from "../util/LogRegLogo";
import LogoIcon from "../LogoIcon";
import { Mail } from "lucide-react";
import Storage from "../../util/Storage";
import UserApi from "../../api/UserApi";
import AuthApi from "../../api/AuthApi";

export default function ForgotPasswordPage() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [form] = Form.useForm();

  async function submit() {
    setError(false);
    try {
      setLoading(true);
      // await MembersApi.generatePassword(email);
      setSuccess(true);
    } catch (err) {
      setLoading(false);
      setError(true);
    }
  }

  function goBack() {
    window.history.back();
  }

  function handleSubmit(values: any) {
    setLoading(true);

    try {
      AuthApi.resetPassword(values.email).then(resp => {
        setLoading(false);
        setSuccess(true);
      });
    } catch (err) {
      console.error(err);
      setError(true);
    }
  }

  return (
    <div className={"forgot-password-page"}>
      ? <Layout>
        <Row justify="center">
          <Col xl={8} lg={8} md={10} sm={24} xs={24}>
            <Card>
              <LogoIcon size="medium" />

              {!success
                ? <>
                  <h2>Forgot your password?</h2>
                  <h5>Enter the email address you registered with and we'll email you a new password.</h5>

                  {error && <h6 className={"err"}>{error}</h6>}

                  <Form form={form} layout="vertical" onFinish={handleSubmit}>
                    <Form.Item name="email" rules={[{ required: true, message: 'Please enter your email' }]}>
                      <Input prefix={<Mail />} placeholder="Email" size="large" />
                    </Form.Item>

                    <Form.Item>
                      <Button block size="large" htmlType="submit" type="primary" loading={loading}>
                        Reset Password
                      </Button>
                    </Form.Item>
                  </Form>
                </>

                : <>
                  <h2>Password Sent</h2>
                  <h5>
                    Email has been sent to <br /> {form.getFieldValue("email")} with a new password.
                  </h5>
                  <NavLink to={"/signin"}>Sign in</NavLink>
                </>
              }

            </Card>
          </Col>
        </Row>
      </Layout>
    </div>
  );
}
