import { useState } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useRecoilState } from "recoil";
import CookieNotification from "./components/CookieNotification";
import SignInPage from "./components/auth/SignInPage";
import ForgotPasswordPage from "./components/auth/ForgotPasswordPage";
import PasswordResetPage from "./components/auth/PasswordResetPage";
import SignUpPage from "./components/auth/SignUpPage";
import DashboardPage from "./components/pages/DashboardPage";
import Editor from "./components/pages/Editor";
import { tourState, userState } from "./state/atoms";
import DocumentationPage from "./components/pages/DocumentationPage";
import { ConfigProvider } from "antd";

function App() {
  const location = useLocation();
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [user, setUser] = useRecoilState(userState);
  const [isAppLoading, setAppLoading] = useState(false);
  const [accessRemoved, setAccessRemoved] = useState(false);

  // getExistingToken().then(async (resp) => {
  //   const data = await resp.json();
  //   if (data.token) {
  //     Storage.setToken(data.token);
  //   } else {
  //     logout().then(() => {
  //       window.location.href = env.basePath;
  //     })
  //   }
  // }).catch(() => {
  //   window.localStorage.setItem("redirect-path", window.location.pathname);
  //   window.location.href = `/account/login`;
  // });

  // }, [user, isAppLoading, user.id]);

  return (
    <div className="app bg-gray-50 h-full">
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            // colorPrimary: '#ff0000',
            fontFamily: `"Inter", -apple-system, "system-ui"`,
            borderRadius: 4,

            // Alias Token
            // colorBgContainer: '#f6ffed',
          },

          // components: {
          //   Switch: {
          //     colorPrimary: 'green',
          //     colorPrimaryHover: 'green'
          //   },
          // },
        }}
      >
        <CookieNotification />

        <Routes>
          <Route
            path={"/*"}
            element={
              <DashboardPage
                isAppLoading={isAppLoading}
                accessRemoved={accessRemoved}
              />
            }
          />

          <Route path={"/editor"} element={<Editor />} />
          <Route path={"/editor/:id"} element={<Editor />} />

          <Route path={"/signup"} element={<SignUpPage />} />
          <Route
            path={"/signin"}
            element={<SignInPage setAppLoading={setAppLoading} />}
          />
          <Route path={"/forgot-password"} element={<ForgotPasswordPage />} />
          <Route path={"/password/reset"} element={<PasswordResetPage />} />

          <Route path={"/docs"} element={<DocumentationPage />} />
        </Routes>
      </ConfigProvider>
    </div>
  );
}

export default App;
