import React, { useEffect, useState } from 'react';
import { UserDto } from '../generated/edithor-client';
import { Modal } from 'antd';

interface Props {
  user: UserDto;
}

declare global {
  interface Window {
    Tally: any;
  }
}

export default function OnboardingSurvey({ user }: Props) {
  const [closable, setClosable] = useState(true);
  const [open, setOpen] = useState(true);

	useEffect(() => {
		const widgetScriptSrc = 'https://tally.so/widgets/embed.js';

    const load = () => {
      // Load Tally embeds
      if (typeof window.Tally !== 'undefined') {
        window.Tally.loadEmbeds();

        // On submit
        window.addEventListener('message', (e) => {
          if (e?.data?.includes && e?.data?.includes('Tally.FormSubmitted')) {
            const payload = JSON.parse(e.data).payload as any;

            setClosable(true); 
            setOpen(false);
          }
        });
        return;
      }

      // Fallback if window.Tally is not available
      document
        .querySelectorAll('iframe[data-tally-src]:not([src])')
        .forEach((iframeEl: any) => {
          iframeEl.src = iframeEl.dataset.tallySrc;
        });
    };

    // If Tally is already loaded, load the embeds
    if (typeof window.Tally !== 'undefined') {
      load();
      return;
    }

    // If the Tally widget script is not loaded yet, load it
    if (document.querySelector(`script[src="${widgetScriptSrc}"]`) === null) {
      const script = document.createElement('script');
      script.src = widgetScriptSrc;
      script.onload = load;
      script.onerror = load;
      document.body.appendChild(script);
      return;
    }
	}, []);

  return (
    <Modal classNames={{ body: "!p-0", header: "!bg-secondary", content: "!p-0" }} styles={{ mask: { backdropFilter: "blur(5px)" } }}
      maskClosable={false} centered open={open} width={"50%"} closable={closable} onCancel={() => setOpen(false)}
      title={
        <div className="text-white p-6">
          <p className="text-3xl mb-3">Welcome, {user.name}!</p>
          <p className="text-xl font-normal leading-snug">
            We're thrilled to have you with us! <br />
            Please take a few moments to help us understand your automation goals.
          </p>
        </div>
      } >
        
      <iframe
        data-tally-src={`https://tally.so/embed/npGbd8?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1&email=${user.email}&name=${user.name}`}
        loading="lazy"
        className="px-6 mb-4"
        width={"100%"}
        height="216"
        frameBorder={0}
        marginHeight={0}
        marginWidth={0}
      ></iframe>
    </Modal>
  );
};