import { IconCopy, IconExternalLink } from "@tabler/icons-react";
import { Button, Card, Input, Skeleton, Tooltip, message } from "antd";
import { Copy } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userState } from "../../state/atoms";
import CodeBlock from "../CodeBlock";
import { env } from "../../env/env";
import { Helmet } from "react-helmet";

export interface Props {
}

export default function APIIntegrationPage({ }: Props) {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const user = useRecoilValue(userState);
  const [loading, setLoading] = useState(false);

  function copyApiKey() {
    navigator.clipboard.writeText(user.apiKey!).then(() => {
      messageApi.success("API Key copied.");
    });
  }

  return loading || !user.apiKey ? (
    <>
      <Skeleton active paragraph={{ rows: 4 }} />
    </>
  ) : (
    <div>
      <Helmet>
        <title>API Integration | Templated</title>
      </Helmet>

      {contextHolder}

      <Card className="w-fit mb-6">
        <p className="text-lg font-mono tracking-tighter mb-1.5">API Key</p>

        <p className="text-gray-500 leading-relaxed">
          Use your API Key to integrate with our API and generate your renders.<br />
          <span className="text-sm">Keep it private and safe.</span>
        </p>

        <div className="flex mt-3 items-center gap-2">
          <Input value={user.apiKey} readOnly size="large" className="w-96 font-mono" />
          <Tooltip title="Copy your API key">
            <Button size="large" icon={<IconCopy />} onClick={copyApiKey} />
          </Tooltip>
        </div>
      </Card>

      <Card className="w-[700px] mb-4 shadow-sm">
        <p className="text-lg font-mono tracking-tighter mb-1.5">Sample Request</p>

        <p className="text-gray-500 leading-relaxed">
          Here is a sample request to the <span className="font-mono font-bold">/render</span> endpoint to generate an image using your API Key. Use this as a starting point for your API calls.
        </p>

        <div className="w-full mt-3">
          <CodeBlock language="javascript" title="sample request" className="mb-4">
            {
              `fetch('https://api.templated.io/v1/render', {
  method: 'POST',
  body: JSON.stringify({
    "template" : TEMPLATE_ID,
    "layers" : {
      "text-1" : {
        "text" : "This is my text to be rendered",
        "color" : "#FFFFFF", 
        "background" : "#0000FF"
      },
      "image-1": {
        "image_url" : "https://pathtomyphoto.com/123.jpg"
      }
    }
  }),
  headers: {
    'Content-Type' : 'application/json',
    'Authorization' : 'Bearer ${user.apiKey}'
  }
})`
            }
          </CodeBlock>

          <p className="text-gray-500 leading-relaxed">
            Learn more on our <a href={`${env.sitePath}/docs/`}>API Documentation <IconExternalLink height={20} className="inline" /></a>.
          </p>

          <p className="text-gray-500 leading-relaxed">
            If you have questions or need help to integrate please contact us using the chat or email us at <a href="mailto:support@templated.io">support@templated.io</a>
          </p>
        </div>
      </Card>
    </div>
  );
}
