import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { Tooltip } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import { ChevronLeft, ChevronRight } from "lucide-react";

interface Props {
  opened: boolean;
  tooltipPlacement: TooltipPlacement;
  rotate?: boolean;
  invertColors?: boolean
}

export default function CollapsePanelIcon({ opened, tooltipPlacement, rotate = false, invertColors = false}: Props) {
  return (
    // <Tooltip title="Hide" placement={tooltipPlacement} mouseLeaveDelay={0}>
    <div className={`py-1 w-4 rounded-tr-2xl rounded-br-2xl h-14 flex justify-center border-2 border-l-0 border-slate-200 ${opened ? "bg-[#22292C]" : "bg-secondary"} ${rotate ? "rotate-180" : ""} ${invertColors ? "!bg-slate-50" : "" }`}>
      {opened && <IconChevronLeft className={`w-5 h-full ${invertColors ? "text-[#22292C]" : "text-white"}`}/>}
      {!opened && <IconChevronRight className={`w-5 h-full ${invertColors ? "text-[#22292C]" : "text-white"}`}/>}
    </div>
    // </Tooltip>
  );
}
