import { MoveableManagerInterface } from "moveable";
import { Renderer } from "react-moveable/types";
// import util, {roundTo25} from "../../util";
// import {ABLE_OFFSET} from "../interactable-constants";

const DimensionViewable = {
  name: "dimensionViewable",
  props: [],
  events: [],
  render(moveable: MoveableManagerInterface<any, any>, React: Renderer) {
    const rect = moveable.getRect();

    return <div key={"dimension-viewer"} className={"moveable-dimension"} style={{
      position: "absolute",
      left: `${rect.width / 2}px`,
      top: `${rect.height + 12}px`,
    }}>
      {Math.round(rect.offsetWidth)} x {Math.round(rect.offsetHeight)}
    </div>;
  },
} as const;

export default DimensionViewable;
