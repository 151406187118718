export function updateDimentions(width: number = 0, height: number = 0) {
  const canvas = document.getElementById("canvas");

  if (canvas) {
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;
  }
}

export function updateZoom(zoom: number = 0) {
  const canvas = document.getElementById("canvas");

  if (canvas) {
    canvas.style.transform = `scale(${zoom / 50})`;

    // const content = document.getElementsByClassName("content")[0] as any;

    // if (content) {
    //   const canvasComputedStyle = window.getComputedStyle(canvas)

    //   content.style.width = parseFloat(canvasComputedStyle.getPropertyValue('width')) * 2 + "px";
    //   content.style.height = parseFloat(canvasComputedStyle.getPropertyValue('height')) * 2 + "px";
    // }
  }


}

export function dragComponent(component: any, transform: string, moveableRef: any) {
  component.style.transform = transform;
  moveableRef.current.updateRect();
}

export function resizeComponent(component: any, type: string, width: number, height: number, transform: string, moveableRef: any) {
  // if (type === "text") {
  //   const pHeight = component.querySelector('p')!.offsetHeight;
  //   component.style.height = `${pHeight}px`;
  // } else {
    component.style.height = `${height}px`;
  // }

  if (type === "shape") {
    const svg = component.querySelector('svg')!;
    const svgChild = svg.firstElementChild!; // Get the first child element of the SVG.

    if (svgChild) {
      if (svgChild.nodeName.toLowerCase() !== 'line') {
        svgChild.setAttribute('width', `${width}`);
        svgChild.setAttribute('height', `${height}`);
      }

      if (svgChild.nodeName.toLowerCase() === 'line') {
        const line = svgChild as SVGLineElement;
        // svgChild.setAttribute('width', `${width}`);
        // svgChild.setAttribute('height', `${height}`);
        line.setAttribute('x2', `${width}`);
      }

      if (svgChild.nodeName.toLowerCase() === 'ellipse') {
        const ellipse = svgChild as SVGEllipseElement;

        ellipse.setAttribute('cx', `${width / 2}`);
        ellipse.setAttribute('cy', `${height / 2}`);
        ellipse.setAttribute('rx', `${width / 2 - 4}`);
        ellipse.setAttribute('ry', `${height / 2 - 4}`);
      }

      // if (svgChild.nodeName.toLowerCase() === 'line') {
      //   // Handle resizing for line elements
      //   const line = svgChild as SVGLineElement;

      //   const x1 = height - 5;
      //   const y1 = 5;
      //   const x2 = height - 5;
      //   const y2 = height - 5;

      //   // 'x1="95" y1="5" x2="95" y2="95"'

      //   line.setAttribute('x1', `${x1}`);
      //   line.setAttribute('y1', `${y1}`);
      //   line.setAttribute('x2', `${x2}`);
      //   line.setAttribute('y2', `${y2}`);
      // }

      if (svgChild.nodeName.toLowerCase() === 'polygon') {
        const polygon = svgChild as SVGPolygonElement;
        const scaledPoints = [
          [width / 2, 5],
          [width - 5, height - 5],
          [5, height - 5]
        ]
        polygon.setAttribute('points', scaledPoints.map(point => point.join(' ')).join(','));
      }
    }
    if (svgChild.nodeName.toLowerCase() === 'line') {
      svg.setAttribute('viewBox', `0 100 ${width} ${height}`);
    } else {
      svg.setAttribute('viewBox', `0 0 ${width} ${height}`);
      svg.setAttribute('width', `${width}px`);
      svg.setAttribute('height', `${height}px`);
    }
    svg.style.width = `${width}px`;
    svg.style.height = `${height}px`;
  }

  component.style.width = `${width}px`;

  // console.log('> resizeComponent' + width + 'x' + height);
  if (transform) component.style.transform = transform;

  moveableRef.current.updateRect();
}

export function scaleComponent(component: any, transform: string, moveableRef: any) {
  component.style.transform = transform;
  moveableRef.current.updateRect();
}

export function rotateComponent(component: any, transform: string, moveableRef: any) {
  component.style.transform = transform;
  moveableRef.current.updateRect();
}