import { IconLock } from "@tabler/icons-react";
import { Card, Col, Flex, Layout, Row, Tag } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import CodeBlock from "../CodeBlock";
import Logo from "../Logo";
import "./DocumentationPage.scss";

export default function DocumentationPage() {

  useEffect(() => {
    // Select all anchor links that use smooth scrolling
    const anchorLinks = document.querySelectorAll('a[href*="#"]');

    // Add a click event listener to each anchor link
    anchorLinks.forEach(anchor => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault(); // Prevent the default anchor behavior

        const targetId = anchor!.getAttribute('href')!.substring(1); // Extract the target section's ID

        const targetElement = document.getElementById(targetId); // Get the target section element

        if (targetElement) {
          // Scroll to the target section smoothly
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      });
    });
  }, []);

  return (
    <Layout className="documentation-page">
      <Helmet>
        <title>Templated API Documentation</title>
        <meta name="description" content="Templated API Documentation" />
        <meta name="robots" content="all" />
      </Helmet>

      <Sider breakpoint="lg" collapsedWidth="0" width={270} className="">
        <Flex justify="space-between" align="center">
          <NavLink to="/">
            <div className="flex justify-center items-center">
              <Logo className="w-40 h-12" />
            </div>
          </NavLink>
          <small>API V1</small>
        </Flex>

        <ul>
          <li><a href="#introduction">Introduction</a></li>
          <li><a href="#authentication">Authentication</a></li>

          <li className="title">RESOURCES</li>
          <li>
            <a href="#renders">Renders</a>

            <ul>
              <li><a href="#render-object">The render object</a></li>
              <li className="underline"><a href="#create-render">Create a render</a></li>
              <li><a href="#retrieve-render">Retrieve a render</a></li>
              {/* <li><a href="#render-examples">Examples</a></li> */}
            </ul>
          </li>

          <li>
            <a href="#renders">Templates</a>

            <ul>
              <li><a href="#template-object">The template object</a></li>
              <li><a href="#retrieve-template">Retrieve a template</a></li>
              <li><a href="#list-all-templates">List all templates</a></li>
              <li><a href="#list-template-layers">List template layers</a></li>
              <li><a href="#list-template-renders">List template renders</a></li>
              {/* <li><a href="#render-examples">Examples</a></li> */}
            </ul>
          </li>

          {/* <li><a href="#account">Account</a></li> */}
        </ul>
      </Sider>

      <Content className="!bg-slate-50">
        <Row className="section" id="introduction">
          <Col span={24}>
            <Row>
              <Col span={24}>
                <h1>Templated API Documentation</h1>
              </Col>
            </Row>

            <Row>
              <Col md={12} sm={24}>
                <p>Templated is a service that auto generate images and PDFs.</p>

                <ol className="list-disc mt-2">
                  <li>Design your template once in our editor</li>
                  <li>Integrate with our API</li>
                  <li>Render multiple assets with your template</li>
                </ol>

                {/* <p>See examples here.</p> */}
              </Col>

              <Col md={12} sm={24}>
                <CodeBlock language="json" title="base url">
                  {
                    `https://api.templated.io`
                  }
                </CodeBlock>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="section" id="authentication">
          <Col span={24}>
            <Row>
              <Col span={24}>
                <h1>Authentication</h1>
              </Col>
            </Row>

            <Row>
              <Col md={12} sm={24}>
                <p>Templated uses API keys to allow access to the API. You can get an API key by creating a project in Templated.</p>

                <p>Templated expects the API key to be included in all API requests to the server in a header that looks like the following:</p>

                <div style={{ width: "fit-content" }}>
                  <CodeBlock language="javascript">
                    Authorization: Bearer API_KEY
                  </CodeBlock>
                </div>

              </Col>

              <Col md={12} sm={24}>
                <CodeBlock language="javascript" title="endpoint">
                  {
                    `GET /v1/auth`
                  }
                </CodeBlock>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="section" id="renders">
          <Col span={24}>
            <Row>
              <Col span={24}>
                <h1>Renders</h1>
              </Col>
            </Row>

            <Row>
              <Col md={12} sm={24}>
                <p>Renders are the main resource on Templated.</p>

                <p>You generate renders by sending a POST request with a template id and a list of template layer changes you want to apply. These layer changes can be things like: changing the text, changing the images or changing the colors.</p>

                <p>Templated will respond with JPG and PNG (and PDF, if requested) formats of the new Render you have requested.</p>
              </Col>

              <Col md={12} sm={24}>
                <CodeBlock language="javascript" title="endpoints">
                  {
                    `POST /v1/render
GET /v1/render/:id
GET /v1/renders
`
                  }
                </CodeBlock>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="section" id="render-object">
          <Col span={24}>
            <Row>
              <Col span={24}>
                <h1>The render object</h1>
              </Col>
            </Row>

            <Row>
              <Col md={12} sm={24}>
                <h2>Attributes</h2>

                <p>These attributes are populated by Templated. All other attributes of the object are set by the user at the time of creation.</p>

                <Card>
                  <p>
                    <code className="param">id</code> <Tag>string</Tag><br />
                    The unique ID for this object.
                  </p>

                  <p>
                    <code className="param">render_url</code> <Tag>string</Tag><br />
                    The final, rendered render url. This will be null to begin with and populates when the render has the status <b>completed</b>.
                  </p>

                  <p>
                    <code className="param">created_at</code> <Tag>string</Tag><br />
                    The date and time the render was created.
                  </p>

                  <p>
                    <code className="param">status</code> <Tag>string</Tag><br />
                    The current status of the render; pending, completed or failed.
                  </p>

                  <p>
                    <code className="param">template</code> <Tag>string</Tag><br />
                    The ID of the template used to generate the render.
                  </p>

                  <p>
                    <code className="param">webhook_url</code> <Tag>string</Tag><br />
                    The webhook URL that will be called when the render is completed.
                  </p>

                  {/* <p>
                    <code className="param">self</code> <Tag>string</Tag><br />
                    The permalink to this object.
                  </p> */}

                  

                  {/* <p>
                    <code className="param">webhook_response_code</code> <Tag>integer</Tag><br />
                    The HTTP response code received by the webhook_url.
                  </p> */}
                </Card>
              </Col>

              <Col md={12} sm={24}>
                <CodeBlock language="json" title="sample object">
                  {
                    `{
  "id": "ce424057-6b54-41bb-afec-adc35a2b9175",
  "render_url": "https://templated-assets.s3.amazonaws.com/render/ce424057-6b54-41bb-afec-adc35a2b9175.jpg",
  "created_at": "2023-10-02T10:00:00.077Z",
  "status": "completed",
  "template": "1f1231-dasd123-fsdf12312-fds4123-asdas23",
  "webhook_url": "https://webhook-to-be-called.com/webhook"
}`
                  }
                </CodeBlock>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="section" id="create-render">
          <Col span={24}>
            <Row>
              <Col span={24}>
                <h1>Create a render</h1>
              </Col>
            </Row>

            <Row>
              <Col md={12} sm={24}>
                <p>Creating a render on Templated is achieved via this endpoint.</p>

                <p>This endpoint responds with 202 Accepted after which your render will be queued to generate. Renders are usually rendered within a few seconds. When completed, the status changes to completed.</p>

                <p>You can poll the GET endpoint for status updates or use a webhook to get the final render posted to you.</p>

                <p>This endpoint supports synchronous generation.</p>

                <h2>Parameters</h2>

                <Card>
                  <p>
                    <code className="param">template</code> <Tag>string</Tag><Tag color="red">REQUIRED</Tag><br />
                    The template id that you want to use.
                  </p>

                  <p>
                    <code className="param">webhook_url</code> <Tag>string</Tag><br />
                    A url to POST the full Render object to upon rendering completed.
                  </p>

                  {/* <p>
                    <code className="param">transparent</code> <Tag>boolean</Tag><br />
                    Render a PNG with a transparent background. Default is false.
                  </p> */}

                  <p>
                    <code className="param">format</code> <Tag>boolean</Tag><br />
                    Render format (<code className="param">jpg</code>, <code className="param">png</code>, <code className="param">webp</code> or <code className="param">pdf</code>). Default is <code className="param">jpg</code>.
                  </p>

                  <p>
                    <code className="param">layers</code> <Tag>array</Tag><Tag color="red">REQUIRED</Tag><br />
                    An object of layers that will be updated in the template.<br />
                    The object key is the layer name and the value is an object with the following properties to override the template layers:
                  </p>

                  <Card className="mt-4">
                    <p>
                      <code className="param">text</code> <Tag>string</Tag><br />
                      Replacement text you want to use.
                    </p>

                    <p>
                      <code className="param">image_url</code> <Tag>string</Tag><br />
                      Replacement image src for an image layer.
                    </p>

                    <p>
                      <code className="param">color</code> <Tag>string</Tag><br />
                      Color in hex format e.g. "#FF0000".
                    </p>

                    <p>
                      <code className="param">background</code> <Tag>string</Tag><br />
                      Background color in hex format e.g. "#FF0000".
                    </p>

                    <p>
                      <code className="param">font_family</code> <Tag>string</Tag><br />
                      Change the font.
                    </p>

                    {/* <p>
                      <code className="param">text_align_h</code> <Tag>string</Tag><br />
                      Horizontal alignment (left, center, right)
                    </p>

                    <p>
                      <code className="param">text_align_v</code> <Tag>string</Tag><br />
                      Vertical alignment (top, center, bottom)
                    </p>

                    <p>
                      <code className="param">font_family_2</code> <Tag>string</Tag><br />
                      Change the secondary font.
                    </p>

                    <p>
                      <code className="param">color_2</code> <Tag>string</Tag><br />
                      Change the secondary font color.
                    </p>

                    <p>
                      <code className="param">effect</code> <Tag>string</Tag><br />
                      Change the effect.
                    </p>

                    <p>
                      <code className="param">fill_type</code> <Tag>string</Tag><br />
                      Change the fill type (fill, fit).
                    </p> */}

                    <p>
                      <code className="param">border_width</code> <Tag>integer</Tag><br />
                      Width of the object border.
                    </p>

                    <p>
                      <code className="param">border_color</code> <Tag>string</Tag><br />
                      Border color in hex format e.g. "#FF0000".
                    </p>

                    <p>
                      <code className="param">hide</code> <Tag>boolean</Tag><br />
                      Set to true to hide the layer.
                    </p>
                  </Card>
                </Card>
              </Col>

              <Col md={12} sm={24}>
                <CodeBlock language="javascript" title="endpoint" className="mb-4">
                  {
                    `POST /v1/render`
                  }
                </CodeBlock>

                <CodeBlock language="javascript" title="sample request">
                  {`fetch('https://api.templated.io/v1/render', {
  method: 'POST',
  body: JSON.stringify(
    {
      "template" : TEMPLATE_ID,
      "layers" : {
        "text-1" : {
          "text" : "This is my text to be rendered",
          "color" : "#FFFFFF", 
          "background" : "#0000FF"
        },
        "image-1": {
          "image_url" : "https://pathtomyphoto.com/123.jpg"
        }
      }
    }
  ),
  headers: {
    'Content-Type' : 'application/json',
    'Authorization' : \`Bearer \${API_KEY}\`
  }
})`
                  }
                </CodeBlock>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="section" id="retrieve-render">
          <Col span={24}>
            <Row>
              <Col span={24}>
                <h1>Retrieve a render</h1>
              </Col>
            </Row>

            <Row>
              <Col md={12} sm={24}>
                <p>Retrieves a single Render object referenced by its unique ID.</p>

                <h2>Parameters</h2>

                <Card>
                  <p>
                    <code className="param">id</code> <Tag>string</Tag><Tag color="red">REQUIRED</Tag><br />
                    The render id that you want to retrieve.
                  </p>
                </Card>
              </Col>

              <Col md={12} sm={24}>
                <CodeBlock language="javascript" title="endpoint" className="mb-4">
                  {
                    `GET /v1/render/:id`
                  }
                </CodeBlock>

                <CodeBlock language="javascript" title="sample request">
                  {
                    `fetch(\`https://api.templated.io/v1/render/\${id}\`, {
  method: 'GET',
  headers: {
    'Authorization' : \`Bearer \${API_KEY}\`
  }
})`
                  }
                </CodeBlock>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="section" id="list-all-renders">
          <Col span={24}>
            <Row>
              <Col span={24}>
                <h1>List all renders</h1>
              </Col>
            </Row>

            <Row>
              <Col md={12} sm={24}>
                <p>Lists renders inside of a template.</p>

                <h2>Parameters</h2>

                <Card>
                  <p>
                    <code className="param">page</code> <Tag>integer</Tag><br />
                    The page of results you would like to retrieve. The API returns 25 items per page.
                  </p>

                  <p>
                    <code className="param">limit</code> <Tag>integer</Tag><br />
                    The API returns 25 items per page by default but you can request up to 100 using this parameter.
                  </p>
                </Card>
              </Col>

              <Col md={12} sm={24}>
                <CodeBlock language="javascript" title="endpoint" className="mb-4">
                  {
                    `GET /v1/renders`
                  }
                </CodeBlock>

                <CodeBlock language="javascript" title="sample request">
                  {
                    `fetch(\`https://api.templated.io/v1/renders/\${id}\`, {
  method: 'GET',
  headers: {
    'Authorization' : \`Bearer \${API_KEY}\`
  }
})`
                  }
                </CodeBlock>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="section" id="template-object">
          <Col span={24}>
            <Row>
              <Col span={24}>
                <h1>The template object</h1>
              </Col>
            </Row>

            <Row>
              <Col md={12} sm={24}>
                <h2>Attributes</h2>
                <p>These attributes define the properties of a template. The template object is used to store template data, including dimensions, user information, and category details.</p>

                <Card>
                  <p>
                    <code className="param">id</code> <Tag>string</Tag><br />
                    The unique UUID for the template.
                  </p>

                  <p>
                    <code className="param">name</code> <Tag>string</Tag><br />
                    The name of the template.
                  </p>

                  <p>
                    <code className="param">description</code> <Tag>string</Tag><br />
                    A brief description of the template.
                  </p>

                  <p>
                    <code className="param">width</code> <Tag>integer</Tag><br />
                    The width of the template in pixels.
                  </p>

                  <p>
                    <code className="param">height</code> <Tag>integer</Tag><br />
                    The height of the template in pixels.
                  </p>

                  <p>
                    <code className="param">thumbnail</code> <Tag>string</Tag><br />
                    URL of the template's thumbnail image.
                  </p>

                  <p>
                    <code className="param">layersCount</code> <Tag>integer</Tag><br />
                    The number of layers (not locked) of the template.
                  </p>

                  {/* <p>
                    <code className="param">category</code> <Tag>Category object</Tag><br />
                    The category this template belongs to.
                  </p>

                  <p>
                    <code className="param">tags</code> <Tag>string</Tag><br />
                    Comma-separated tags associated with the template.
                  </p> */}

                  <p>
                    <code className="param">user</code> <Tag>User object</Tag><br />
                    The user who created or owns the template.
                  </p>
                </Card>
              </Col>

              <Col md={12} sm={24}>
                <CodeBlock language="json" title="sample Template object">
                  {
                    `{
  "id": "306c724a-d138-486a-a601-0b2a9ced52be",
  "name": "Twitter Bubble Square Template",
  "description": "This is a sample template for demonstration.",
  "width": 1024,
  "height": 1024,
  "thumbnail": "https://templated-assets.s3.us-east-1.amazonaws.com/public/thumbnail/306c724a-d138-486a-a601-0b2a9ced52be.webp",
  "user": {
    "id": "872s0atn-l4o5-09g9-gth2-oy7f79df6tuw",
    "name": "Mark Doe"
  }
}`
                  }
                </CodeBlock>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="section" id="retrieve-template">
          <Col span={24}>
            <Row>
              <Col span={24}>
                <h1>Retrieve a template</h1>
              </Col>
            </Row>

            <Row>
              <Col md={12} sm={24}>
                <p>Retrieves a single template object referenced by its unique ID.</p>

                <h2>Parameters</h2>

                <Card>
                  <p>
                    <code className="param">id</code> <Tag>string</Tag><Tag color="red">REQUIRED</Tag><br />
                    The template id that you want to retrieve.
                  </p>
                </Card>
              </Col>

              <Col md={12} sm={24}>
                <CodeBlock language="javascript" title="endpoint" className="mb-4">
                  {
                    `GET /v1/template/:id`
                  }
                </CodeBlock>

                <CodeBlock language="javascript" title="sample request">
                  {
                    `fetch(\`https://api.templated.io/v1/template/\${id}\`, {
  method: 'GET',
  headers: {
    'Authorization' : \`Bearer \${API_KEY}\`
  }
})`
                  }
                </CodeBlock>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="section" id="list-all-templates">
          <Col span={24}>
            <Row>
              <Col span={24}>
                <h1>List all templates</h1>
              </Col>
            </Row>

            <Row>
              <Col md={12} sm={24}>
                <p>Lists all templates of an user.</p>

                {/* <h2>Parameters</h2>

                <Card>
                  <p>
                    <code className="param">page</code> <Tag>integer</Tag><br />
                    The page of results you would like to retrieve. The API returns 25 items per page.
                  </p>

                  <p>
                    <code className="param">limit</code> <Tag>integer</Tag><br />
                    The API returns 25 items per page by default but you can request up to 100 using this parameter.
                  </p>
                </Card> */}
              </Col>

              <Col md={12} sm={24}>
                <CodeBlock language="javascript" title="endpoint" className="mb-4">
                  {
                    `GET /v1/templates`
                  }
                </CodeBlock>

                <CodeBlock language="javascript" title="sample request">
                  {
                    `fetch(\`https://api.templated.io/v1/render/\${id}\`, {
  method: 'GET',
  headers: {
    'Authorization' : \`Bearer \${API_KEY}\`
  }
})`
                  }
                </CodeBlock>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="section" id="list-template-layers">
          <Col span={24}>
            <Row>
              <Col span={24}>
                <h1>List template layers</h1>
              </Col>
            </Row>

            <Row>
              <Col md={12} sm={24}>
                <p>
                  Lists all layers of a template.<br />
                  <span className="flex items-center"><IconLock width={20} height={20} className="mr-1" /> Locked layers will not be returned.</span>
                </p>

                <h2>Parameters</h2>

                <Card>
                  <p>
                    <code className="param">id</code> <Tag>string</Tag><Tag color="red">REQUIRED</Tag><br />
                    The template id that you want to retrieve the layers.
                  </p>
                </Card>
              </Col>

              <Col md={12} sm={24}>
                <CodeBlock language="javascript" title="endpoint" className="mb-4">
                  {
                    `GET /v1/template/:id/layers`
                  }
                </CodeBlock>

                <CodeBlock language="javascript" title="sample request">
                  {
                    `fetch(\`https://api.templated.io/v1/template/\${id}/layers\`, {
  method: 'GET',
  headers: {
    'Authorization' : \`Bearer \${API_KEY}\`
  }
})`
                  }
                </CodeBlock>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="section" id="list-template-renders">
          <Col span={24}>
            <Row>
              <Col span={24}>
                <h1>List template renders</h1>
              </Col>
            </Row>

            <Row>
              <Col md={12} sm={24}>
                <p>List all renders of a template.</p>

                <h2>Parameters</h2>

                <Card>
                  <p>
                    <code className="param">id</code> <Tag>string</Tag><Tag color="red">REQUIRED</Tag><br />
                    The template id that you want to retrieve the renders.
                  </p>

                  <p>
                    <code className="param">page</code> <Tag>integer</Tag><br />
                    The page of results you would like to retrieve. The initial page is 0.
                  </p>

                  <p>
                    <code className="param">limit</code> <Tag>integer</Tag><br />
                    The API returns 25 items per page by default but you can request up to 100 using this parameter.
                  </p>
                </Card>
              </Col>

              <Col md={12} sm={24}>
                <CodeBlock language="javascript" title="endpoint" className="mb-4">
                  {
                    `GET /v1/renders`
                  }
                </CodeBlock>

                <CodeBlock language="javascript" title="sample request">
                  {
                    `fetch(\`https://api.templated.io/v1/renders/\${id}?page=2&limit=50\`, {
  method: 'GET',
  headers: {
    'Authorization' : \`Bearer \${API_KEY}\`
  }
})`
                  }
                </CodeBlock>
              </Col>
            </Row>
          </Col>
        </Row>


      </Content>
    </Layout>
  );
}
