import { AxiosPromise } from "axios";
import { FolderDto, TemplateDto, FolderResourceApi } from "../generated/edithor-client"; // Adjust the import path as necessary
import { apiConfig, ops } from "./config";

const api = new FolderResourceApi(apiConfig);

const FolderApi = {
  get(id: string): AxiosPromise<FolderDto> {
    return api.getFolder(id, ops());
  },

  create(dto: FolderDto): AxiosPromise<FolderDto> {
    return api.createFolder(dto, ops());
  },

  update(id: string, dto: FolderDto): AxiosPromise<FolderDto> {
    return api.updateFolder(id, dto, ops());
  },

  remove(id: string) {
    return api.deleteFolder(id, ops());
  },

  getFolderTemplates(id: string): AxiosPromise<TemplateDto[]> {
    return api.getFolderTemplates(id, ops());
  },
};

export default FolderApi;
