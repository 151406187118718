import { IconDots, IconFolder, IconFolderFilled, IconFolderPlus, IconFolderSymlink, IconPlus, IconTrash } from "@tabler/icons-react";
import { Button, Drawer, Dropdown, Flex, Form, Input, MenuProps, Popconfirm, Tooltip } from "antd";
import { useState } from "react";
import { FolderDto, UserDto } from "../generated/edithor-client";
import FolderApi from "../api/FolderApi";
import { AlertCircle, ArrowRight } from "lucide-react";
import { useNavigate } from "react-router-dom";

interface Props {
  folders: FolderDto[],
  setFolders: any,
  selectedFolder: FolderDto | null,
  setSelectedFolder: any,
  setLoading: any,
  setFilteredTemplates: any,
  setTemplates: any,
  user: UserDto,
  messageApi: any
}

export default function Folders({ folders, setFolders, selectedFolder, setSelectedFolder, setLoading, setTemplates, setFilteredTemplates, user, messageApi }: Props) {
  const [createFolderDrawerOpen, setCreateFolderDrawerOpen] = useState(false);

  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();

  function selectFolder(folder: FolderDto) {
    setLoading(true);
    setSelectedFolder(folder);

    FolderApi.getFolderTemplates(folder.id!).then(resp => {
      setTemplates(resp.data);
      setFilteredTemplates(resp.data);
      setLoading(false);
    });
  }

  async function handleSubmit(values: any) {
    form.validateFields().then(values => {
      setLoading(true);

      values.userId = user.id

      FolderApi.create(values).then(resp => {
        console.log(resp.data);
        setFolders([...folders, resp.data]);
        setLoading(false);
      });
    });
  }

  function copyFolderId(folder: FolderDto) {
    navigator.clipboard.writeText(folder.id!).then(() => {
      messageApi.success(<>You copied the <span className="font-bold">{folder.name}</span> folder ID: <br /> <b>{folder.id}</b></>);
    });
  }

  return (
    <div className="flex justify-between items-end w-full pb-6">
      <div>
        {selectedFolder &&
          <div className="text-gray-600 text-base">
            <span className="hover:underline cursor-pointer"
              onClick={() => {
                setSelectedFolder(null);
                // navigate("/");
              }}>
              All Templates
            </span> <ArrowRight size={15} className="inline-block" /> <IconFolderFilled size={25} className="inline-block text-gray-400" /> {selectedFolder.name}
          </div>
        }

        {!selectedFolder && folders.length > 0 &&
          <div>
            <p className="pb-2 text-xs uppercase text-gray-400">Folders</p>

            <div className="flex items-center flex-wrap gap-3">
              {folders.map(folder =>
                <div key={folder.id}
                  onClick={() => selectFolder(folder)}
                  className="px-3 py-2 border rounded border-0 cursor-pointer bg-white flex justify-between gap-2 items-center hover:bg-gray-200 text-sm w-60 transition-all group">
                  <div className="flex items-center gap-2">
                    <div>
                      <IconFolderFilled stroke={1.5} size={25} className="text-gray-400" />
                    </div>

                    <div className="flex flex-col w-36">
                      <span className="block text-sm truncate">{folder.name}</span>
                      {/* <span className="block text-xs text-gray-400">0 templates</span> */}
                    </div>
                  </div>

                  <div className="opacity-0 group-hover:opacity-100 transition-all">
                    <Dropdown
                      overlayClassName=""
                      trigger={["click"]}
                      placement="bottomLeft"
                      menu={{
                        items: [
                          // {
                          //   key: '1',
                          //   danger: true,
                          //   label: (
                          //     <Popconfirm
                          //       placement="top"
                          //       arrow={false}
                          //       title="Delete template"
                          //       icon={<AlertCircle style={{ color: 'red', marginRight: 10 }} width={15} />}
                          //       description={`Are you sure you want to delete ${folder.name}?`}
                          //       // onConfirm={() => remove(template)}
                          //       okText="Yes"
                          //       cancelText="No">
                          //       <Flex align="center" gap={8}>
                          //         <IconTrash size={18} />
                          //         Delete
                          //       </Flex>
                          //     </Popconfirm>
                          //   ),
                          // },
                          // {
                          //   key: '2',
                          //   label: (
                          //     <Popconfirm
                          //       placement="top"
                          //       arrow={false}
                          //       title="Duplicate template"
                          //       icon={false}
                          //       description={`Are you sure you want to duplicate ${template.name}?`}
                          //       onConfirm={() => copy(template)}
                          //       okText="Yes"
                          //       cancelText="No">
                          //       <Flex align="center" gap={8}>
                          //         <IconCopy size={18} className="text-gray-500" />
                          //         Duplicate
                          //       </Flex>
                          //     </Popconfirm>
                          //   ),
                          // },
                          {
                            key: '3',
                            // disabled: true,
                            label: (
                              <Flex align="center" gap={8} onClick={(e) => { e.stopPropagation(); copyFolderId(folder); }}>
                                <span className="font-mono font-bold px-1">ID</span>
                                Copy folder ID
                              </Flex>
                            ),
                          },

                          // {
                          //   key: '5',
                          //   label: (
                          //     <Flex align="center" gap={8} onClick={() => navigate('/api-integration')}>
                          //       <IconPlugConnected />
                          //       Automate
                          //     </Flex>
                          //   ),
                          // },
                        ] as MenuProps['items']
                      }}
                    >
                      <IconDots className="text-gray-600 hover:bg-gray-100 p-1 rounded transition-all" size={30} onClick={(e) => e.stopPropagation()} />
                    </Dropdown>
                  </div>
                </div>
              )}
            </div>
          </div>
        }
      </div>

      {!selectedFolder &&
        <Tooltip title="Create new folder" placement="bottomRight">
          <Button type="text" size="large" className="!px-2 text-gray-400" icon={<IconFolderPlus size={25} className="" />} onClick={() => setCreateFolderDrawerOpen(true)}>
          </Button>
        </Tooltip>
      }

      <Drawer
        title={<div className="text-center">Create new folder</div>}
        placement="right"
        size="default"
        onClose={() => { setCreateFolderDrawerOpen(false); }}
        open={createFolderDrawerOpen}
      >
        <div>
          <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <Form.Item label="Folder name" name="name" rules={[{ required: true, message: 'Please enter the folder name' }]}>
              <Input prefix={<IconFolder className="text-gray-300 mr-1" />} placeholder="Name your folder" size="large" />
            </Form.Item>

            <Form.Item>
              <Button block size="large" htmlType="submit" type="primary" loading={saving} className="!my-2">
                Create
              </Button>
            </Form.Item>
          </Form>

        </div>
      </Drawer>
    </div >
  )
}
