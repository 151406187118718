import { LoadingOutlined } from "@ant-design/icons";
import { Empty, Flex, Radio, RadioChangeEvent, Space } from "antd";
import Search from "antd/es/input/Search";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import Masonry from "react-responsive-masonry";
import AssetApi from "../../api/AssetApi";
import { env } from "../../env/env";
import "./AsideImages.scss";

const pixabayApiKey = '40600584-2001427cbb5aac3027160097e';

export interface AsideImagesProps {
  addComponent: any;
}

export default function AsideImages({ addComponent }: AsideImagesProps) {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("background");
  const [type, setType] = useState("");
  const [pixabayImages, setPixabayImages] = useState([] as any[]);
  const containerRef = useRef<HTMLDivElement | null>(null);

  let allowLoad = true;

  const pageRef = useRef(page);
  pageRef.current = page;

  useEffect(() => {
    getPixabayImages(query, 1, type);
  }, [query, type]);

  useEffect(() => {
    getPixabayImages(query, page, type);
  }, [page]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", () => {
        if (allowLoad &&
          containerRef.current &&
          containerRef.current.scrollTop + containerRef.current.clientHeight >= containerRef.current.scrollHeight) {
          const nextPage = pageRef.current + 1;
          setPage(nextPage);

          allowLoad = false;
          setTimeout(() => {
            allowLoad = true;
          }, 1000);
        }
      });
    }
    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", {} as any);
      }
    };
  }, [containerRef, page]);

  function getPixabayImages(q: string, p: number, t: string) {
    if (p === 1) setLoading(true);

    axios.get(`https://pixabay.com/api/?key=${pixabayApiKey}&q=${q}&per_page=40&page=${p}&image_type=${t}`).then((response) => {
      const resp = response.data;
      if (p === 1) {
        setPixabayImages(resp.hits);
        if (resp.hits) {
          resp.hits.forEach((hit: any) => {
            // Url to download svg
            // https://pixabay.com/images/download/grooming-1801287.svg
            const match = hit.pageURL.match(/\/vectors\/([^\/-]+)/);
            const name = match && match[1];
            const id = hit.id;

            // console.log(hit.pageURL);
            // console.log(`https://pixabay.com/images/download/${name}-${id}.svg`);
          });
        }
        // console.log(resp.hits);
      } else {
        const newImages = [...pixabayImages, ...resp.hits];
        setPixabayImages(newImages);
        // console.log(newImages);
      }

      setLoading(false);
    }).catch((error) => {
      console.error("Error fetching Pixabay images:", error);
      setLoading(false);
    });
  }

  function onSearch(value: string) {
    setQuery(value);
    setPage(1);
    setPixabayImages([]);
  }

  const onChangeType = ({ target: { value } }: RadioChangeEvent) => {
    setType(value)
  };

  async function addImageComponent(ev: any, image: any) {
    const newComponent = addComponent(ev, "image");
    const img = newComponent.firstChild;
    const originalSrc = img.src

    const path = `public/pixabay-images/${image.id}`;
    const url = `${env.s3Path}${path}`;

    const tempImage = new Image();
    tempImage.src = url;

    tempImage.onload = function () {
      img.src = url;
    }

    tempImage.onerror = function () {
      AssetApi.uploadAsset(image.largeImageURL, path).then(resp => {
        img.src = url;
      });
    }
  }

  return (
    <div className="aside-images">
      <Flex vertical gap={12} style={{ height: "100%" }}>
        <Space direction="vertical" size={"small"}>
          <Search loading={loading} size="large" placeholder="Search for images..." allowClear onSearch={onSearch} />

          <Radio.Group
            options={[
              { label: 'All', value: '' },
              { label: 'Photo', value: 'photo' },
              { label: 'Vector', value: 'vector' },
            ]}
            onChange={onChangeType}
            value={type}
            optionType="button"
            buttonStyle="solid"
            className="image-type-selector"
            size="large"
          />
        </Space>

        <div ref={containerRef} className="scroller">
          {loading ? (
            <div>
              <LoadingOutlined />
            </div>
          ) : pixabayImages.length === 0 ? (
            <Empty />
          ) : (
            <>
              <Masonry gutter="4px">
                {pixabayImages.map((image: any) => (
                  <div key={image.id} className="component-template image" onClick={(ev) => addImageComponent(ev, image)}>
                    <img src={image.previewURL} alt={image.tags} draggable="false" />
                  </div>
                ))}
              </Masonry>
            </>
          )}
        </div>
      </Flex>
    </div>
  );
}
