import { LoadingOutlined } from "@ant-design/icons";
import { Empty, Flex, Popconfirm, Radio, RadioChangeEvent, Space, Tooltip } from "antd";
import Search from "antd/es/input/Search";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import Masonry from "react-responsive-masonry";
import AssetApi from "../../api/AssetApi";
import { env } from "../../env/env";
import { TemplateDto } from "../../generated/edithor-client";
import TemplateApi from "../../api/TemplateApi";


export interface Props {
  selectTemplate: any;
}

export default function AsideTemplatesGallery({ selectTemplate }: Props) {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("background");
  const [templates, setTemplates] = useState([] as TemplateDto[]);
  const containerRef = useRef<HTMLDivElement | null>(null);

  let allowLoad = true;

  const pageRef = useRef(page);
  pageRef.current = page;

  useEffect(() => {
    setLoading(true);

    TemplateApi.getMasterTemplates().then(resp => {
      setTemplates(resp.data);
      setLoading(false);
    });
  }, []);

  return (
    <div className="aside-templates-gallery h-full">
      <Flex vertical gap={12} style={{ height: "100%" }}>
        <Space direction="vertical" size={"small"}>
          <h4>Pick a template from our gallery</h4>
          {/* <Search loading={loading} size="large" placeholder="Search for images..." allowClear onSearch={onSearch} /> */}
        </Space>

        <div ref={containerRef} className="block overflow-y-auto w-full h-full">
          {loading ? (
            <div>
              <LoadingOutlined />
            </div>
          ) : templates.length === 0 ? (
            <Empty />
          ) : (
            <div key="masonry-wrapper" className="">
              <Masonry gutter="10px" columnsCount={2} className="">
                {templates.map((template: any) => (
                  <Popconfirm
                    placement="left"
                    arrow={false}
                    title="Create new template"
                    icon={false}
                    description={
                      <>
                        Do you want to create a new template from <span className="underline">{template.name.trim()}</span>? <br />
                        You will not lose your current template design and layers.
                      </>
                    }
                    onConfirm={() => selectTemplate(template)}
                    okText="Yes"
                    cancelText="Cancel">
                    <Tooltip title={template.name} placement="bottom" className="text-center">
                      <div key={template.id} className="border-2 border-transparent rounded hover:border-slate-100 transition-all duration-300 cursor-pointer">
                        <img src={template.thumbnail} alt={template.name} draggable="false" className="rounded-sm" />
                      </div>
                    </Tooltip>
                  </Popconfirm>
                ))}
              </Masonry>
            </div>
          )}
        </div>
      </Flex>
    </div>
  );
}
