import React, { useEffect, useState } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Flex, message, Upload } from 'antd';
import type { GetProp, UploadProps } from 'antd';
import UserApi from '../api/UserApi';
import { useRecoilValue } from 'recoil';
import { userState } from '../state/atoms';
import { env } from '../env/env';

interface Props {
  url: string;
  setUrl: any;
}

export default function UploadButton({ url, setUrl }: Props) {
  const [loading, setLoading] = useState(false);
  const user = useRecoilValue(userState);
  const [imageUrl, setImageUrl] = useState<string>(url);

  type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

  useEffect(() => {
    console.log("url" + url);
    setImageUrl(url);
  }, [url]);

  const getBase64 = (img: FileType, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: FileType) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp';
    if (!isJpgOrPng) {
      message.error('You can only the image in JPG/PNG/WEBP format.');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB.');
    }

    setLoading(true);
    UserApi.upload(user.id!, file).then(resp => {
      setLoading(false);
      setImageUrl(env.s3Path + resp.data.path);
      setUrl(env.s3Path + resp.data.path);
    });

    // return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps['onChange'] = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    // if (info.file.status === 'done') {
    //   // Get this url from response in real world.
    //   getBase64(info.file.originFileObj as FileType, (url) => {
    //     setLoading(false);
    //     setImageUrl(env.s3Path + url);
    //   });
    // }
  };

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  return (
    <Flex gap="middle" wrap>
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {imageUrl ? <img src={imageUrl} style={{ width: '100%' }} /> : uploadButton}
      </Upload>
    </Flex>
  );
};