import { RenderDto, RenderResourceApi } from "../generated/edithor-client";
import { AxiosResponse } from "axios";
import { apiConfig, opsRender } from "./config";

const api = new RenderResourceApi(apiConfig);

const V1Api = {
  download(id: string, apiKey: string): Promise<AxiosResponse<RenderDto, any>> {
    return api.download(id, opsRender(apiKey));
  },
};

export default V1Api;
