import { Button, Card, ColorPicker, Flex, Form, Input, Popconfirm, Progress, Tag, Tooltip, message } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userState } from "../../state/atoms";
import { AlertCircle, Copy } from "lucide-react";
import { IconTrash } from "@tabler/icons-react";
import PaymentApi from "../../api/PaymentApi";
import UserApi from "../../api/UserApi";
import { Helmet } from "react-helmet";
import UploadButton from "../UploadButton";
import { env } from "../../env/env";
import CodeBlock from "../CodeBlock";

export default function EmbedConfigPage() {
  const navigate = useNavigate();
  const user = useRecoilValue(userState);
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();

  const [domain, setDomain] = useState<string | null>();
  const [logoUrl, setLogoUrl] = useState<string>("");
  const [primaryColor, setPrimaryColor] = useState<string | null>();
  const [secondaryColor, setSecondaryColor] = useState<string | undefined>();
  const [embedConfigId, setEmbedConfigId] = useState<string | undefined>();

  useEffect(() => {
    console.log(">logoUrl:" + logoUrl);
  }, [logoUrl]);

  useEffect(() => {
    if (user.id) {
      setLoading(true);
      UserApi.getEmbedConfig(user.id).then(response => {
        const embedConfig = response.data as any; // Assuming one config per user for simplicity
        if (embedConfig) {
          setDomain(embedConfig.domain);
          setLogoUrl(embedConfig.logoUrl ? embedConfig.logoUrl : "");
          setPrimaryColor(embedConfig.primaryColor);
          setSecondaryColor(embedConfig.secondaryColor);
          setEmbedConfigId(embedConfig.id);

          form.setFieldsValue({
            domain: embedConfig.domain,
            primaryColor: embedConfig.primaryColor,
            secondaryColor: embedConfig.secondaryColor
          });
        }
        setLoading(false);
      }).catch(error => {
        messageApi.error('Failed to load embed configuration');
        setLoading(false);
      });
    }
  }, [user]);

  const handleSave = () => {
    const dto = {
      userId: user.id,
      domain: domain || "",
      logoUrl: logoUrl || "",
      primaryColor: primaryColor || "",
      secondaryColor: secondaryColor || ""
    };

    setSaving(true);

    const savePromise = embedConfigId
      ? UserApi.updateEmbedConfig(user.id!, embedConfigId, dto)
      : UserApi.createEmbedConfig(user.id!, dto);

    savePromise.then(response => {
      messageApi.success('Embed configuration saved successfully');
      if (!embedConfigId) {
        setEmbedConfigId(response.data.id);
      }
      setSaving(false);
    }).catch(error => {
      messageApi.error('Failed to save embed configuration');
      setSaving(false);
    });
  };

  function cancelSubscription() {
    PaymentApi.cancelSubscription().then(resp => {
      messageApi.open({
        type: 'success',
        content: resp.data,
      });
    });
  }

  return !user.id ? (
    <>Loading...</>
  ) : (
    <div className="embed-page">
      <Helmet>
        <title>Embed Setup | Templated</title>
      </Helmet>

      {contextHolder}

      <div className="flex flex-col gap-6">
        <div className="flex gap-6 w-full">

          <Card className="w-1/3 h-full">
            <div className="flex flex-col justify-between gap-6">
              <h2 className="text-2xl font-bold">Embed Setup</h2>

              <Form
                layout="vertical"
                size="small"
                form={form}
                initialValues={{ domain: domain, primaryColor: primaryColor, secondaryColor: secondaryColor }}
              >
                <div className="flex flex-col h-full justify-between">
                  <div>
                    <Form.Item label="Your Domain" name="domain">
                      <Input
                        placeholder="https://yourdomain.com"
                        size="large"
                        onChange={e => setDomain(e.target.value)}
                      />
                    </Form.Item>

                    <Form.Item label="Your Logo" name="logo">
                      <UploadButton url={logoUrl} setUrl={setLogoUrl} />
                    </Form.Item>

                    <Form.Item label="Primary Color" name="primaryColor">
                      <ColorPicker
                        defaultValue="#1677ff"
                        size="large"
                        showText
                        onChange={color => setPrimaryColor(color.toHexString())}
                      />
                    </Form.Item>

                    <Form.Item label="Secondary Color" name="secondaryColor">
                      <ColorPicker
                        defaultValue="#1F1F1F"
                        size="large"
                        showText
                        onChange={color => setSecondaryColor(color.toHexString())}
                      />
                    </Form.Item>
                  </div>

                  <Form.Item className="mb-0">
                    <Button
                      type="primary"
                      size="large"
                      className="w-full"
                      onClick={handleSave}
                      loading={saving}
                    >
                      Save
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </Card>

          <Card className="w-full h-full overflow-hidden">
            <Flex vertical justify="space-between" gap={20} className="h-full">
              <h2 className="text-2xl font-bold">Preview</h2>

              <div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
                <div style={{ width: '1380px', height: '500px', transform: 'scale(0.62)', transformOrigin: 'top left' }}>
                  <embed
                    src={`/editor?embed=${embedConfigId}&demo=true`}
                    // src={`/editor?embed=true&demo=true&primaryColor=${primaryColor ? primaryColor!!.replace("#", "%23") : ""}&secondaryColor=${secondaryColor ? secondaryColor!!.replace("#", "%23") : ""}&logoUrl=${logoUrl ? logoUrl : ""}`}
                    className="w-full h-[800px] rounded-2xl border-2 "
                  />
                </div>
              </div>
            </Flex>
          </Card>
        </div>

        <Card className="w-full">
          <Flex vertical justify="space-between" gap={20} className="h-full">
            <h2 className="text-2xl font-bold">Embed Code</h2>

            <div className="break-words">
              <CodeBlock language="html" title="Embed Code" className="mb-4 break-words" action={
                <Tooltip title="Copy code">
                  <Copy onClick={() => {
                    navigator.clipboard.writeText(`<embed src="https://app.templated.io/editor?embed=${embedConfigId}&demo=true" />`).then(() => {
                      messageApi.success("Embed Code copied.");
                    });
                  }} />
                </Tooltip>
              }>
                {
                  // `<embed src="https://app.templated.io/editor?embed=true&demo=true&primaryColor=${primaryColor ? primaryColor!!.replace("#", "%23") : ""}&secondaryColor=${secondaryColor ? secondaryColor!!.replace("#", "%23") : ""}&logoUrl=${logoUrl ? logoUrl : ""} />`
                  `<embed src="https://app.templated.io/editor?embed=${embedConfigId}&demo=true" />`
                }
              </CodeBlock>
              { }
            </div>
          </Flex>
        </Card>

      </div>
    </div>
  );
}