import { Button, Col, Row, message } from "antd";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import PaymentApi from "../../api/PaymentApi";
import { userState } from "../../state/atoms";
import PlansTable from "../PlansTable";
import "./UpgradePage.scss";
import { env } from "../../env/env";
import { Loader } from "lucide-react";
import { IconCheck, IconExternalLink } from "@tabler/icons-react";
import { UserDto } from "../../generated/edithor-client";

export default function UpgradePage() {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const user = useRecoilValue(userState);
  const [loading, setLoading] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();
  const [success] = useSearchParams();
  const [canceled] = useSearchParams();
  const [session_id] = useSearchParams();

  const testimonials = [
    {
      testimonial: `This is a clever idea, especially in concert with programmatic (AI-generated) SEO.`,
      name: 'Ryan Hoover',
      job: 'Creator of ProductHunt',
      link: 'https://www.producthunt.com/products/templated-2?comment=3123294#templated-2',
      image: {
        src: 'https://ph-avatars.imgix.net/2/original.jpeg?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=36&h=36&fit=crop&dpr=1',
        alt: 'Ryan Hoover Profile Photo',
      },
    },
    {
      testimonial: `The possibilities with Templated are endless. The templates are super easy to set up and incredibly versatile. I plan to use this for various solutions in my own apps as well as my clients' apps in the future.`,
      name: 'Mike Mathisen',
      job: 'No-code developer',
      link: 'https://mikegm.com/',
      image: {
        src: 'https://mikegm.com/assets/images/image01.jpg?v=b6ade899',
        alt: 'Mike Mathisen Profile Photo',
      },
    },
    {
      testimonial: `The editor is easy to use, very similar to Canva and the API is simple enough to integrate on the backend.`,
      name: 'Halem Wenderson',
      job: 'Developer at Telvetto',
      link: 'https://www.saashub.com/templated-io/#reviews',
      image: {
        src: 'https://www.gravatar.com/avatar/6d98b67c11de0315193bf413954b0b75?s=96&d=identicon',
        alt: 'Halem Wenderson Profile Photo',
      },
    },
    {
      testimonial: `I have so many ideas running through my head right now of use cases I can do with your API using Bubble.io.`,
      name: 'Jesus Vazquez',
      job: 'Founder of Hustling Labs',
      link: 'https://www.producthunt.com/products/templated-2?comment=3123317#templated-2',
      image: {
        src: 'https://ph-avatars.imgix.net/4060465/7eae2e5d-57bb-4b37-87b8-3a827d80ed69.jpeg?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=120&h=120&fit=crop&dpr=2',
        alt: 'Jesus Vazquez Profile Photo',
      },
    },
  ];

  useEffect(() => {
    if (searchParams.get("success")) {
      messageApi.success('You have been upgraded successfully!');
    }

    if (searchParams.get("canceled")) {
      messageApi.error('You have canceled the upgrade.');
    }
  }, [])

  async function upgrade(planId: any) {
    setLoading(true);

    PaymentApi.createCheckoutSession(planId).then(resp => {
      setLoading(false);

      if (resp.data) {
        window.location.href = resp.data;
      } else {
        console.log(resp)
      }
    });
  }

  const [yearly, setYearly] = useState<boolean>(false)
  const [isAnnual, setIsAnnual] = useState<boolean>(true)

  return (
    <div>
      <Helmet>
        <title>Upgrade | Templated</title>
      </Helmet>

      <div className="flex justify-center max-w-[14rem] m-auto mb-8 lg:mb-8">
        <div className="relative flex w-full p-1 bg-white dark:bg-slate-900 rounded-full border">
          <span className="absolute inset-0 m-1 pointer-events-none" aria-hidden="true">
            <span className={`absolute inset-0 w-1/2 bg-primary rounded-full shadow-sm shadow-indigo-950/10 transform transition-transform duration-150 ease-in-out ${yearly ? 'translate-x-0' : 'translate-x-full'}`}></span>
          </span>
          <button
            className={`relative flex-1 text-sm font-medium h-8 rounded-full focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150 ease-in-out ${yearly ? 'text-white' : 'text-slate-500 dark:text-slate-400'}`}
            onClick={() => setYearly(true)}
            aria-pressed={isAnnual}>
            Yearly <span className={`${yearly ? 'text-indigo-200' : 'text-slate-400 dark:text-slate-500'}`}>-15%</span>
          </button>
          <button
            className={`relative flex-1 text-sm font-medium h-8 rounded-full focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150 ease-in-out ${yearly ? 'text-slate-500 dark:text-slate-400' : 'text-white'}`}
            onClick={() => setYearly(false)}
            aria-pressed={isAnnual}>
            Monthly
          </button>
        </div>
      </div>

      <div className="max-w-sm grid gap-6 lg:grid-cols-3 items-start lg:max-w-full mx-20">
        {/* <PricingTab
          yearly={yearly}
          planName="Hobby"
          price={{ yearly: 170, monthly: 17 }}
          planDescription="Start with a basic package"
          features={[
            '200 renders per month',
            'Up to 3 templates',
            'No Code integrations',
            'Email Support',
          ]}
          upgrade={upgrade}
          loading={loading}
          plan={
            {
              monthlyId: env.plans.hobby.monthlyId,
              yearlyId: env.plans.hobby.yearlyId,
            }
          } /> */}
        <PricingTab
          yearly={yearly}
          user={user}
          planName="Starter"
          price={{ yearly: 299, monthly: 29 }}
          planDescription="Automate your images and PDFs"
          features={[
            '1,000 renders per month',
            'Up to 15 templates',
            'No-code integrations',
            'Custom Fonts',
            'Email + Chat Support',
          ]}
          upgrade={upgrade}
          loading={loading}
          plan={
            {
              monthlyId: env.plans.starter.monthlyId,
              yearlyId: env.plans.starter.yearlyId,
            }
          } />

        <PricingTab
          yearly={yearly}
          user={user}
          popular={true}
          planName="Scale"
          price={{ yearly: 999, monthly: 99 }}
          planDescription="Scale your automations"
          features={[
            '5,000 renders per month',
            'Up to 50 templates',
            'All Starter Plan benefits',
            'Email + Chat Support',
          ]}
          upgrade={upgrade}
          loading={loading}
          plan={
            {
              monthlyId: env.plans.scale.monthlyId,
              yearlyId: env.plans.scale.yearlyId,
            }
          } />

        <PricingTab
          yearly={yearly}
          user={user}
          planName="Enterprise"
          price={{ yearly: 1799, monthly: 179 }}
          planDescription="Grow the machine"
          features={[
            '25,000 renders per month',
            'Unlimited templates',
            'All Scale Plan benefits',
            'Priority Support',
          ]}
          upgrade={upgrade}
          loading={loading}
          plan={
            {
              monthlyId: env.plans.enterprise.monthlyId,
              yearlyId: env.plans.enterprise.yearlyId,
            }
          } />

      </div>

      <div className="grid sm:grid-cols-2 lg:grid-cols-4 max-w-sm gap-6 items-start lg:max-w-full mx-20 mt-14">
        {
          testimonials && testimonials.map(({ testimonial, name, job, link, image }) => (
            <div className="flex h-auto text-gray-500 mx-2">
              <div className="flex flex-col rounded-md hover:scale-110 transition-all duration-500 opacity-40 hover:opacity-100">
                <blockquote className="flex-auto mb-4">
                  <p className="text-muted p- text-sm">"{testimonial}"</p>
                </blockquote>

                {/* <hr className="border-slate-200 dark:border-slate-600 my-4" /> */}

                <div className="flex items-center">
                  {image && (
                    <div>
                      <img
                        src={image}
                        className="size-12 rounded-full border border-slate-200 dark:border-slate-600 min-w-full min-h-full"
                        width={40}
                        height={40}
                        widths={[400, 768]}
                        layout="fixed"
                        {...(image as any)}
                      />
                    </div>
                  )}

                  <div className="grow ml-3 rtl:ml-0 rtl:mr-3">
                    {name && <p className="text-sm font-semibold">{name}</p>}
                    {job && <p className="text-xs text-muted">{job}</p>}
                  </div>

                  {/* <a href={link} target="_blank" rel="nofollow" className="block hover:bg-slate-100 hover:scale-110 p-2 transition-all duration-300 rounded-lg">
                    <IconExternalLink className="text-xl text-secondary" />
                  </a> */}
                </div>
              </div>
            </div>
          ))
        }
      </div>

    </div>
  )
}

interface PricingTabProps {
  yearly: boolean;
  popular?: boolean;
  planName: string;
  price: {
    monthly: number;
    yearly: number;
  }
  planDescription: string;
  features: string[];
  upgrade?: any;
  plan: {
    monthlyId: string;
    yearlyId: string;
  };
  loading: boolean;
  user: UserDto;
}

function PricingTab(props: PricingTabProps) {
  return (
    <div className={`h-full ${props.popular ? '' : 'dark'} dark`}>
      <div className={`relative flex flex-col h-full p-6 rounded-xl bg-white border border-slate-200 shadow-lg shadow-slate-950/5`}>
        {props.popular && (
          <div className="absolute top-0 right-0 mr-6 -mt-4">
            <div className="inline-flex items-center text-xs font-semibold py-1.5 px-3 bg-accent text-white rounded-full shadow-sm shadow-slate-950/5">Popular</div>
          </div>
        )}
        <div className="mb-5">
          <div className="text-slate-500 font-semibold mb-1 text-3xl tracking-tighter">{props.planName}</div>
          <div className="inline-flex items-baseline mb-2">
            <span className="text-slate-900 font-bold text-3xl">$</span>
            <span className="text-slate-900 font-bold text-5xl">{props.yearly ? props.price.yearly : props.price.monthly}</span>
            <span className="text-slate-500 font-medium text-2xl">{props.yearly ? '/year' : '/mo'}</span>
          </div>
          <div className="text-base text-slate-500 mb-5">{props.planDescription}</div>
          <Button type={props.user.plan == props.planName ? "dashed" : "primary"} size="large" loading={props.loading} disabled={props.user.plan == props.planName} className="w-full transition-colors duration-150 !text-lg !py-2 !h-auto"
            onClick={() => props.upgrade(props.yearly ? props.plan.yearlyId : props.plan.monthlyId)}>
            {props.user.plan == props.planName ? "Subscribed" : "Upgrade"}
          </Button>
          {/* <button className="w-full inline-flex justify-center whitespace-nowrap rounded-md bg-primary 
          px-3.5 py-2.5 text-xl font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-primary 
          focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 
          transition-colors duration-150" 
          onClick={() => props.upgrade(props.planId)}>
            Upgrade
          </button> */}
        </div>
        <div className="text-slate-900 dark:text-slate-200 font-medium mb-3">Includes:</div>
        <ul className="text-slate-600 dark:text-slate-400 text-sm space-y-3 grow">
          {props.features.map((feature, index) => {
            return (
              <li key={index} className="flex items-center">
                <IconCheck className="text-accent mr-2" size={20} />
                <span className="text-base">{feature}</span>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
