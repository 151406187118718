import { Button, Card, Col, Form, Input, Layout, Row, message } from "antd";
import { Lock, Mail, User } from "lucide-react";
import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import AuthApi from "../../api/AuthApi";
import { AuthDto, GoogleAuthDto, UserDto } from "../../generated/edithor-client";
import Storage from "../../util/Storage";
import Logo from "../Logo";
import "./AuthPage.scss";
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode"
import { Helmet } from "react-helmet";
import { showSurveyState } from "../../state/atoms";
import { useRecoilState } from "recoil";

export default function SignUpPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [showSurvey, setShowSurvey] = useRecoilState(showSurveyState);

  useEffect(() => {
    if (Storage.getToken()) {
      navigate('/');
    }
  }, []);

  function handleSubmit(values: any) {
    setLoading(true);

    const user = {
      email: values.email,
      name: values.name,
      password: values.password
    } as UserDto;

    AuthApi.signup(user)
      .then(async resp => {
        const { user } = resp.data as AuthDto;
        await performLogin(values.email, values.password);
      }).catch((err) => {
        const key = err.response.data.message;
        setLoading(false);
        message.error(key === "email.in.use" ? "This email is in use." : "Something went wrong.");
      });
  }

  async function performLogin(email: string, password: string = "") {
    setLoading(true);
    const resp = await AuthApi.login({ email, password });

    if (resp.data.token) {
      Storage.setToken(resp.data.token);
      
      if (!Storage.getFlag("survey")) {
        setShowSurvey(true);
        Storage.setFlag("survey", true);
      } else {
        setShowSurvey(false);
      }

      navigate('/');
    }

    setLoading(false);
  }

  function validatePassword(rule: any, value: any, callback: any) {
    if (value && value.length >= 8) {
      const hasLetters = /[A-Za-z]/.test(value);
      const hasNumbers = /\d/.test(value);
      if (hasLetters && hasNumbers) {
        callback();
      } else {
        callback('Password must contain both letters and numbers.');
      }
    } else {
      callback('Password must be at least 8 characters long.');
    }
  };

  const responseMessage = (resp: any) => {
    setLoading(true);

    const dto = {
      idToken: resp.credential,
      // name: `${decoded.given_name} ${decoded.family_name}`,
      // googleSub: decoded.sub
    } as GoogleAuthDto;

    AuthApi.googleAuth(dto)
      .then(async resp => {
        setLoading(false);

        if (resp.data.token) {
          Storage.setToken(resp.data.token);
          
          if (!Storage.getFlag("survey")) {
            setShowSurvey(true);
            Storage.setFlag("survey", true);
          } else {
            setShowSurvey(false);
          }

          navigate('/')
        }
      }).catch((err) => {
        const key = err.response.data.message;
        setLoading(false);
        message.error(key === "email.in.use" ? "This email is in use." : "Something went wrong.");
      });
  };

  const errorMessage = () => {
    // console.log(error);
    setLoading(false);
  };

  return (
    <div className={"signup-page"}>
      <Helmet>
        <title>Sign Up | Templated</title>
        <meta name="description" content="Sign Up to a Templated account" />
      </Helmet>

      <Layout>
        <Row justify="center">
          <Col xl={8} lg={8} md={10} sm={24} xs={24}>
            <Card>
              <div className="flex justify-center mb-6 items-center">
                <Logo />
              </div>

              <h3 className="mb-2 tracking-tight">Create your account</h3>
              <p className="mb-6 tracking-tight">Start with 50 free API credits</p>

              <div className="flex justify-center plausible-event-name=signup">
                <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
              </div>

              <hr className="my-8" />

              <Form form={form} layout="vertical" onFinish={handleSubmit}>
                <Form.Item name="name" rules={[{ required: true, message: 'Please enter your name.' }]}>
                  <Input prefix={<User />} placeholder="Name" size="large" />
                </Form.Item>

                <Form.Item name="email" rules={[{ required: true, message: 'Please enter your email' }]}>
                  <Input prefix={<Mail />} placeholder="Email" size="large" />
                </Form.Item>

                <Form.Item name="password" rules={[{ required: true, message: 'Please enter your password' }, { validator: validatePassword }]}>
                  <Input prefix={<Lock />} placeholder="password" type="password" size="large" />
                </Form.Item>

                <Form.Item>
                  <Button block size="large" htmlType="submit" type="primary" className="plausible-event-name=signup" loading={loading}>
                    Sign Up
                  </Button>
                </Form.Item>
              </Form>

              <footer>
                <h4>Already have an account? <NavLink to={`/signin`}>Sign in</NavLink></h4>
              </footer>
            </Card>
          </Col>
        </Row>
      </Layout>
    </div>
  );
}
