const TKN = "tkn";

const Storage = {
  setToken(memberId: string) {
    window.localStorage.setItem(TKN, memberId);
  },

  getToken(): string {
    return window.localStorage.getItem(TKN) || "";
  },

  removeToken() {
    window.localStorage.removeItem(TKN);
  },

  setFlag(flag: string, value: any) {
    window.localStorage.setItem(flag, value);
  },

  getFlag(flag: string ): any {
    return window.localStorage.getItem(flag);
  },
};

export default Storage;
