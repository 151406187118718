import { IconFileTypePdf, IconPalette, IconPhoto, IconScribble, IconTriangleSquareCircle } from "@tabler/icons-react";
import { Button, Card, Drawer, Dropdown, Empty, Flex, Form, Image, Input, Select, Skeleton, Spin, Tooltip, message } from "antd";
import Meta from "antd/es/card/Meta";
import Search from "antd/es/input/Search";
import { Type } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import TemplateApi from "../../api/TemplateApi";
import UserApi from "../../api/UserApi";
import RenderApi from "../../api/V1Api";
import { env } from "../../env/env";
import { CategoryDto, FolderDto, Layer, RenderDto, TemplateDto } from "../../generated/edithor-client";
import { tourState, userState } from "../../state/atoms";
import { download } from "../../util/utils";
import Carousel from "../Carousel";
import Folders from "../Folders";
import TemplateCard from "../TemplateCard";
import DateTimeStamp from "../util/DateTimeStamp";
import CategoryApi from "../../api/CategoryApi";
import RenderCard from "../RenderCard";
import { Helmet } from "react-helmet";
const { Option } = Select;

interface Props {
  isMobile: boolean;
}

export default function UserTemplatesPage({ isMobile }: Props) {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const user = useRecoilValue(userState);
  const [tour, setTour] = useRecoilState(tourState);
  const [loading, setLoading] = useState(true);
  const [loadingRenders, setLoadingRenders] = useState(false);
  const [loadingLayers, setLoadingLayers] = useState(false);
  const [rendersDrawerOpen, setRendersDrawerOpen] = useState(false);
  const [layersDrawerOpen, setLayersDrawerOpen] = useState(false);
  const [configMasterTemplateDrawerOpen, setConfigMasterTemplateDrawerOpen] = useState(false);

  const [templates, setTemplates] = useState([] as TemplateDto[]);
  const [filteredTemplates, setFilteredTemplates] = useState([] as TemplateDto[]);
  const [selectedTemplate, setSelectedTemplate] = useState({} as TemplateDto);
  const [renders, setRenders] = useState([] as RenderDto[]);
  const [layers, setLayers] = useState([] as Layer[]);

  const [folders, setFolders] = useState([] as FolderDto[]);
  const [selectedFolder, setSelectedFolder] = useState<FolderDto | null>(null);

  const [thumbnails, setThumbnails] = useState({} as any);

  const [configMasterTemplateForm] = Form.useForm();

  const [categories, setCategories] = useState<CategoryDto[]>([]);

  useEffect(() => {
    setLoading(true);

    if (user.id) {
      UserApi.getUserTemplates(user.id).then(resp => {
        setTemplates(resp.data);
        setFilteredTemplates(resp.data);
        setLoading(false);

        const tempThumbs = {} as any;

        resp.data.map(async (template, index) => {
          const thumbnailUrl = `${env.s3Path}public/thumbnail/${template.id}.webp?bust=${(new Date().getTime())}`;
          tempThumbs[template.id!] = thumbnailUrl;
        })

        setThumbnails(tempThumbs);

        const loadImages = async () => {
          const tempThumbs = {} as any;
          await Promise.all(
            resp.data.map(async (template, index) => {
              const thumbnailUrl = `${env.s3Path}public/thumbnail/${template.id}.webp?bust=${(new Date().getTime())}`;
              const newThumbnailUrl = `${env.apiPath}/templates/thumbnail/${template.id}`;

              try {
                const response = await fetch(thumbnailUrl);

                if (response.ok) {
                  const headers = {} as any;
                  response.headers.forEach((value, name) => headers[name] = value);

                  if (new Date(template.updatedAt!) > new Date(headers["last-modified"])) {
                    tempThumbs[template.id!] = newThumbnailUrl;
                  } else {
                    tempThumbs[template.id!] = URL.createObjectURL(await response.blob());
                  }
                } else {
                  tempThumbs[template.id!] = newThumbnailUrl;
                }
              } catch (error: any) {
                tempThumbs[template.id!] = newThumbnailUrl;
              }
            })
          );
          setThumbnails(tempThumbs);
        };

        loadImages();

        setTimeout(() => {
          if (!isMobile && !localStorage.getItem('dashboardTourCompleted')) {
            setTour({ run: true });
            localStorage.setItem('dashboardTourCompleted', 'true');
          }
        }, 10);
      })

      UserApi.getUserFolders(user.id).then(resp => {
        setFolders(resp.data.reverse());
      })
    }
  }, [user, selectedFolder]);

  // useEffect(() => {
  //   const loadImages = async () => {
  //     const tempThumbs = {} as any;
  //     await Promise.all(
  //       templates.map(async (template, index) => {
  //         const thumbnailUrl = `${env.s3Path}public/thumbnail/${template.id}.webp?bust=${(new Date().getTime())}`;
  //         const newThumbnailUrl = `${env.apiPath}/templates/thumbnail/${template.id}`;

  //         try {
  //           const response = await fetch(thumbnailUrl);

  //           if (response.ok) {
  //             const headers = {} as any;
  //             response.headers.forEach((value, name) => headers[name] = value);

  //             if (new Date(template.updatedAt!) > new Date(headers["last-modified"])) {
  //               tempThumbs[template.id!] = newThumbnailUrl;
  //             } else {
  //               tempThumbs[template.id!] = URL.createObjectURL(await response.blob());
  //             }
  //           } else {
  //             tempThumbs[template.id!] = newThumbnailUrl;
  //           }
  //         } catch (error: any) {
  //           tempThumbs[template.id!] = newThumbnailUrl;
  //         }
  //       })
  //     );
  //     setThumbnails(tempThumbs);
  //   };

  //   loadImages();
  // }, [templates]);


  function editTemplate(template: TemplateDto, ev: any) {
    if (ev.metaKey) {
      window.open(`/editor/${template.id}`, "_blank");
    } else {
      navigate(`/editor/${template.id}`);
    }
  }

  function copy(template: TemplateDto) {
    messageApi.loading(`Making a copy of ${template.name}...`);

    TemplateApi.copy(template.id!).then(resp => {
      setTemplates(prevTemplates => [resp.data, ...prevTemplates]);
      setFilteredTemplates(prevTemplates => [resp.data, ...prevTemplates]);
      messageApi.success(`Template ${resp.data.name} copied.`);
    })
  }

  function copyId(template: TemplateDto) {
    navigator.clipboard.writeText(template.id!).then(() => {
      messageApi.success(<>You copied the template ID: <br /> <b>{template.id}</b></>);
    });
  }

  function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text).then(() => {
      messageApi.success(<>Copied: <br /> <b>{text}</b></>);
    });
  }

  function downloadTemplate(template: TemplateDto) {
    messageApi.open({
      key: 'downloading',
      type: 'loading',
      content: <>Rendering <b>{template.name}</b>... <br /></>,
      duration: 0,
    });

    RenderApi.download(template.id!, user.apiKey!).then(resp => {
      download(`${resp.data.url}`, template.name, () => {
        messageApi.open({
          key: 'downloading',
          type: 'success',
          content: <>Done!</>,
          duration: 3,
        });
      });
    });
  }

  function addToFolder(template: TemplateDto, folder: FolderDto) {
    TemplateApi.moveToFolder(template.id!, folder.id!);

    messageApi.open({
      type: 'success',
      content: <>Template <b>{template.name}</b> added to folder <b>{folder.name}</b>.</>,
      duration: 3,
    });

    //   download(`${env.s3Path}${resp.data.url}`, template.name, () => {
    //     messageApi.open({
    //       key: 'downloading',
    //       type: 'success',
    //       content: <>Done!</>,
    //       duration: 3,
    //     });
    //   });
    // });
  }

  function remove(template: TemplateDto) {
    if (template.isMaster) {
      messageApi.error(`You can't remove a Master template.`)
      return;
    }

    setTemplates(prevTemplates => prevTemplates.filter(t => t.id !== template.id));
    setFilteredTemplates(prevTemplates => prevTemplates.filter(t => t.id !== template.id));

    messageApi.info(`Template ${template.name} removed.`)

    TemplateApi.remove(template.id!).then(resp => { })
  }

  function toggleMaster(template: TemplateDto) {
    messageApi.open({
      key: 'mastering',
      type: 'loading',
      content: <>Turning template to Master...</>,
      duration: 0,
    });

    TemplateApi.toggleMaster(template.id!).then(() => {
      const temps = templates.map(t => {
        if (template.id === t.id) {
          return { ...template, isMaster: !template.isMaster };
        }
        return t;
      });

      setTemplates(temps);

      messageApi.open({
        key: 'mastering',
        type: 'success',
        content: <>Template is now a Master template.</>,
        duration: 3,
      });
    });
  }

  function seeTemplateRenders(template: TemplateDto) {
    setRendersDrawerOpen(true);
    setLoadingRenders(true);
    setSelectedTemplate(template);

    TemplateApi.getTemplateRenders(template.id!).then(resp => {
      setRenders(resp.data);
      setLoadingRenders(false);
    });
  }

  function seeTemplateLayers(template: TemplateDto) {
    setLayersDrawerOpen(true);
    setLoadingLayers(true);
    setSelectedTemplate(template);

    TemplateApi.getTemplateLayers(template.id!).then(resp => {
      setLayers(resp.data);
      setLoadingLayers(false);
    });
  }

  function onSearchTemplates(value: any) {
    const filtered = templates.filter(template => {
      return template.name!.toLowerCase().includes(value.toLowerCase())
    });
    setFilteredTemplates(filtered);
  }

  function configMasterTemplate(template: TemplateDto) {
    setConfigMasterTemplateDrawerOpen(true);
    configMasterTemplateForm.setFieldsValue({ ...template, category: template.categoryId });
    setSelectedTemplate(template);

    if (categories.length === 0) {
      CategoryApi.getAll().then(resp => {
        setCategories(resp.data);
      });
    }
  }

  async function updateMasterTemplate(values: any) {
    configMasterTemplateForm.validateFields().then(values => {
      console.log(values);

      // setLoading(true);

      selectedTemplate.name = values.name;
      selectedTemplate.categoryId = values.category;
      selectedTemplate.tags = values.tags;

      setConfigMasterTemplateDrawerOpen(false);

      TemplateApi.update(selectedTemplate).then(resp => {
        console.log(resp.data);
        // setLoading(false);
        // setConfigMasterTemplateDrawerOpen(false);
        messageApi.success(<>{selectedTemplate.name} config updated!</>);
      });
    });
  }

  return (
    <div>
      <Helmet>
        <title>My Templates | Templated</title>
      </Helmet>

      {templates.length == 0 && !selectedFolder && !loading &&
        <>
          <Carousel />
          <div className="relative flex py-6 items-center">
            <div className="flex-grow border-t border-gray-100 border"></div>
            {/* <span className="flex-shrink mx-4 text-gray-400">Templates</span> */}
            {/* <div className="flex-grow border-t border-gray-200 border"></div> */}
          </div>
        </>
      }

      {/* <div className="flex flex-col mt-6">
        <h2 className="text-2xl tracking-tight text-gray-700 font-semibold mb-2">Tutorials</h2>

        <ul>
          <li className="p-2 rounded hover:bg-slate-100 w-fit"><a href="#" target="_blank">Youtube Tutorial: Image template editor and API Console</a></li>
          <li className="p-2 rounded hover:bg-slate-100 w-fit"><a href="#" target="_blank">Tutorial: Image generation with Make.com(Integromat)</a></li>
          <li className="p-2 rounded hover:bg-slate-100 w-fit"><a href="#" target="_blank">Tutorial: Image generation with Zapier</a></li>
          <li className="p-2 rounded hover:bg-slate-100 w-fit"><a href="#" target="_blank">Tutorial: Use Auto-position to Create an Responsive Image</a></li>
          <li className="p-2 rounded hover:bg-slate-100 w-fit"><a href="#" target="_blank">Tutorial: Generate Images with Direct URL/Query strings</a></li>
        </ul>
      </div> */}

      {
        loading ? (
          <div className="w-full">
            <Skeleton.Button active style={{ width: 228, height: 40, marginRight: 10 }} />
            <Skeleton.Button active style={{ width: 244, height: 40 }} />
            <Skeleton active paragraph={{ rows: 4 }} className="mt-6" />
          </div>
        ) : (
          <div className="w-full">
            {contextHolder}

            <div className="flex w-full flex-wrap justify-between mb-6 gap-4">
              <div className="flex gap-4 flex-wrap">
                {/* <Dropdown
                  trigger={["click"]}
                  placement="bottomLeft"
                  overlayClassName="border rounded"
                  menu={{
                    items: [
                      { key: '1', className: "!p-0", label: <div className="!p-3 !px-4 !text-lg" onClick={() => navigate('/editor')}>Create a template</div> },
                      { key: '2', className: "!p-0", label: <a className="block !p-3 !px-4 !text-lg" target="_blank" href={`${env.sitePath}/templates/`}>Customize from our Gallery</a> },
                    ]
                  }}>
                  <Button type="primary" icon={<IconScribble />} className="create-template-btn flex !py-6 md:!py-5 !text-xl md:!text-lg w-full md:w-auto items-center justify-center">
                    Create a template
                  </Button>
                </Dropdown> */}

                <Button type="primary" icon={<IconScribble />} onClick={() => navigate('/editor')} className="create-template-btn transition-all !duration-300 flex !py-5 !text-base w-full md:w-auto items-center justify-center shadow-[0_0_4px_#fff,inset_0_0_0px_#fff,0_0_4px_#08f,0_0_7px_#08f,0_0_7px_#08f]">
                  Create a template
                </Button>

                <Button type="default" href={`${env.sitePath}/templates/`} className="flex !py-5 !text-base w-full md:w-auto items-center justify-center">Browse our Template Gallery</Button>
              </div>

              <Search placeholder="Search your templates..." size="large" allowClear onSearch={onSearchTemplates} className="w-full md:w-80" />
            </div>

            <Folders folders={folders} selectedFolder={selectedFolder} setFolders={setFolders} setSelectedFolder={setSelectedFolder} setLoading={setLoading} setFilteredTemplates={setFilteredTemplates} setTemplates={setTemplates} user={user} messageApi={messageApi} />

            {!templates.length &&
              <p className="text-xl">
                {selectedFolder
                  ? 'This folder does not have any templates yet.'
                  : <>You don't have templates yet. Click <button className="underline cursor-pointer" onClick={() => navigate('/editor')}>here</button> to create your first one.</>
                }
              </p>
            }


            {/* <div className="flex gap-6 flex-wrap"> */}
            <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6 5xl:grid-cols-7">
              {filteredTemplates.map((template, index) => (
                <TemplateCard key={template.id} template={template} user={user} loading={loading} thumbnails={thumbnails} folders={folders}
                  editTemplate={editTemplate}
                  copyId={copyId}
                  seeTemplateRenders={seeTemplateRenders}
                  seeTemplateLayers={seeTemplateLayers}
                  remove={remove}
                  copy={copy}
                  downloadTemplate={downloadTemplate}
                  addToFolder={addToFolder}
                  toggleMaster={toggleMaster}
                  configMasterTemplate={configMasterTemplate}
                />
              ))}
            </div>

            <Drawer
              title={
                <div className="flex flex-col items-end text-right">
                  {selectedTemplate.name}
                  <div>{loadingRenders ? <Spin /> : <span className="text-gray-700 font-normal text-sm">({renders.length} renders)</span>}</div>
                </div>
              }
              placement="right"
              width={800}
              onClose={() => { setRendersDrawerOpen(false); setRenders([]); }}
              open={rendersDrawerOpen}
            >
              {!renders.length && !loadingRenders && <Empty />}
              {loadingRenders && <Skeleton />}

              {/* <Flex gap={24} wrap="wrap"> */}
              <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-3">
                {renders.map((render, index) => (
                  <RenderCard key={render.id} render={render} user={user} loading={loading} copyId={copyId} showTemplateName={false} />
                ))}
              </div>
              {/* </Flex> */}
            </Drawer>

            <Drawer
              title={
                <div className="flex flex-col items-end text-right">
                  {selectedTemplate.name}
                  <div className="">{loadingLayers ? <Spin /> : <span className="text-gray-700 font-normal text-sm">({layers.length} layers)</span>}</div>
                </div>
              }
              placement="right"
              size="default"
              onClose={() => { setLayersDrawerOpen(false); setLayers([]); }}
              open={layersDrawerOpen}
            >
              {!layers.length && !loadingLayers && <Empty />}
              {loadingLayers && <Skeleton />}

              <div className="flex flex-col items-end">
                {layers.map((layer) => (
                  <Tooltip title={`${layer.type!} layer`} placement="right">
                    <div className="w-fit px-4 py-1 border border-dashed rounded flex items-center text-lg cursor-pointer mb-2 bg-gray-100 hover:bg-gray-300" onClick={() => { copyToClipboard(layer.layer!) }}>
                      <span>{layer.layer}</span>
                      {layer.type === "text" && <Type className="ml-2" />}
                      {layer.type === "image" && <IconPhoto className="ml-2" />}
                      {layer.type === "shape" && <IconTriangleSquareCircle className="ml-2" />}
                    </div>
                  </Tooltip>
                ))}
              </div>
            </Drawer>

            <Drawer
              title={
                <div className="flex flex-col items-end text-right">
                  {selectedTemplate.name}
                </div>
              }
              placement="right"
              size="default"
              onClose={() => { setConfigMasterTemplateDrawerOpen(false); }}
              open={configMasterTemplateDrawerOpen}
            >
              <Form form={configMasterTemplateForm} layout="vertical" onFinish={updateMasterTemplate}>
                <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please enter the template name' }]}>
                  <Input placeholder="Name" size="large" />
                </Form.Item>

                <Form.Item label="Category" name="category">
                  <Select size="large" showSearch placeholder="Select a category">
                    {categories.map(cat => {
                      return (<Option value={cat.id}>{cat.name}</Option>)
                    })}
                  </Select>
                </Form.Item>

                <Form.Item label="Tags" name="tags">
                  <Input placeholder="tags" size="large" />
                </Form.Item>

                <Form.Item>
                  <Button block size="large" htmlType="submit" type="primary" loading={loading} className="!my-2">
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </Drawer>
          </div>
        )
      }
    </div>
  )
}
