import { TemplateDto, RenderDto, Layer, TemplateResourceApi, FolderDto } from "../generated/edithor-client";
import { AxiosPromise } from "axios";
import { apiConfig, ops } from "./config";

const api = new TemplateResourceApi(apiConfig);

const TemplateApi = {
  get(id: string): AxiosPromise<TemplateDto> {
    return api.getTemplate1(id, ops());
  },

  create(dto: TemplateDto): AxiosPromise<TemplateDto> {
    return api.createTemplate(dto, ops());
  },

  update(dto: TemplateDto): AxiosPromise<TemplateDto> {
    return api.updateTemplate(dto.id!, dto, ops());
  },

  remove(id: string) {
    return api.removeTemplate(id, ops());
  },

  copy(id: string) {
    return api.copyTemplate(id, ops());
  },

  toggleMaster(id: string) {
    return api.toggleMasterTemplate(id, ops());
  },
  
  getTemplateRenders(id: string): AxiosPromise<RenderDto[]> {
    return api.getTemplateRenders1(id, ops());
  },

  getTemplateLayers(id: string): AxiosPromise<Layer[]> {
    return api.getTemplateLayers1(id, ops());
  },

  getMasterTemplates(): AxiosPromise<TemplateDto[]> {
    return api.getMasterTemplates(ops());
  },

  createFromMaster(masterId: string, userId: string): AxiosPromise<TemplateDto> {
    return api.createTemplateFromMaster(masterId, userId, ops());
  },

  getMasterByName(name: string): AxiosPromise<TemplateDto> {
    return api.getMasterTemplateByName(name, ops());
  },

  moveToFolder(id: string, folderId: string) {
    api.moveToFolder(id, folderId, ops());
  },
};

export default TemplateApi;
