import { AxiosPromise } from "axios";
import { UserDto, UserResourceApi, TemplateDto, RenderDto, UploadDto, EmbedConfigDto, FontDto } from "../generated/edithor-client";
import { apiConfig, ops } from "./config";

const api = new UserResourceApi(apiConfig);

const UserApi = {
  get(id: string): AxiosPromise<UserDto> {
    return api.getUser(id, ops());
  },

  create(dto: UserDto): AxiosPromise<UserDto> {
    return api.createUser(dto, ops());
  },

  update(dto: UserDto): AxiosPromise<UserDto> {
    return api.updateUser(dto.id!, dto, ops());
  },

  // delete(id: string) {
  //   return api.removeUser(id, ops());
  // },

  getUserTemplates(id: string): AxiosPromise<TemplateDto[]> {
    return api.getUserTemplates(id, ops());
  },

  getUserRenders(id: string, page: number = 0, limit: number = 25): AxiosPromise<RenderDto[]> {
    return api.getUserRenders(id, page, limit, ops());
  },

  getUserFolders(id: string): AxiosPromise<TemplateDto[]> {
    return api.getUserFolders(id, ops());
  },

  upload(id: string, file: any): AxiosPromise<UploadDto> {
    return api.upload1(id, file, ops());
  },

  getUserUploads(id: string): AxiosPromise<UploadDto[]> {
    return api.getUserUploads(id, ops());
  },

  uploadFont(id: string, file: any): AxiosPromise<FontDto> {
    return api.uploadFont(id, file, ops());
  },

  getUserFonts(id: string): AxiosPromise<FontDto[]> {
    return api.getUserFonts(id, ops());
  },

  // New EmbedConfig endpoints
  createEmbedConfig(userId: string, dto: EmbedConfigDto): AxiosPromise<EmbedConfigDto> {
    return api.createEmbedConfig(userId, dto, ops());
  },

  updateEmbedConfig(userId: string, configId: string, dto: EmbedConfigDto): AxiosPromise<EmbedConfigDto> {
    return api.updateEmbedConfig(userId, configId, dto, ops());
  },

  getEmbedConfig(embedConfigId: string): AxiosPromise<EmbedConfigDto> {
    return api.getEmbedConfig(embedConfigId, ops());
  },

  deleteEmbedConfig(userId: string, configId: string): AxiosPromise<void> {
    return api.deleteEmbedConfig(userId, configId, ops());
  }
};

export default UserApi;