import { env } from "../env/env";
import {
  DigitalSizes,
  SocialSizes,
  DesignSizes,
  DigiboardSizes,
} from "./constants";

export function tick(callback) {
  setTimeout(callback, 0);
}

export function ellipsis(value, count = 25) {
  return value.substring(0, count) + "...";
}

export function defaultSelectFilterOption(input, option) {
  return option.value.toLowerCase().indexOf(input.toLowerCase()) === 0;
}

export function defaultSelectFilterSort(one, two) {
  return one.value.toLowerCase().localeCompare(two.value.toLowerCase());
}

export function toLogin() {
  window.location.href = "/signin";
}

export function isIOS() {
  return (
    navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)
  );
}

export function isFirefox() {
  return navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
}

export function openInNewTab(link) {
  if (link) {
    const opened = window.open(link, "_blank");
    if (opened) {
      opened.focus();
    }
  }
}

export async function assetExists(url) {
  try {
    const response = await fetch(url);

    if (response.ok) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}

export function viewFileInNewTabUsingBlob(blob, fileName, win) {
  if (fileName.endsWith(".pdf")) {
    blob = new Blob([blob], { type: "application/pdf" });
  }

  const url = window.URL.createObjectURL(blob);

  if (isIOS() && win) {
    win.location.href = url;
  } else {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    if (link.parentNode != null) {
      link.parentNode.removeChild(link);
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 10000);
    }
  }
}

export function getImagePath(key) {
  return !key || key.indexOf("https") > -1 ? key : `${env.s3Path}/${key}`;
}

export function getS3Path(key) {
  return !key || key.indexOf("https") > -1 ? key : `${env.s3Path}${key}`;
}

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export function formatPrice(amount, light = false) {
  const amountAsNumber =
    typeof amount === "string" ? parseFloat(amount) : amount;
  const formatted = formatter.format(amountAsNumber);
  return light ? formatted.replace(".00", "") : formatted;
}

export function getSizeInMB(file) {
  return Math.floor(file.size / (1024 * 1024));
}

export function getCookie(name) {
  const search = name + "=";
  let value = "";
  if (document.cookie.length > 0) {
    let offset = document.cookie.indexOf(search);
    if (offset !== -1) {
      offset += search.length;
      let end = document.cookie.indexOf(";", offset);
      if (end === -1) end = document.cookie.length;

      const unescape = window.unescape || window.decodeURI;
      value = unescape(document.cookie.substring(offset, end));
    }
  }
  return value;
}

export function isOldBrowser() {
  const { userAgent } = navigator;
  return (
    (userAgent.includes("Firefox/") &&
      parseInt(userAgent.split("Firefox/")[1]) <= 56) ||
    (userAgent.includes("Chrome/") &&
      parseInt(userAgent.split("Chrome/")[1]) <= 65) ||
    (userAgent.includes("Edge/") && parseInt(userAgent.split("Edge/")[1]) <= 18)
  );
}

export function getUSStates() {
  return [
    { key: "AL", value: "Alabama" },
    { key: "AK", value: "Alaska" },
    { key: "AZ", value: "Arizona" },
    { key: "AR", value: "Arkansas" },
    { key: "CA", value: "California" },
    { key: "CO", value: "Colorado" },
    { key: "CT", value: "Connecticut" },
    { key: "DE", value: "Delaware" },
    { key: "DC", value: "District Of Columbia" },
    { key: "FL", value: "Florida" },
    { key: "GA", value: "Georgia" },
    { key: "HI", value: "Hawaii" },
    { key: "ID", value: "Idaho" },
    { key: "IL", value: "Illinois" },
    { key: "IN", value: "Indiana" },
    { key: "IA", value: "Iowa" },
    { key: "KS", value: "Kansas" },
    { key: "KY", value: "Kentucky" },
    { key: "LA", value: "Louisiana" },
    { key: "ME", value: "Maine" },
    { key: "MD", value: "Maryland" },
    { key: "MA", value: "Massachusetts" },
    { key: "MI", value: "Michigan" },
    { key: "MN", value: "Minnesota" },
    { key: "MS", value: "Mississippi" },
    { key: "MO", value: "Missouri" },
    { key: "MT", value: "Montana" },
    { key: "NE", value: "Nebraska" },
    { key: "NV", value: "Nevada" },
    { key: "NH", value: "New Hampshire" },
    { key: "NJ", value: "New Jersey" },
    { key: "NM", value: "New Mexico" },
    { key: "NY", value: "New York" },
    { key: "NC", value: "North Carolina" },
    { key: "ND", value: "North Dakota" },
    { key: "OH", value: "Ohio" },
    { key: "OK", value: "Oklahoma" },
    { key: "OR", value: "Oregon" },
    { key: "PA", value: "Pennsylvania" },
    { key: "RI", value: "Rhode Island" },
    { key: "SC", value: "South Carolina" },
    { key: "SD", value: "South Dakota" },
    { key: "TN", value: "Tennessee" },
    { key: "TX", value: "Texas" },
    { key: "UT", value: "Utah" },
    { key: "VT", value: "Vermont" },
    { key: "VA", value: "Virginia" },
    { key: "WA", value: "Washington" },
    { key: "WV", value: "West Virginia" },
    { key: "WI", value: "Wisconsin" },
    { key: "WY", value: "Wyoming" },
  ];
}

export function getHeader(headers, key) {
  const found = headers.find((it) => it[0] === key);
  return found ? found[1] : "";
}

export function isDigital(design) {
  if (!design.size) return false;
  return DigitalSizes.indexOf(design.size) > -1;
}

export function isDigiboard(design) {
  if (!design.size) return false;
  return DigiboardSizes.indexOf(design.size) > -1;
}

export function isA4(design) {
  if (!design.size) return false;
  return design.size.indexOf("a4") > -1;
}

export function isSocial(design) {
  if (!design.size) return false;
  return SocialSizes.indexOf(design.size) > -1;
}

export function isCustomSize(design) {
  if (!design.size) return false;
  return design.size === "custom";
}

export function getCookieValue(name) {
  const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  return match ? match[2] : "";
}

export function prettifyPaperSize(paperSize) {
  return paperSize.replace("x", " x ");
}

export function getPrintCartSizeLabel(designSize) {
  const data = DesignSizes[designSize ?? ""];
  if (data) {
    if (data[0] === "takeout") {
      return "folded takeout";
    } else {
      return data[0];
    }
  }
  return "";
}

export function getSizeLabel(designSize) {
  const data = DesignSizes[designSize ?? ""];
  return data ? data[0] : "";
}

export function getSizeDimensions(designSize) {
  const data = DesignSizes[designSize ?? ""];
  return data ? data[1] : "";
}

export function removeDash(paperType) {
  return paperType.replace("-", " ");
}

export function getPaginationTitle(value) {
  switch (value) {
    case "single-sided":
      return "Single-sided";

    case "double-sided":
      return "Double-sided";

    case "front-double-sided":
      return "Front Cover + Double-sided Interior";

    case "back-double-sided":
      return "Back Cover + Double-sided Interior";

    case "front-back-double-sided":
      return "Front & Back Cover + Double-sided Interior";

    default:
      return value;
  }
}

export function isAccountPath(path) {
  return path.indexOf("/account") > -1;
}

export function isUpgradePath(path) {
  return path.indexOf("/upgrade") > -1;
}

export function isAuthPath(path) {
  return (
    path.indexOf("/register") > -1 ||
    path.indexOf("/signin") > -1 ||
    path.indexOf("/oauth") > -1 ||
    path.indexOf("/password") > -1
  );
}

export function isContentPath(path) {
  return path.indexOf("/content/") > -1;
}

export function isRegisterPath(path) {
  return path.indexOf("/register") > -1;
}

const UpperLettersRegex = new RegExp("[A-Z]+");
const LowerLettersRegex = new RegExp("[a-z]+");
const DigitsRegex = new RegExp("[0-9]+");

export function isValidPassword(password) {
  return (
    (UpperLettersRegex.test(password) || LowerLettersRegex.test(password)) &&
    DigitsRegex.test(password) &&
    /\W|_/g.test(password)
  );
}

const EmailRegEx = /^[\w!#$%&'*+-/=?^`.{|}~]+@\w+\.[.\w][.\w]+$/;
export function isValidEmail(email) {
  return EmailRegEx.test(email);
}

export function isValidZip(zip) {
  return !!zip;
}

export function hasPOBox(addressLine) {
  const pos = [" box", " p.o.", " box", " po. box", " apo", " fpo", " pob"];
  return pos.some((v) => addressLine.toLowerCase().includes(v));
}

const PhoneRegEx = /^[0-9+]+[0-9-]{3,15}$/;
export function isValidPhone(phone) {
  return PhoneRegEx.test(phone);
}

export function iniFrame() {
  return window.self !== window.top;
}

export function setSiteBannerCookie(banner) {
  const now = new Date();
  now.setTime(now.getTime() + 14 * 24 * 60 * 60 * 1000);
  const expiration = "expires=" + now.toUTCString();
  document.cookie = `showSiteBanner=${banner.toString()};${expiration};path=/`;
}

export function setExpandFoldersCookie(show) {
  const now = new Date();
  now.setTime(now.getTime() + 365 * 24 * 60 * 60 * 1000);
  const expiration = "expires=" + now.toUTCString();
  document.cookie = `expandFolders=${show.toString()};${expiration};path=/`;
}

export function synchronousRequest(url) {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", url, false);
  xhr.send(null);
  if (xhr.status === 200) {
    return xhr.responseText;
  } else {
    return "";
  }
}

// Convert RGB to hex
function rgbToHex(rgb) {
  var parts = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
  if (parts) {
    var r = parseInt(parts[1]).toString(16);
    var g = parseInt(parts[2]).toString(16);
    var b = parseInt(parts[3]).toString(16);
    return "#" + r + g + b;
  }
  return null; // Return null if the format is not RGB
}

export function hasClass(component, className) {
  return component.classList.contains(className);
}

export function hasId(component, id) {
  return component.id === id;
}

export function isComponent(component) {
  return hasClass(component, "component");
}

export function isText(component) {
  return hasClass(component, "text");
}

export function isImage(component) {
  return hasClass(component, "image");
}

export function isShape(component) {
  return hasClass(component, "shape");
}

export function isCanvas(component) {
  return hasId(component, "canvas");
}

export function isContentArea(component) {
  return hasClass(component, "content");
}

export function isInput(component) {
  return component.tagName === "INPUT";
}

export function isTextarea(component) {
  return component.tagName === "TEXTAREA";
}

export function isContentEditable(component) {
  return component.contentEditable === "true";
}

export function getComponentType(component) {
  if (component.className.includes("text")) {
    return "text";
  } else if (component.className.includes("image")) {
    return "image";
  } else if (component.className.includes("shape")) {
    return "shape";
  } else {
    return "text";
  }
}

export function getComponentRenderDirections(type, component) {
  switch (type) {
    case "image":
      return ["nw", "n", "ne", "w", "e", "sw", "s", "se"];
    case "shape":
      const svgChild = component.querySelector('svg').firstElementChild;
      if (svgChild.nodeName.toLowerCase() === 'line') {
        return ["w", "e"];
      } else {
        return ["nw", "n", "ne", "w", "e", "sw", "s", "se"];
      }
    case "text":
      return ["w", "e", "n", "s"];
    default:
      return [];
  }
}

export function isComponentLocked(component) {
  const prop = component.getAttribute("locked");
  return prop === "true";
}

export function isComponentBold(component) {
  const prop = component.style.fontWeight;
  return prop === "bold" || parseInt(prop) > 500;
}

export function isComponentItalic(component) {
  const prop = component.style.fontStyle;
  return prop === "italic";
}

export function isComponentUppercase(component) {
  const prop = component.style.textTransform;
  return prop === "uppercase";
}

export function getComponentTextAutoFit(component) {
  return component.getAttribute("auto-fit") || "";
}

export function getComponentTextAlign(component) {
  const comp = isText(component) ? component.querySelector("p") : component;
  return getComputedStyle(comp).justifyContent || "left";
}

export function getComponentTextVerticalAlign(component) {
  const comp = isText(component) ? component.querySelector("p") : component;
  return getComputedStyle(comp).alignItems || "start";
}

export function getComponentLetterSpacing(component) {
  const child = component.querySelector("p");

  if (child) {
    const prop = child.style.letterSpacing;
    return prop ? parseFloat(prop) : 0;
  } else {
    return 0;
  }
}

export function getComponentLineHeight(component) {
  const child = component.querySelector("p");

  if (child) {
    const prop = child.style.lineHeight;
    return prop ? parseFloat(prop) : 1.4;
  } else {
    return 0;
  }
}

export function getComponentOpacity(component) {
  return (component.style.opacity || 1) * 100;
}

export function getComponentFontFamily(component) {
  return component.style.fontFamily.replaceAll('"', "") || "Noto Sans";
}

export function getComponentFontSize(component) {
  const child = component.querySelector("p");

  if (child) {
    const prop = child.style.fontSize;
    return prop ? parseFloat(prop) : 0;
  } else {
    return 0;
  }
}

export function getComponentColor(component) {
  return getComputedStyle(component).color;
}

export function getComponentBackgroundColor(component) {
  const comp = isText(component) ? component.querySelector("p") : component;
  return comp.style.backgroundColor || "#FFFFFF";
}

export function getComponentPadding(component) {
  const comp = isText(component) ? component.querySelector("p") : component;
  const prop = comp.style.padding;
  return prop ? parseFloat(prop) : 0;
}

export function getComponentBorderColor(component) {
  const comp = isText(component) ? component.querySelector("p") : component;
  return comp.style.borderColor;
}

export function getComponentBorderWidth(component) {
  const comp = isText(component) ? component.querySelector("p") : component;
  const prop = comp.style.borderWidth;
  return prop ? parseFloat(prop) : 0;
}

export function getComponentBorderRadius(component) {
  const comp = isText(component) ? component.querySelector("p") : component;
  const prop = comp.style.borderWidth;
  return prop ? parseFloat(prop) : 0;
}

export function getComponentShapeLineColor(component) {
  const child = component.querySelector("svg");

  if (child) {
    const prop = rgbToHex(
      getComputedStyle(child.firstElementChild).getPropertyValue("stroke")
    );
    return prop ? prop : "";
  } else {
    return "";
  }
}

export function getComponentShapeFillColor(component) {
  const child = component.querySelector("svg");

  if (child) {
    const prop = rgbToHex(
      getComputedStyle(child.firstElementChild).getPropertyValue("fill")
    );
    return prop ? prop : "";
  } else {
    return "";
  }
}

export function getComponentShapeLineWidth(component) {
  const child = component.querySelector("svg");

  if (child) {
    const prop = child.firstElementChild.getAttribute('stroke-width');
    return prop ? parseInt(prop) : 0;
  } else {
    return 0;
  }
}

export function getComponentShapeLineRadius(component) {
  const child = component.querySelector("svg");

  if (child) {
    const prop = child.firstElementChild.getAttribute('rx');
    return prop ? parseInt(prop) : 0;
  } else {
    return 0;
  }
}

export function download(url, fileName, callback) {
  fetch(url, { cache: "no-cache" }).then(resp => resp.blob()).then(blob => {
    const link = document.createElement('a');
    const blobUrl = URL.createObjectURL(blob);

    link.download = fileName;
    link.href = blobUrl;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    if (callback) callback();
  })
}

export function getTemplateThumbnailPath(id) {
  return `${env.s3Path}public/thumbnail/${id}.webp`;
}

export function lightenColor(color, percent) {
  const num = parseInt(color.replace("#", ""), 16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    G = ((num >> 8) & 0x00ff) + amt,
    B = (num & 0x0000ff) + amt;
  return (
    "#" +
    (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
      (B < 255 ? (B < 1 ? 0 : B) : 255)
    )
      .toString(16)
      .slice(1)
  );
}

export function darkenColor(color, percent) {
  const num = parseInt(color.replace("#", ""), 16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) - amt,
    G = ((num >> 8) & 0x00ff) - amt,
    B = (num & 0x0000ff) - amt;
  return (
    "#" +
    (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
      (B < 255 ? (B < 1 ? 0 : B) : 255)
    )
      .toString(16)
      .slice(1)
  );
};

export function getNextMonthFirstDay() {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();

  // Calculate the first day of the next month
  const nextMonthFirstDay = new Date(year, month + 1, 1);

  // Format the date as "Aug 1st, 2024"
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const day = nextMonthFirstDay.getDate();
  const daySuffix = (day) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  };
  const formattedDate = `${monthNames[nextMonthFirstDay.getMonth()]} ${day}${daySuffix(day)}, ${nextMonthFirstDay.getFullYear()}`;

  return formattedDate;
};

// Utility function to remove common font file extensions from the font name
export function cleanFontName(fontName) {
  return fontName.replace(/\.(ttf|otf|woff|woff2)$/i, '');
};

