import { IconArrowDown, IconArrowRight, IconChevronDown, IconExternalLink, IconLogout, IconMessage, IconSettings, IconStar, IconStarFilled, IconUserSquareRounded } from "@tabler/icons-react";
import { Button, Card, Dropdown, Flex, Progress, Space, Tag, Tooltip } from "antd";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { env } from "../env/env";
import { tourState, userState } from "../state/atoms";
import Storage from "../util/Storage";
import { Crisp } from "crisp-sdk-web";
import { ArrowRight } from "lucide-react";
import { getNextMonthFirstDay } from "../util/utils";

export default function DashboardHeader() {
  const navigate = useNavigate();
  const userDropdownButton = useRef<HTMLDivElement>(null);

  const [user] = useRecoilState(userState);
  const [tour, setTour] = useRecoilState(tourState);

  const apiUsagePercentage = user.apiUsage! / user.apiQuota! * 100;

  function logout() {
    Storage.removeToken();
    window.location.href = env.sitePath;
  }

  function hideUserDropdown() {
    userDropdownButton.current?.click();
  }

  const renewalDate = getNextMonthFirstDay();

  return (
    <div className="border-b h-16 flex items-center justify-between bg-white py-4 px-6">
      <Space>
        <Space>
          {/* <Button type="text" href={`${env.sitePath}/templates/`}>
            Template Gallery
          </Button> */}

          <Button type="dashed" className="hover:underline" href={`${env.sitePath}/docs/`} target="_blank">
            API Documentation <ArrowRight width={15} className="relative" />
          </Button>

          {/* <Button type="text" target="_blank" href={`${env.sitePath}/blog/`}>
            Blog
          </Button> */}

          {/* <Dropdown
            overlayClassName="text-center"
            menu={{
              items: [
                { key: '1', label: <a target="_blank" href={`/docs`}>API Documentation</a> },
                { key: '2', label: <a target="_blank" href={`${env.sitePath}/blog/how-to-use-templated-with-zapier/`}>Zapier Integration</a> },
                { key: '3', label: <a target="_blank" href={`${env.sitePath}/blog/`}>Blog</a> },
              ]
            }}>
            <Button type="text">
              Resources
            </Button>
          </Dropdown> */}

          {/* <Dropdown
            overlayClassName="text-center"
            menu={{
              items: [
                { key: '1', label: <a target="_blank" href={`${env.sitePath}/blog/how-to-generate-images-with-an-api/`}>Using the API</a> },
                { key: '2', label: <a target="_blank" href={`${env.sitePath}/blog/how-to-use-templated-with-zapier/`}>Zapier</a> },
              ]
            }}>
            <Button type="dashed">
              How To Integrate
            </Button>
          </Dropdown> */}
        </Space>
      </Space>

      <Space>
        <Tooltip overlayInnerStyle={{ width: "460px" }} title={
          <div className="text-center">
            This is how much of your API credits you have used this month. <br />
            Your credits will renew on <span className="underline">{renewalDate}</span>.
          </div>
        }>
          <Space style={{ marginRight: 20 }} className="border px-3 py-1.5 rounded-3xl border-dashed hover:animate-pulse cursor-help">
            <Progress percent={apiUsagePercentage} size={["14vw", 12]} strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }} status="active" showInfo={false} style={{ marginRight: 15 }} />
            {user.apiQuota &&
              <span className="text-sm flex items-center gap-2">
                <span className="">
                  <span>{user.apiUsage}</span> <span className="!text-gray-400"> / {user.apiQuota}</span>
                </span>

                <IconArrowRight size={15} style={{ display: "inline" }} />

                <span><b>{apiUsagePercentage.toFixed(0)}%</b></span>
              </span>}
          </Space>
        </Tooltip>

        <Button type="primary" onClick={() => { navigate("/upgrade") }}
          className="font-bold border-0 bg-gradient-to-r from-primary to-accent">
          UPGRADE
        </Button>

        <Dropdown trigger={["click"]} dropdownRender={() => (
          <div className="bg-white border rounded w-60">
            <div className="flex flex-col px-4 py-4 border-b text-sm text-left">
              <strong>{user.name}</strong>
              <span className="font-normal">{user.email}</span>
            </div>

            <div className="flex flex-col align-left text-sm">
              {/* {user.isAdmin &&
                <Tag className="text-center w-fit mx-auto mb-1 cursor-pointer !px-4 !text-sm" color="#FF0044" onClick={() => { navigate('/'); setTour({ run: true }); }}>
                  Take the tour
                </Tag>
              } */}

              <div className="flex justify-start items-center gap-2 py-2.5 px-4 cursor-pointer hover:bg-gray-100" onClick={() => { navigate('/account') }}>
                <IconSettings size={20} /> Account Settings
              </div>

              <div className="flex justify-start items-center gap-2 py-2.5 px-4 cursor-pointer hover:bg-gray-100" onClick={() => { Crisp.chat.open(); }}>
                <IconMessage size={20} /> Chat Support
              </div>

              <div className="flex justify-start items-center gap-2 py-2.5 px-4 cursor-pointer hover:bg-gray-100 border-t" onClick={logout}>
                <IconLogout size={20} /> Logout
              </div>
            </div>
          </div>
        )
        }>

          <Button type="text">
            {user.name} <IconChevronDown size={18} />
          </Button>
        </Dropdown >
      </Space >
    </div >
  );
}
