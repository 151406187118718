import { IconCornerLeftDown, IconCornerRightDown } from "@tabler/icons-react";
import { Tooltip } from "antd";
import { getTemplateThumbnailPath } from "../util/utils";
import { useEffect, useRef } from "react";

interface Props {
}

export default function Carousel({ }: Props) {
  const carouselTemplates = [
    { id: '16f83689-caf5-4517-9d1c-61e598345a10', name: 'template 2' },
    { id: 'dcf19ca8-cf4f-46bd-95ec-db10b5e38809', name: 'template 3' },
    { id: '3f602927-8f26-41e5-9897-8b5076b213db', name: 'template 4' },
    { id: '99c3645b-699a-4acd-ad99-897fc1703f24', name: 'template 5' },
    { id: '09b06472-6997-4b3d-9d4a-35622fecb07c', name: 'template 3' },
    { id: '99d9f7f4-bc0a-4cba-915b-0e725889fb3b', name: 'template 1' },
    { id: '919d1685-852c-4835-bdbb-874c9ebccde7', name: 'template 1' },
    { id: 'c9dc362b-eb94-4455-aade-2e760b19a0b7', name: 'template 4' },
    { id: '21868c03-684f-4a9d-a42f-73ad45fd5f8d', name: 'template 5' },
    { id: '006f3647-f94a-41aa-8254-f4a037b03cd0', name: 'template 1' },
    { id: '97cda15a-f8a1-4aba-a46a-fab0110759cf', name: 'template 2' },
    { id: 'c1dca279-7913-47ff-a2a9-9939699557a1', name: 'template 2' },
    { id: 'abca6ad5-2ae2-4679-9318-045773f9dafe', name: 'template 3' },
    { id: '9c4f7280-bfa8-4bb9-81ed-32df52a10394', name: 'template 4' },
    { id: 'b21b06fb-38dc-415b-b590-32ab20e8aa48', name: 'template 5' },
  ];

  const scrollRef = useRef<HTMLDivElement>(null);

  const autoScroll = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += 1;
    }
  };

  useEffect(() => {
    const interval = setInterval(autoScroll, 20); // Adjust the interval for different scroll speeds
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <p className="text-xl tracking-tight flex items-start justify-center mb-1">
        <IconCornerLeftDown className="w-7 h-10 text-gray-400 mr-2" />Pick one of our popular templates to customize and start automating <IconCornerRightDown className="w-7 h-10 text-gray-400 ml-2" />
      </p>

      <div ref={scrollRef} className="flex gap-2 h-56 w-full rounded-xl px-5 overflow-x-scroll bg-gradient-to-r from-primary-faded to-accent-faded bg-opacity-10 no-scrollbar">
        {/* <button className="absolute left-0 z-10 ml-4 top-[100px]">
          <IconArrowLeft />
        </button> */}

        {carouselTemplates.map(template =>
          <a key={template.id} className="p-2 rounded snap-start flex-shrink-0 w-60 flex items-center justify-center cursor-pointer" href={`/editor?master=${template.id}`}>
            <Tooltip title="Customize this template" placement="bottom">
              <img src={getTemplateThumbnailPath(template.id)} className="max-w-50 max-h-44 rounded hover:scale-110 shadow-lg hover:shadow-xl transition-all ease-in-out duration-300" />
            </Tooltip>
          </a>
        )}

        {/* <button className="absolute right-0 z-10 mr-4 top-1">
          <IconArrowRight />
        </button> */}
      </div>
    </div>
  )
}
