import { Button, Card, Flex, Form, Input, Popconfirm, Progress, Tag, Tooltip, message } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userState } from "../../state/atoms";
import "./UpgradePage.scss";
import { AlertCircle } from "lucide-react";
import { IconArrowRight, IconCreditCard, IconTrash } from "@tabler/icons-react";
import PaymentApi from "../../api/PaymentApi";
import { Helmet } from "react-helmet";

export default function AccountPage() {
  const navigate = useNavigate();
  const user = useRecoilValue(userState);
  const [loading, setLoading] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();
  const [success] = useSearchParams();
  const [canceled] = useSearchParams();
  const [session_id] = useSearchParams();
  const [messageApi, contextHolder] = message.useMessage();
  const [userForm] = Form.useForm();

  const apiUsagePercentage = user.apiUsage! / user.apiQuota! * 100;

  useEffect(() => {
    console.log(user);
  }, [user])

  function cancelSubscription() {
    PaymentApi.cancelSubscription().then(resp => {
      messageApi.open({
        type: 'success',
        content: resp.data,
      });
    })
  }

  async function manageSubscription() {
    setLoading(true);
    try {
      const response = await PaymentApi.createPortalSession();
      const portalUrl = response.data;
      window.location.href = portalUrl;
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: 'Failed to load the customer portal.',
      });
    }
  }

  return !user.id ? (
    <>Loading...</>
  ) : (
    <div className="account-page">
      <Helmet>
        <title>Account Settings | Templated</title>
      </Helmet>

      {contextHolder}

      <p className="text-lg mb-4">Account Settings</p>

      <Card className="w-[550px] mb-6">
        <span className="text-md text-center gap-2 flex items-center mb-1">
          <span>{user.apiUsage}</span> <span className="!text-gray-400"> / {user.apiQuota}</span>
          <IconArrowRight size={15} style={{ display: "inline" }} />
          <span>You have used <b className="">{apiUsagePercentage.toFixed(0)}%</b> your credits</span>
        </span>

        <Progress percent={apiUsagePercentage} size={["100%", 12]} strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }} status="active" showInfo={false} style={{ margin: 0 }} />
      </Card>

      <Card className="w-[550px]">
        <Flex vertical justify="space-between" gap={10}>

          <div>
            <label className="block mb-1 font-bold">Name</label>
            <Input size="large" value={user.name} readOnly className="w-full opacity-50" />
          </div>

          <div>
            <label className="block mb-1 font-bold">Email</label>
            <Input size="large" value={user.email} readOnly className="w-full opacity-50" />
          </div>

          <div>
            <label className="block mb-1 font-bold">Plan</label>
            <div className="text-lg flex items-center justify-between gap-3">
              <Tag color="green" className="text-xl mr-0 p-1 px-4">{user.plan || "Free"}</Tag>

              {user.plan &&
                <div className="flex items-center gap-4">
                  <Tooltip title="Update payment method">
                    <Button type="link" onClick={manageSubscription} loading={loading} icon={<IconCreditCard />}></Button>
                  </Tooltip>

                  <Popconfirm
                    className="inline"
                    placement="top"
                    arrow={false}
                    title="Cancel Subscription"
                    icon={<AlertCircle style={{ color: 'red', marginRight: 10 }} width={15} />}
                    description={<>Are you sure you want to cancel your subscription? <br />You will keep your API credits until the end of the month.</>}
                    onConfirm={() => cancelSubscription()}
                    okText="Cancel Subscription"
                    okButtonProps={{ danger: true, type: "dashed", size: "middle" }}
                    cancelButtonProps={{ type: "primary", size: "middle" }}
                    cancelText="Abort">
                    <Button type="primary" danger className="w-fit !bg-red-500">Cancel Subscription</Button>
                  </Popconfirm>
                </div>
              }
            </div>
          </div>
        </Flex>
      </Card>

      <Button type="primary" size="large" className="w-[550px] !py-6 mt-6 border-0 bg-gradient-to-r from-primary to-accent" onClick={() => navigate('/upgrade')}>Upgrade your plan</Button>
    </div>
  );
}