import { IconCloudDownload, IconCopy, IconCopyCheck, IconDots, IconDownload, IconEdit, IconFolderFilled, IconFolderPlus, IconFolderSymlink, IconPhoto, IconPlugConnected, IconSettings, IconStack2, IconStar, IconStarFilled, IconTrash } from "@tabler/icons-react";
import { Button, Card, Dropdown, Flex, Image, MenuProps, Popconfirm, Tooltip } from "antd";
import Meta from "antd/es/card/Meta";
import { AlertCircle } from "lucide-react";
import { FolderDto, TemplateDto, UserDto } from "../generated/edithor-client";
import loadingThumbnailGif from "../static/images/loading-thumbnail.gif";
import { useNavigate } from "react-router-dom";

interface Props {
  template: TemplateDto;
  user: UserDto;
  loading: boolean;
  thumbnails: any;
  editTemplate: any;
  copyId: any;
  seeTemplateRenders: any;
  seeTemplateLayers: any;
  remove: any;
  copy: any;
  downloadTemplate: any;
  addToFolder: any;
  toggleMaster: any;
  configMasterTemplate: any;
  folders: FolderDto[]
}

export default function TemplateCard({ template, user, loading, thumbnails, editTemplate, copyId, seeTemplateRenders, seeTemplateLayers, remove, copy, downloadTemplate, addToFolder, toggleMaster, configMasterTemplate, folders }: Props) {
  const navigate = useNavigate();

  return (
    <div className={`relative flex flex-col bg-white border rounded w-full h-fit gap-3 ${template.isMaster ? "border-2 border-purple-400" : ""}`}>
      {template.isMaster && <span className="absolute bg-purple-600 border border-purple-400 text-center text-white px-2 py-1 rounded top-1 right-1 z-10 text-xs">MASTER</span>}

      <div className="flex flex-col gap-2 border-b p-3">
        <div className="flex flex-col gap-1">
          <div className="h-fit w-full rounded flex items-center justify-center cursor-pointer bg-slate-50 p-2 border-2 transition-all ease-in-out duration-300 border-transparent hover:border-primary" onClick={(ev) => editTemplate(template, ev)}>
            <Image
              className="max-w-full max-h-full"
              wrapperClassName="w-fit h-48 flex items-center justify-center max-w-full max-h-full"
              style={{ boxShadow: "0 0 40px rgba(0, 0, 0, .07)" }}
              alt={template.name}
              preview={false}
              src={thumbnails[template.id!] || loadingThumbnailGif}
              fallback={loadingThumbnailGif}
              placeholder={<img src={loadingThumbnailGif} />}
            />
          </div>

          <p className="text-sm leading-snug tracking-normal text-gray-400 mt-1">
            {template.width} x {template.height} px
          </p>
        </div>

        <div className="flex flex-col justify-between flex-1">
          <p className="leading-snug truncate text-base">
            {template.name}
          </p>
        </div>
      </div>

      <div className="flex flex-row justify-between gap-0 p-3 pt-0">
        <div className="flex">
          <Button className="px-2 flex items-center border-dashed" onClick={() => navigate('/api-integration')}>
            <div className="flex gap-1 items-center uppercase text-xs">
              <IconPlugConnected width={15} height={15} /> Automate
            </div>
          </Button>
          {/* <Button className="px-2 flex items-center border-dashed" onClick={() => seeTemplateRenders(template)}>
            <div className="flex gap-1 items-center uppercase text-xs">
              <IconPhoto width={15} height={15} /> Renders
            </div>
          </Button> */}
        </div>

        <div className="flex gap-1">
          <Tooltip overlayInnerStyle={{ width: "fit-content" }} title={
            <div className="text-center w-80">
              Copy <span className="font-mono">ID</span> <br />
              <span className="font-mono font-bold">
                {template.id}
              </span>
            </div>
          }>
            <Button type="text" className="px-1 font-bold text-lg flex items-center font-mono" onClick={() => copyId(template)}>
              ID
            </Button>
          </Tooltip>

          <Tooltip title="Renders">
            <Button type="text" className="px-1 flex items-center" onClick={() => seeTemplateRenders(template)}>
              <IconPhoto className="w-5 font-bold" />
            </Button>
          </Tooltip>

          <Tooltip title="Layers">
            <Button type="text" className="px-1 flex items-center" onClick={() => seeTemplateLayers(template)}>
              <IconStack2 className="w-5 font-bold" />
            </Button>
          </Tooltip>

          <Tooltip title="More actions">
            <Dropdown
              overlayClassName=""
              className="p-2"
              trigger={["click"]}
              placement="topRight"
              
              menu={{
                items: [
                  {
                    key: '1',
                    danger: true,
                    icon: <IconTrash size={18} />,
                    label: (
                      <Popconfirm
                        placement="top"
                        arrow={false}
                        title="Delete template"
                        icon={<AlertCircle style={{ color: 'red', marginRight: 10 }} width={15} />}
                        description={`Are you sure you want to delete ${template.name}?`}
                        onConfirm={() => remove(template)}
                        okText="Yes"
                        cancelText="No">
                        Delete
                      </Popconfirm>
                    ),
                  },
                  {
                    key: '2',
                    icon: <IconCopy size={18} className="text-gray-500" />,
                    label: (
                      <Popconfirm
                        placement="top"
                        arrow={false}
                        title="Duplicate template"
                        icon={false}
                        description={`Are you sure you want to duplicate ${template.name}?`}
                        onConfirm={() => copy(template)}
                        okText="Yes"
                        cancelText="No">
                        <Flex align="center" gap={8}>
                          Duplicate
                        </Flex>
                      </Popconfirm>
                    ),
                  },
                  {
                    key: '3',
                    // disabled: true,
                    icon: <IconFolderSymlink size={18} className="text-gray-500 inline-block" />,
                    label: <>Add to folder</>,
                    children: folders.map((folder) => ({
                      icon: <IconFolderFilled size={18} className="text-gray-400"/>,
                      key: folder.id,
                      label: folder.name,
                      onClick: () => addToFolder(template, folder),
                    })),
                  },

                  {
                    key: '4',
                    icon: <IconCloudDownload size={18} className="text-gray-500" />,
                    label: (
                      <Flex align="center" gap={8} onClick={() => downloadTemplate(template)}>
                        Download
                      </Flex>
                    ),
                  },

                  // {
                  //   key: '5',
                  //   label: (
                  //     <Flex align="center" gap={8} onClick={() => seeTemplateRenders(template)}>
                  //       <IconPhoto size={18} className="text-gray-500" />
                  //       Renders
                  //     </Flex>
                  //   ),
                  // },

                  // {
                  //   key: '5',
                  //   label: (
                  //     <Flex align="center" gap={8} onClick={() => navigate('/api-integration')}>
                  //       <IconPlugConnected />
                  //       Automate
                  //     </Flex>
                  //   ),
                  // },

                  {
                    key: '6',
                    icon: template.isMaster ? <IconStarFilled size={18} className="text-gray-500 inline-block" /> : <IconStar size={18} className="text-gray-500 inline-block" />,
                    style: { display: user.isAdmin ? 'block' : 'none' },
                    label: (
                      <Popconfirm
                        placement="top"
                        arrow={false}
                        title="Master"
                        icon={<AlertCircle style={{ color: 'red', marginRight: 10 }} width={15} />}
                        description={`Are you sure?`}
                        onConfirm={() => toggleMaster(template)}
                        okText="Yes"
                        cancelText="No">
                        <span className="text-purple-600">
                          {template.isMaster ? 'Remove Master' : 'Turn Master'}
                        </span>
                      </Popconfirm>
                    ),
                  },

                  {
                    key: '7',
                    icon: <IconSettings />,
                    style: { display: user.isAdmin && template.isMaster ? 'block' : 'none' },
                    label: (
                      <Flex align="center" gap={8} onClick={() => configMasterTemplate(template)}>
                        Config
                      </Flex>
                    ),
                  },
                ] as MenuProps['items']
              }}
            >
              <Button type="text" className="px-1 font-bold text-lg flex items-center font-mono">
                <IconDots />
              </Button>
            </Dropdown>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}
