import { AxiosPromise } from "axios";
import { EmbedConfigDto, EmbedConfigResourceApi } from "../generated/edithor-client";
import { apiConfig, ops } from "./config";

const api = new EmbedConfigResourceApi(apiConfig);

const EmbedConfigApi = {
  get(id: string): AxiosPromise<EmbedConfigDto> {
    return api.getEmbedConfig1(id, ops());
  },
};

export default EmbedConfigApi;