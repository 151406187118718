/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AuthDto
 */
export interface AuthDto {
    /**
     * 
     * @type {UserDto}
     * @memberof AuthDto
     */
    'user'?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof AuthDto
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface CategoryDto
 */
export interface CategoryDto {
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    'cover'?: string;
}
/**
 * 
 * @export
 * @interface EmbedConfigDto
 */
export interface EmbedConfigDto {
    /**
     * 
     * @type {string}
     * @memberof EmbedConfigDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmbedConfigDto
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmbedConfigDto
     */
    'domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmbedConfigDto
     */
    'logoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmbedConfigDto
     */
    'primaryColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmbedConfigDto
     */
    'secondaryColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmbedConfigDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmbedConfigDto
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface FolderDto
 */
export interface FolderDto {
    /**
     * 
     * @type {string}
     * @memberof FolderDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FolderDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof FolderDto
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FolderDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof FolderDto
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface FontDto
 */
export interface FontDto {
    /**
     * 
     * @type {string}
     * @memberof FontDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FontDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof FontDto
     */
    'path'?: string;
    /**
     * 
     * @type {string}
     * @memberof FontDto
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FontDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof FontDto
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface GoogleAuthDto
 */
export interface GoogleAuthDto {
    /**
     * 
     * @type {string}
     * @memberof GoogleAuthDto
     */
    'idToken'?: string;
}
/**
 * 
 * @export
 * @interface GoogleMerchantProduct
 */
export interface GoogleMerchantProduct {
    /**
     * 
     * @type {string}
     * @memberof GoogleMerchantProduct
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GoogleMerchantProduct
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof GoogleMerchantProduct
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof GoogleMerchantProduct
     */
    'link'?: string;
    /**
     * 
     * @type {string}
     * @memberof GoogleMerchantProduct
     */
    'imageLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof GoogleMerchantProduct
     */
    'brand'?: string;
    /**
     * 
     * @type {string}
     * @memberof GoogleMerchantProduct
     */
    'price'?: string;
    /**
     * 
     * @type {string}
     * @memberof GoogleMerchantProduct
     */
    'availability'?: string;
    /**
     * 
     * @type {string}
     * @memberof GoogleMerchantProduct
     */
    'condition'?: string;
    /**
     * 
     * @type {string}
     * @memberof GoogleMerchantProduct
     */
    'newImageLink'?: string;
}
/**
 * 
 * @export
 * @interface Layer
 */
export interface Layer {
    /**
     * 
     * @type {string}
     * @memberof Layer
     */
    'layer'?: string;
    /**
     * 
     * @type {string}
     * @memberof Layer
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Layer
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof Layer
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof Layer
     */
    'font_family'?: string;
    /**
     * 
     * @type {string}
     * @memberof Layer
     */
    'font_size'?: string;
    /**
     * 
     * @type {string}
     * @memberof Layer
     */
    'image_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof Layer
     */
    'background'?: string;
    /**
     * 
     * @type {number}
     * @memberof Layer
     */
    'border_width'?: number;
    /**
     * 
     * @type {string}
     * @memberof Layer
     */
    'border_color'?: string;
    /**
     * 
     * @type {string}
     * @memberof Layer
     */
    'fill'?: string;
    /**
     * 
     * @type {string}
     * @memberof Layer
     */
    'stroke'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Layer
     */
    'hide'?: boolean;
}
/**
 * 
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'password'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LoginDto
     */
    'admin'?: boolean;
}
/**
 * 
 * @export
 * @interface LogoutDto
 */
export interface LogoutDto {
    /**
     * 
     * @type {string}
     * @memberof LogoutDto
     */
    'tkn'?: string;
}
/**
 * 
 * @export
 * @interface Payload
 */
export interface Payload {
    /**
     * 
     * @type {string}
     * @memberof Payload
     */
    'template'?: string;
    /**
     * 
     * @type {string}
     * @memberof Payload
     */
    'webhook_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Payload
     */
    'async'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Payload
     */
    'format'?: string;
    /**
     * 
     * @type {string}
     * @memberof Payload
     */
    'render_url'?: string;
    /**
     * 
     * @type {{ [key: string]: Layer; }}
     * @memberof Payload
     */
    'layers'?: { [key: string]: Layer; };
    /**
     * 
     * @type {boolean}
     * @memberof Payload
     */
    'transparent'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Payload
     */
    'cmyk'?: boolean;
}
/**
 * 
 * @export
 * @interface PayloadNoCode
 */
export interface PayloadNoCode {
    /**
     * 
     * @type {string}
     * @memberof PayloadNoCode
     */
    'template'?: string;
    /**
     * 
     * @type {string}
     * @memberof PayloadNoCode
     */
    'webhook_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PayloadNoCode
     */
    'async'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PayloadNoCode
     */
    'format'?: string;
    /**
     * 
     * @type {string}
     * @memberof PayloadNoCode
     */
    'render_url'?: string;
    /**
     * 
     * @type {Array<Layer>}
     * @memberof PayloadNoCode
     */
    'layers'?: Array<Layer>;
}
/**
 * 
 * @export
 * @interface RenderDto
 */
export interface RenderDto {
    /**
     * 
     * @type {string}
     * @memberof RenderDto
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof RenderDto
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof RenderDto
     */
    'height'?: number;
    /**
     * 
     * @type {string}
     * @memberof RenderDto
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof RenderDto
     */
    'status'?: RenderDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof RenderDto
     */
    'format'?: string;
    /**
     * 
     * @type {string}
     * @memberof RenderDto
     */
    'templateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RenderDto
     */
    'templateName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RenderDto
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RenderDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof RenderDto
     */
    'updatedAt'?: string;
}

export const RenderDtoStatusEnum = {
    Pending: 'PENDING',
    Completed: 'COMPLETED',
    Failed: 'FAILED'
} as const;

export type RenderDtoStatusEnum = typeof RenderDtoStatusEnum[keyof typeof RenderDtoStatusEnum];

/**
 * 
 * @export
 * @interface TemplateDto
 */
export interface TemplateDto {
    /**
     * 
     * @type {boolean}
     * @memberof TemplateDto
     */
    'isMaster'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TemplateDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateDto
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof TemplateDto
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof TemplateDto
     */
    'height'?: number;
    /**
     * 
     * @type {string}
     * @memberof TemplateDto
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateDto
     */
    'html'?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateDto
     */
    'thumbnail'?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateDto
     */
    'categoryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateDto
     */
    'categoryName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateDto
     */
    'folderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateDto
     */
    'tags'?: string;
    /**
     * 
     * @type {number}
     * @memberof TemplateDto
     */
    'layersCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof TemplateDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TemplateDto
     */
    'master'?: boolean;
}
/**
 * 
 * @export
 * @interface UploadDto
 */
export interface UploadDto {
    /**
     * 
     * @type {string}
     * @memberof UploadDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof UploadDto
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof UploadDto
     */
    'path'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadDto
     */
    'contentType'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadDto
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadDto
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'isAdmin'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'currentPassword'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'logged'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'changePassword'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'apiKey'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    'apiQuota'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    'apiUsage'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'stripeCustomerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'status'?: UserDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'plan'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'billingPeriod'?: UserDtoBillingPeriodEnum;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'googleSub'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'admin'?: boolean;
}

export const UserDtoStatusEnum = {
    Free: 'FREE',
    Active: 'ACTIVE',
    Expired: 'EXPIRED'
} as const;

export type UserDtoStatusEnum = typeof UserDtoStatusEnum[keyof typeof UserDtoStatusEnum];
export const UserDtoBillingPeriodEnum = {
    Month: 'MONTH',
    Year: 'YEAR'
} as const;

export type UserDtoBillingPeriodEnum = typeof UserDtoBillingPeriodEnum[keyof typeof UserDtoBillingPeriodEnum];


/**
 * AssetResourceApi - axios parameter creator
 * @export
 */
export const AssetResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} fromUrl 
         * @param {string} toPath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAsset: async (fromUrl: string, toPath: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fromUrl' is not null or undefined
            assertParamExists('uploadAsset', 'fromUrl', fromUrl)
            // verify required parameter 'toPath' is not null or undefined
            assertParamExists('uploadAsset', 'toPath', toPath)
            const localVarPath = `/assets/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fromUrl !== undefined) {
                localVarQueryParameter['fromUrl'] = fromUrl;
            }

            if (toPath !== undefined) {
                localVarQueryParameter['toPath'] = toPath;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetResourceApi - functional programming interface
 * @export
 */
export const AssetResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} fromUrl 
         * @param {string} toPath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadAsset(fromUrl: string, toPath: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadAsset(fromUrl, toPath, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssetResourceApi - factory interface
 * @export
 */
export const AssetResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetResourceApiFp(configuration)
    return {
        /**
         * 
         * @param {string} fromUrl 
         * @param {string} toPath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAsset(fromUrl: string, toPath: string, options?: any): AxiosPromise<void> {
            return localVarFp.uploadAsset(fromUrl, toPath, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssetResourceApi - object-oriented interface
 * @export
 * @class AssetResourceApi
 * @extends {BaseAPI}
 */
export class AssetResourceApi extends BaseAPI {
    /**
     * 
     * @param {string} fromUrl 
     * @param {string} toPath 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetResourceApi
     */
    public uploadAsset(fromUrl: string, toPath: string, options?: AxiosRequestConfig) {
        return AssetResourceApiFp(this.configuration).uploadAsset(fromUrl, toPath, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthResourceApi - axios parameter creator
 * @export
 */
export const AuthResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GoogleAuthDto} googleAuthDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleAuth: async (googleAuthDto: GoogleAuthDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'googleAuthDto' is not null or undefined
            assertParamExists('googleAuth', 'googleAuthDto', googleAuthDto)
            const localVarPath = `/public/google-auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(googleAuthDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginDto: LoginDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDto' is not null or undefined
            assertParamExists('login', 'loginDto', loginDto)
            const localVarPath = `/public/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LogoutDto} logoutDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (logoutDto: LogoutDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'logoutDto' is not null or undefined
            assertParamExists('logout', 'logoutDto', logoutDto)
            const localVarPath = `/public/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logoutDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (loginDto: LoginDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDto' is not null or undefined
            assertParamExists('resetPassword', 'loginDto', loginDto)
            const localVarPath = `/public/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserDto} userDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup: async (userDto: UserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userDto' is not null or undefined
            assertParamExists('signup', 'userDto', userDto)
            const localVarPath = `/public/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthResourceApi - functional programming interface
 * @export
 */
export const AuthResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GoogleAuthDto} googleAuthDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async googleAuth(googleAuthDto: GoogleAuthDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.googleAuth(googleAuthDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginDto: LoginDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LogoutDto} logoutDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(logoutDto: LogoutDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(logoutDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(loginDto: LoginDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(loginDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserDto} userDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signup(userDto: UserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signup(userDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthResourceApi - factory interface
 * @export
 */
export const AuthResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthResourceApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auth(options?: any): AxiosPromise<UserDto> {
            return localVarFp.auth(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GoogleAuthDto} googleAuthDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleAuth(googleAuthDto: GoogleAuthDto, options?: any): AxiosPromise<AuthDto> {
            return localVarFp.googleAuth(googleAuthDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginDto: LoginDto, options?: any): AxiosPromise<AuthDto> {
            return localVarFp.login(loginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LogoutDto} logoutDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(logoutDto: LogoutDto, options?: any): AxiosPromise<void> {
            return localVarFp.logout(logoutDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(loginDto: LoginDto, options?: any): AxiosPromise<void> {
            return localVarFp.resetPassword(loginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserDto} userDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup(userDto: UserDto, options?: any): AxiosPromise<AuthDto> {
            return localVarFp.signup(userDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthResourceApi - object-oriented interface
 * @export
 * @class AuthResourceApi
 * @extends {BaseAPI}
 */
export class AuthResourceApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthResourceApi
     */
    public auth(options?: AxiosRequestConfig) {
        return AuthResourceApiFp(this.configuration).auth(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GoogleAuthDto} googleAuthDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthResourceApi
     */
    public googleAuth(googleAuthDto: GoogleAuthDto, options?: AxiosRequestConfig) {
        return AuthResourceApiFp(this.configuration).googleAuth(googleAuthDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginDto} loginDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthResourceApi
     */
    public login(loginDto: LoginDto, options?: AxiosRequestConfig) {
        return AuthResourceApiFp(this.configuration).login(loginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LogoutDto} logoutDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthResourceApi
     */
    public logout(logoutDto: LogoutDto, options?: AxiosRequestConfig) {
        return AuthResourceApiFp(this.configuration).logout(logoutDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginDto} loginDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthResourceApi
     */
    public resetPassword(loginDto: LoginDto, options?: AxiosRequestConfig) {
        return AuthResourceApiFp(this.configuration).resetPassword(loginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserDto} userDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthResourceApi
     */
    public signup(userDto: UserDto, options?: AxiosRequestConfig) {
        return AuthResourceApiFp(this.configuration).signup(userDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CategoryResourceApi - axios parameter creator
 * @export
 */
export const CategoryResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CategoryDto} categoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCategory: async (categoryDto: CategoryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryDto' is not null or undefined
            assertParamExists('createCategory', 'categoryDto', categoryDto)
            const localVarPath = `/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCategory', 'id', id)
            const localVarPath = `/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCategories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategory: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCategory', 'id', id)
            const localVarPath = `/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryTemplates: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCategoryTemplates', 'id', id)
            const localVarPath = `/categories/{id}/templates`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CategoryDto} categoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCategory: async (id: string, categoryDto: CategoryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCategory', 'id', id)
            // verify required parameter 'categoryDto' is not null or undefined
            assertParamExists('updateCategory', 'categoryDto', categoryDto)
            const localVarPath = `/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoryResourceApi - functional programming interface
 * @export
 */
export const CategoryResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoryResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CategoryDto} categoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCategory(categoryDto: CategoryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCategory(categoryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCategory(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCategory(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCategories(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategory(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategory(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategoryTemplates(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TemplateDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategoryTemplates(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {CategoryDto} categoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCategory(id: string, categoryDto: CategoryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCategory(id, categoryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CategoryResourceApi - factory interface
 * @export
 */
export const CategoryResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoryResourceApiFp(configuration)
    return {
        /**
         * 
         * @param {CategoryDto} categoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCategory(categoryDto: CategoryDto, options?: any): AxiosPromise<CategoryDto> {
            return localVarFp.createCategory(categoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCategory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCategories(options?: any): AxiosPromise<Array<CategoryDto>> {
            return localVarFp.getAllCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategory(id: string, options?: any): AxiosPromise<CategoryDto> {
            return localVarFp.getCategory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryTemplates(id: string, options?: any): AxiosPromise<Array<TemplateDto>> {
            return localVarFp.getCategoryTemplates(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {CategoryDto} categoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCategory(id: string, categoryDto: CategoryDto, options?: any): AxiosPromise<CategoryDto> {
            return localVarFp.updateCategory(id, categoryDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CategoryResourceApi - object-oriented interface
 * @export
 * @class CategoryResourceApi
 * @extends {BaseAPI}
 */
export class CategoryResourceApi extends BaseAPI {
    /**
     * 
     * @param {CategoryDto} categoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryResourceApi
     */
    public createCategory(categoryDto: CategoryDto, options?: AxiosRequestConfig) {
        return CategoryResourceApiFp(this.configuration).createCategory(categoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryResourceApi
     */
    public deleteCategory(id: string, options?: AxiosRequestConfig) {
        return CategoryResourceApiFp(this.configuration).deleteCategory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryResourceApi
     */
    public getAllCategories(options?: AxiosRequestConfig) {
        return CategoryResourceApiFp(this.configuration).getAllCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryResourceApi
     */
    public getCategory(id: string, options?: AxiosRequestConfig) {
        return CategoryResourceApiFp(this.configuration).getCategory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryResourceApi
     */
    public getCategoryTemplates(id: string, options?: AxiosRequestConfig) {
        return CategoryResourceApiFp(this.configuration).getCategoryTemplates(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {CategoryDto} categoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryResourceApi
     */
    public updateCategory(id: string, categoryDto: CategoryDto, options?: AxiosRequestConfig) {
        return CategoryResourceApiFp(this.configuration).updateCategory(id, categoryDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmbedConfigResourceApi - axios parameter creator
 * @export
 */
export const EmbedConfigResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmbedConfig1: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEmbedConfig1', 'id', id)
            const localVarPath = `/public/embed-config/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmbedConfigResourceApi - functional programming interface
 * @export
 */
export const EmbedConfigResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmbedConfigResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmbedConfig1(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmbedConfigDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmbedConfig1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmbedConfigResourceApi - factory interface
 * @export
 */
export const EmbedConfigResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmbedConfigResourceApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmbedConfig1(id: string, options?: any): AxiosPromise<EmbedConfigDto> {
            return localVarFp.getEmbedConfig1(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmbedConfigResourceApi - object-oriented interface
 * @export
 * @class EmbedConfigResourceApi
 * @extends {BaseAPI}
 */
export class EmbedConfigResourceApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbedConfigResourceApi
     */
    public getEmbedConfig1(id: string, options?: AxiosRequestConfig) {
        return EmbedConfigResourceApiFp(this.configuration).getEmbedConfig1(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FolderResourceApi - axios parameter creator
 * @export
 */
export const FolderResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FolderDto} folderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFolder: async (folderDto: FolderDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'folderDto' is not null or undefined
            assertParamExists('createFolder', 'folderDto', folderDto)
            const localVarPath = `/folders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(folderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFolder: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFolder', 'id', id)
            const localVarPath = `/folders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolder: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFolder', 'id', id)
            const localVarPath = `/folders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolderTemplates: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFolderTemplates', 'id', id)
            const localVarPath = `/folders/{id}/templates`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {FolderDto} folderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFolder: async (id: string, folderDto: FolderDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFolder', 'id', id)
            // verify required parameter 'folderDto' is not null or undefined
            assertParamExists('updateFolder', 'folderDto', folderDto)
            const localVarPath = `/folders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(folderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FolderResourceApi - functional programming interface
 * @export
 */
export const FolderResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FolderResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {FolderDto} folderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFolder(folderDto: FolderDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FolderDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFolder(folderDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFolder(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFolder(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFolder(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FolderDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFolder(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFolderTemplates(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TemplateDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFolderTemplates(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {FolderDto} folderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFolder(id: string, folderDto: FolderDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FolderDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFolder(id, folderDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FolderResourceApi - factory interface
 * @export
 */
export const FolderResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FolderResourceApiFp(configuration)
    return {
        /**
         * 
         * @param {FolderDto} folderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFolder(folderDto: FolderDto, options?: any): AxiosPromise<FolderDto> {
            return localVarFp.createFolder(folderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFolder(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFolder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolder(id: string, options?: any): AxiosPromise<FolderDto> {
            return localVarFp.getFolder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolderTemplates(id: string, options?: any): AxiosPromise<Array<TemplateDto>> {
            return localVarFp.getFolderTemplates(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {FolderDto} folderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFolder(id: string, folderDto: FolderDto, options?: any): AxiosPromise<FolderDto> {
            return localVarFp.updateFolder(id, folderDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FolderResourceApi - object-oriented interface
 * @export
 * @class FolderResourceApi
 * @extends {BaseAPI}
 */
export class FolderResourceApi extends BaseAPI {
    /**
     * 
     * @param {FolderDto} folderDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderResourceApi
     */
    public createFolder(folderDto: FolderDto, options?: AxiosRequestConfig) {
        return FolderResourceApiFp(this.configuration).createFolder(folderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderResourceApi
     */
    public deleteFolder(id: string, options?: AxiosRequestConfig) {
        return FolderResourceApiFp(this.configuration).deleteFolder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderResourceApi
     */
    public getFolder(id: string, options?: AxiosRequestConfig) {
        return FolderResourceApiFp(this.configuration).getFolder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderResourceApi
     */
    public getFolderTemplates(id: string, options?: AxiosRequestConfig) {
        return FolderResourceApiFp(this.configuration).getFolderTemplates(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {FolderDto} folderDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderResourceApi
     */
    public updateFolder(id: string, folderDto: FolderDto, options?: AxiosRequestConfig) {
        return FolderResourceApiFp(this.configuration).updateFolder(id, folderDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentResourceApi - axios parameter creator
 * @export
 */
export const PaymentResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSubscription: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/payments/cancel-subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCheckoutSession: async (planId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planId' is not null or undefined
            assertParamExists('createCheckoutSession', 'planId', planId)
            const localVarPath = `/payments/create-checkout-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (planId !== undefined) {
                localVarQueryParameter['planId'] = planId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPortalSession: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/payments/create-portal-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentResourceApi - functional programming interface
 * @export
 */
export const PaymentResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelSubscription(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelSubscription(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCheckoutSession(planId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCheckoutSession(planId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPortalSession(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPortalSession(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentResourceApi - factory interface
 * @export
 */
export const PaymentResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentResourceApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSubscription(options?: any): AxiosPromise<string> {
            return localVarFp.cancelSubscription(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCheckoutSession(planId: string, options?: any): AxiosPromise<string> {
            return localVarFp.createCheckoutSession(planId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPortalSession(options?: any): AxiosPromise<string> {
            return localVarFp.createPortalSession(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentResourceApi - object-oriented interface
 * @export
 * @class PaymentResourceApi
 * @extends {BaseAPI}
 */
export class PaymentResourceApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentResourceApi
     */
    public cancelSubscription(options?: AxiosRequestConfig) {
        return PaymentResourceApiFp(this.configuration).cancelSubscription(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} planId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentResourceApi
     */
    public createCheckoutSession(planId: string, options?: AxiosRequestConfig) {
        return PaymentResourceApiFp(this.configuration).createCheckoutSession(planId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentResourceApi
     */
    public createPortalSession(options?: AxiosRequestConfig) {
        return PaymentResourceApiFp(this.configuration).createPortalSession(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RenderResourceApi - axios parameter creator
 * @export
 */
export const RenderResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} templateId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download: async (templateId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('download', 'templateId', templateId)
            const localVarPath = `/v1/download/{templateId}`
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRender: async (id: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRender', 'id', id)
            const localVarPath = `/v1/render/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplate: async (id: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTemplate', 'id', id)
            const localVarPath = `/v1/template/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateLayers: async (id: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTemplateLayers', 'id', id)
            const localVarPath = `/v1/template/{id}/layers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateRenders: async (id: string, page?: number, limit?: number, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTemplateRenders', 'id', id)
            const localVarPath = `/v1/template/{id}/renders`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplates: async (page?: number, limit?: number, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} file 
         * @param {number} [qty] 
         * @param {string} [templateId] 
         * @param {any} [logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parseGoogleMerchantFile: async (file: any, qty?: number, templateId?: string, logo?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('parseGoogleMerchantFile', 'file', file)
            const localVarPath = `/v1/public/google/merchant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (qty !== undefined) {
                localVarQueryParameter['qty'] = qty;
            }

            if (templateId !== undefined) {
                localVarQueryParameter['templateId'] = templateId;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (logo !== undefined) { 
                localVarFormParams.append('logo', logo as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} file 
         * @param {number} [qty] 
         * @param {string} [templateId] 
         * @param {any} [logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parseMetaFeedFile: async (file: any, qty?: number, templateId?: string, logo?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('parseMetaFeedFile', 'file', file)
            const localVarPath = `/v1/public/meta/feed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (qty !== undefined) {
                localVarQueryParameter['qty'] = qty;
            }

            if (templateId !== undefined) {
                localVarQueryParameter['templateId'] = templateId;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (logo !== undefined) { 
                localVarFormParams.append('logo', logo as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Payload} payload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        render: async (payload: Payload, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'payload' is not null or undefined
            assertParamExists('render', 'payload', payload)
            const localVarPath = `/v1/render`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PayloadNoCode} payloadNoCode 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        render1: async (payloadNoCode: PayloadNoCode, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'payloadNoCode' is not null or undefined
            assertParamExists('render1', 'payloadNoCode', payloadNoCode)
            const localVarPath = `/v1/nocode/render`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payloadNoCode, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testAuth: async (authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testWebhook: async (body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('testWebhook', 'body', body)
            const localVarPath = `/v1/test/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upload: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('upload', 'file', file)
            const localVarPath = `/v1/public/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} url 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xtogram: async (url: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'url' is not null or undefined
            assertParamExists('xtogram', 'url', url)
            const localVarPath = `/v1/public/xtogram`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RenderResourceApi - functional programming interface
 * @export
 */
export const RenderResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RenderResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} templateId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async download(templateId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RenderDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.download(templateId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRender(id: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RenderDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRender(id, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplate(id: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTemplate(id, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplateLayers(id: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Layer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTemplateLayers(id, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplateRenders(id: string, page?: number, limit?: number, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RenderDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTemplateRenders(id, page, limit, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplates(page?: number, limit?: number, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TemplateDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTemplates(page, limit, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} file 
         * @param {number} [qty] 
         * @param {string} [templateId] 
         * @param {any} [logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async parseGoogleMerchantFile(file: any, qty?: number, templateId?: string, logo?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GoogleMerchantProduct>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.parseGoogleMerchantFile(file, qty, templateId, logo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} file 
         * @param {number} [qty] 
         * @param {string} [templateId] 
         * @param {any} [logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async parseMetaFeedFile(file: any, qty?: number, templateId?: string, logo?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.parseMetaFeedFile(file, qty, templateId, logo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Payload} payload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async render(payload: Payload, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Payload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.render(payload, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PayloadNoCode} payloadNoCode 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async render1(payloadNoCode: PayloadNoCode, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PayloadNoCode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.render1(payloadNoCode, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testAuth(authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testAuth(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testWebhook(body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testWebhook(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upload(file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upload(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} url 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xtogram(url: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xtogram(url, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RenderResourceApi - factory interface
 * @export
 */
export const RenderResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RenderResourceApiFp(configuration)
    return {
        /**
         * 
         * @param {string} templateId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download(templateId: string, authorization?: string, options?: any): AxiosPromise<RenderDto> {
            return localVarFp.download(templateId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRender(id: string, authorization?: string, options?: any): AxiosPromise<RenderDto> {
            return localVarFp.getRender(id, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplate(id: string, authorization?: string, options?: any): AxiosPromise<TemplateDto> {
            return localVarFp.getTemplate(id, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateLayers(id: string, authorization?: string, options?: any): AxiosPromise<Array<Layer>> {
            return localVarFp.getTemplateLayers(id, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateRenders(id: string, page?: number, limit?: number, authorization?: string, options?: any): AxiosPromise<Array<RenderDto>> {
            return localVarFp.getTemplateRenders(id, page, limit, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplates(page?: number, limit?: number, authorization?: string, options?: any): AxiosPromise<Array<TemplateDto>> {
            return localVarFp.getTemplates(page, limit, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} file 
         * @param {number} [qty] 
         * @param {string} [templateId] 
         * @param {any} [logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parseGoogleMerchantFile(file: any, qty?: number, templateId?: string, logo?: any, options?: any): AxiosPromise<Array<GoogleMerchantProduct>> {
            return localVarFp.parseGoogleMerchantFile(file, qty, templateId, logo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} file 
         * @param {number} [qty] 
         * @param {string} [templateId] 
         * @param {any} [logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parseMetaFeedFile(file: any, qty?: number, templateId?: string, logo?: any, options?: any): AxiosPromise<string> {
            return localVarFp.parseMetaFeedFile(file, qty, templateId, logo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Payload} payload 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        render(payload: Payload, authorization?: string, options?: any): AxiosPromise<Payload> {
            return localVarFp.render(payload, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PayloadNoCode} payloadNoCode 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        render1(payloadNoCode: PayloadNoCode, authorization?: string, options?: any): AxiosPromise<PayloadNoCode> {
            return localVarFp.render1(payloadNoCode, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testAuth(authorization?: string, options?: any): AxiosPromise<UserDto> {
            return localVarFp.testAuth(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testWebhook(body: string, options?: any): AxiosPromise<void> {
            return localVarFp.testWebhook(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upload(file: any, options?: any): AxiosPromise<UploadDto> {
            return localVarFp.upload(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} url 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xtogram(url: string, authorization?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.xtogram(url, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RenderResourceApi - object-oriented interface
 * @export
 * @class RenderResourceApi
 * @extends {BaseAPI}
 */
export class RenderResourceApi extends BaseAPI {
    /**
     * 
     * @param {string} templateId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenderResourceApi
     */
    public download(templateId: string, authorization?: string, options?: AxiosRequestConfig) {
        return RenderResourceApiFp(this.configuration).download(templateId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenderResourceApi
     */
    public getRender(id: string, authorization?: string, options?: AxiosRequestConfig) {
        return RenderResourceApiFp(this.configuration).getRender(id, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenderResourceApi
     */
    public getTemplate(id: string, authorization?: string, options?: AxiosRequestConfig) {
        return RenderResourceApiFp(this.configuration).getTemplate(id, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenderResourceApi
     */
    public getTemplateLayers(id: string, authorization?: string, options?: AxiosRequestConfig) {
        return RenderResourceApiFp(this.configuration).getTemplateLayers(id, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenderResourceApi
     */
    public getTemplateRenders(id: string, page?: number, limit?: number, authorization?: string, options?: AxiosRequestConfig) {
        return RenderResourceApiFp(this.configuration).getTemplateRenders(id, page, limit, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenderResourceApi
     */
    public getTemplates(page?: number, limit?: number, authorization?: string, options?: AxiosRequestConfig) {
        return RenderResourceApiFp(this.configuration).getTemplates(page, limit, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} file 
     * @param {number} [qty] 
     * @param {string} [templateId] 
     * @param {any} [logo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenderResourceApi
     */
    public parseGoogleMerchantFile(file: any, qty?: number, templateId?: string, logo?: any, options?: AxiosRequestConfig) {
        return RenderResourceApiFp(this.configuration).parseGoogleMerchantFile(file, qty, templateId, logo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} file 
     * @param {number} [qty] 
     * @param {string} [templateId] 
     * @param {any} [logo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenderResourceApi
     */
    public parseMetaFeedFile(file: any, qty?: number, templateId?: string, logo?: any, options?: AxiosRequestConfig) {
        return RenderResourceApiFp(this.configuration).parseMetaFeedFile(file, qty, templateId, logo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Payload} payload 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenderResourceApi
     */
    public render(payload: Payload, authorization?: string, options?: AxiosRequestConfig) {
        return RenderResourceApiFp(this.configuration).render(payload, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PayloadNoCode} payloadNoCode 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenderResourceApi
     */
    public render1(payloadNoCode: PayloadNoCode, authorization?: string, options?: AxiosRequestConfig) {
        return RenderResourceApiFp(this.configuration).render1(payloadNoCode, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenderResourceApi
     */
    public testAuth(authorization?: string, options?: AxiosRequestConfig) {
        return RenderResourceApiFp(this.configuration).testAuth(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenderResourceApi
     */
    public testWebhook(body: string, options?: AxiosRequestConfig) {
        return RenderResourceApiFp(this.configuration).testWebhook(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenderResourceApi
     */
    public upload(file: any, options?: AxiosRequestConfig) {
        return RenderResourceApiFp(this.configuration).upload(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} url 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenderResourceApi
     */
    public xtogram(url: string, authorization?: string, options?: AxiosRequestConfig) {
        return RenderResourceApiFp(this.configuration).xtogram(url, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TemplateResourceApi - axios parameter creator
 * @export
 */
export const TemplateResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyTemplate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('copyTemplate', 'id', id)
            const localVarPath = `/templates/{id}/copy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TemplateDto} templateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTemplate: async (templateDto: TemplateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateDto' is not null or undefined
            assertParamExists('createTemplate', 'templateDto', templateDto)
            const localVarPath = `/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(templateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} masterId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTemplateFromMaster: async (masterId: string, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'masterId' is not null or undefined
            assertParamExists('createTemplateFromMaster', 'masterId', masterId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createTemplateFromMaster', 'userId', userId)
            const localVarPath = `/templates/master/{masterId}`
                .replace(`{${"masterId"}}`, encodeURIComponent(String(masterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMasterTemplateByName: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getMasterTemplateByName', 'name', name)
            const localVarPath = `/templates/master/name/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMasterTemplateCategoryAndTags: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/templates/master/category-and-tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMasterTemplateDescriptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/templates/master/descriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMasterTemplates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/templates/master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplate1: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTemplate1', 'id', id)
            const localVarPath = `/templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateLayers1: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTemplateLayers1', 'id', id)
            const localVarPath = `/templates/{id}/layers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateRenders1: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTemplateRenders1', 'id', id)
            const localVarPath = `/templates/{id}/renders`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateThumbnail: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTemplateThumbnail', 'id', id)
            const localVarPath = `/templates/thumbnail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveToFolder: async (id: string, folderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('moveToFolder', 'id', id)
            // verify required parameter 'folderId' is not null or undefined
            assertParamExists('moveToFolder', 'folderId', folderId)
            const localVarPath = `/templates/{id}/folder`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (folderId !== undefined) {
                localVarQueryParameter['folderId'] = folderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenAllMasterThumbnails: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/templates/master/thumbnails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTemplate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeTemplate', 'id', id)
            const localVarPath = `/templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleMasterTemplate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('toggleMasterTemplate', 'id', id)
            const localVarPath = `/templates/{id}/toggle/master`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {TemplateDto} templateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplate: async (id: string, templateDto: TemplateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTemplate', 'id', id)
            // verify required parameter 'templateDto' is not null or undefined
            assertParamExists('updateTemplate', 'templateDto', templateDto)
            const localVarPath = `/templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(templateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TemplateResourceApi - functional programming interface
 * @export
 */
export const TemplateResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TemplateResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyTemplate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TemplateDto} templateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTemplate(templateDto: TemplateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTemplate(templateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} masterId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTemplateFromMaster(masterId: string, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTemplateFromMaster(masterId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMasterTemplateByName(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMasterTemplateByName(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMasterTemplateCategoryAndTags(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMasterTemplateCategoryAndTags(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMasterTemplateDescriptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMasterTemplateDescriptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMasterTemplates(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TemplateDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMasterTemplates(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplate1(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTemplate1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplateLayers1(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Layer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTemplateLayers1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplateRenders1(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RenderDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTemplateRenders1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplateThumbnail(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTemplateThumbnail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveToFolder(id: string, folderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moveToFolder(id, folderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async regenAllMasterThumbnails(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.regenAllMasterThumbnails(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeTemplate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async toggleMasterTemplate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.toggleMasterTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {TemplateDto} templateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTemplate(id: string, templateDto: TemplateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTemplate(id, templateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TemplateResourceApi - factory interface
 * @export
 */
export const TemplateResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TemplateResourceApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyTemplate(id: string, options?: any): AxiosPromise<TemplateDto> {
            return localVarFp.copyTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TemplateDto} templateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTemplate(templateDto: TemplateDto, options?: any): AxiosPromise<TemplateDto> {
            return localVarFp.createTemplate(templateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} masterId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTemplateFromMaster(masterId: string, userId: string, options?: any): AxiosPromise<TemplateDto> {
            return localVarFp.createTemplateFromMaster(masterId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMasterTemplateByName(name: string, options?: any): AxiosPromise<TemplateDto> {
            return localVarFp.getMasterTemplateByName(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMasterTemplateCategoryAndTags(options?: any): AxiosPromise<void> {
            return localVarFp.getMasterTemplateCategoryAndTags(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMasterTemplateDescriptions(options?: any): AxiosPromise<void> {
            return localVarFp.getMasterTemplateDescriptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMasterTemplates(options?: any): AxiosPromise<Array<TemplateDto>> {
            return localVarFp.getMasterTemplates(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplate1(id: string, options?: any): AxiosPromise<TemplateDto> {
            return localVarFp.getTemplate1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateLayers1(id: string, options?: any): AxiosPromise<Array<Layer>> {
            return localVarFp.getTemplateLayers1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateRenders1(id: string, options?: any): AxiosPromise<Array<RenderDto>> {
            return localVarFp.getTemplateRenders1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateThumbnail(id: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getTemplateThumbnail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveToFolder(id: string, folderId: string, options?: any): AxiosPromise<void> {
            return localVarFp.moveToFolder(id, folderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenAllMasterThumbnails(options?: any): AxiosPromise<void> {
            return localVarFp.regenAllMasterThumbnails(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTemplate(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleMasterTemplate(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.toggleMasterTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {TemplateDto} templateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplate(id: string, templateDto: TemplateDto, options?: any): AxiosPromise<TemplateDto> {
            return localVarFp.updateTemplate(id, templateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TemplateResourceApi - object-oriented interface
 * @export
 * @class TemplateResourceApi
 * @extends {BaseAPI}
 */
export class TemplateResourceApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateResourceApi
     */
    public copyTemplate(id: string, options?: AxiosRequestConfig) {
        return TemplateResourceApiFp(this.configuration).copyTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TemplateDto} templateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateResourceApi
     */
    public createTemplate(templateDto: TemplateDto, options?: AxiosRequestConfig) {
        return TemplateResourceApiFp(this.configuration).createTemplate(templateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} masterId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateResourceApi
     */
    public createTemplateFromMaster(masterId: string, userId: string, options?: AxiosRequestConfig) {
        return TemplateResourceApiFp(this.configuration).createTemplateFromMaster(masterId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateResourceApi
     */
    public getMasterTemplateByName(name: string, options?: AxiosRequestConfig) {
        return TemplateResourceApiFp(this.configuration).getMasterTemplateByName(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateResourceApi
     */
    public getMasterTemplateCategoryAndTags(options?: AxiosRequestConfig) {
        return TemplateResourceApiFp(this.configuration).getMasterTemplateCategoryAndTags(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateResourceApi
     */
    public getMasterTemplateDescriptions(options?: AxiosRequestConfig) {
        return TemplateResourceApiFp(this.configuration).getMasterTemplateDescriptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateResourceApi
     */
    public getMasterTemplates(options?: AxiosRequestConfig) {
        return TemplateResourceApiFp(this.configuration).getMasterTemplates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateResourceApi
     */
    public getTemplate1(id: string, options?: AxiosRequestConfig) {
        return TemplateResourceApiFp(this.configuration).getTemplate1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateResourceApi
     */
    public getTemplateLayers1(id: string, options?: AxiosRequestConfig) {
        return TemplateResourceApiFp(this.configuration).getTemplateLayers1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateResourceApi
     */
    public getTemplateRenders1(id: string, options?: AxiosRequestConfig) {
        return TemplateResourceApiFp(this.configuration).getTemplateRenders1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateResourceApi
     */
    public getTemplateThumbnail(id: string, options?: AxiosRequestConfig) {
        return TemplateResourceApiFp(this.configuration).getTemplateThumbnail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} folderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateResourceApi
     */
    public moveToFolder(id: string, folderId: string, options?: AxiosRequestConfig) {
        return TemplateResourceApiFp(this.configuration).moveToFolder(id, folderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateResourceApi
     */
    public regenAllMasterThumbnails(options?: AxiosRequestConfig) {
        return TemplateResourceApiFp(this.configuration).regenAllMasterThumbnails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateResourceApi
     */
    public removeTemplate(id: string, options?: AxiosRequestConfig) {
        return TemplateResourceApiFp(this.configuration).removeTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateResourceApi
     */
    public toggleMasterTemplate(id: string, options?: AxiosRequestConfig) {
        return TemplateResourceApiFp(this.configuration).toggleMasterTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {TemplateDto} templateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateResourceApi
     */
    public updateTemplate(id: string, templateDto: TemplateDto, options?: AxiosRequestConfig) {
        return TemplateResourceApiFp(this.configuration).updateTemplate(id, templateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserResourceApi - axios parameter creator
 * @export
 */
export const UserResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {EmbedConfigDto} embedConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmbedConfig: async (id: string, embedConfigDto: EmbedConfigDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createEmbedConfig', 'id', id)
            // verify required parameter 'embedConfigDto' is not null or undefined
            assertParamExists('createEmbedConfig', 'embedConfigDto', embedConfigDto)
            const localVarPath = `/users/{id}/embed-config`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(embedConfigDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserDto} userDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (userDto: UserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userDto' is not null or undefined
            assertParamExists('createUser', 'userDto', userDto)
            const localVarPath = `/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} configId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmbedConfig: async (id: string, configId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteEmbedConfig', 'id', id)
            // verify required parameter 'configId' is not null or undefined
            assertParamExists('deleteEmbedConfig', 'configId', configId)
            const localVarPath = `/users/{id}/embed-config/{configId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"configId"}}`, encodeURIComponent(String(configId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmbedConfig: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEmbedConfig', 'id', id)
            const localVarPath = `/users/{id}/embed-config`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUser', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserFolders: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserFolders', 'id', id)
            const localVarPath = `/users/{id}/folders`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserFonts: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserFonts', 'id', id)
            const localVarPath = `/users/{id}/fonts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRenders: async (id: string, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserRenders', 'id', id)
            const localVarPath = `/users/{id}/renders`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserTemplates: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserTemplates', 'id', id)
            const localVarPath = `/users/{id}/templates`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserUploads: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserUploads', 'id', id)
            const localVarPath = `/users/{id}/uploads`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} configId 
         * @param {EmbedConfigDto} embedConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmbedConfig: async (id: string, configId: string, embedConfigDto: EmbedConfigDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEmbedConfig', 'id', id)
            // verify required parameter 'configId' is not null or undefined
            assertParamExists('updateEmbedConfig', 'configId', configId)
            // verify required parameter 'embedConfigDto' is not null or undefined
            assertParamExists('updateEmbedConfig', 'embedConfigDto', embedConfigDto)
            const localVarPath = `/users/{id}/embed-config/{configId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"configId"}}`, encodeURIComponent(String(configId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(embedConfigDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UserDto} userDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (id: string, userDto: UserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUser', 'id', id)
            // verify required parameter 'userDto' is not null or undefined
            assertParamExists('updateUser', 'userDto', userDto)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upload1: async (userId: string, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('upload1', 'userId', userId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('upload1', 'file', file)
            const localVarPath = `/users/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFont: async (userId: string, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('uploadFont', 'userId', userId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadFont', 'file', file)
            const localVarPath = `/users/upload/font`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserResourceApi - functional programming interface
 * @export
 */
export const UserResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {EmbedConfigDto} embedConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEmbedConfig(id: string, embedConfigDto: EmbedConfigDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmbedConfigDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEmbedConfig(id, embedConfigDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserDto} userDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(userDto: UserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(userDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} configId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEmbedConfig(id: string, configId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEmbedConfig(id, configId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmbedConfig(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmbedConfigDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmbedConfig(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserFolders(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FolderDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserFolders(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserFonts(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FontDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserFonts(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserRenders(id: string, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RenderDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserRenders(id, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserTemplates(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TemplateDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserTemplates(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserUploads(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UploadDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserUploads(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} configId 
         * @param {EmbedConfigDto} embedConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmbedConfig(id: string, configId: string, embedConfigDto: EmbedConfigDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmbedConfigDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmbedConfig(id, configId, embedConfigDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UserDto} userDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(id: string, userDto: UserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(id, userDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upload1(userId: string, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upload1(userId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFont(userId: string, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FontDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFont(userId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserResourceApi - factory interface
 * @export
 */
export const UserResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserResourceApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {EmbedConfigDto} embedConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmbedConfig(id: string, embedConfigDto: EmbedConfigDto, options?: any): AxiosPromise<EmbedConfigDto> {
            return localVarFp.createEmbedConfig(id, embedConfigDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserDto} userDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(userDto: UserDto, options?: any): AxiosPromise<UserDto> {
            return localVarFp.createUser(userDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} configId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmbedConfig(id: string, configId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteEmbedConfig(id, configId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmbedConfig(id: string, options?: any): AxiosPromise<EmbedConfigDto> {
            return localVarFp.getEmbedConfig(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(id: string, options?: any): AxiosPromise<UserDto> {
            return localVarFp.getUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserFolders(id: string, options?: any): AxiosPromise<Array<FolderDto>> {
            return localVarFp.getUserFolders(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserFonts(id: string, options?: any): AxiosPromise<Array<FontDto>> {
            return localVarFp.getUserFonts(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRenders(id: string, page?: number, limit?: number, options?: any): AxiosPromise<Array<RenderDto>> {
            return localVarFp.getUserRenders(id, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserTemplates(id: string, options?: any): AxiosPromise<Array<TemplateDto>> {
            return localVarFp.getUserTemplates(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserUploads(id: string, options?: any): AxiosPromise<Array<UploadDto>> {
            return localVarFp.getUserUploads(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} configId 
         * @param {EmbedConfigDto} embedConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmbedConfig(id: string, configId: string, embedConfigDto: EmbedConfigDto, options?: any): AxiosPromise<EmbedConfigDto> {
            return localVarFp.updateEmbedConfig(id, configId, embedConfigDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UserDto} userDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(id: string, userDto: UserDto, options?: any): AxiosPromise<UserDto> {
            return localVarFp.updateUser(id, userDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upload1(userId: string, file: any, options?: any): AxiosPromise<UploadDto> {
            return localVarFp.upload1(userId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFont(userId: string, file: any, options?: any): AxiosPromise<FontDto> {
            return localVarFp.uploadFont(userId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserResourceApi - object-oriented interface
 * @export
 * @class UserResourceApi
 * @extends {BaseAPI}
 */
export class UserResourceApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {EmbedConfigDto} embedConfigDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserResourceApi
     */
    public createEmbedConfig(id: string, embedConfigDto: EmbedConfigDto, options?: AxiosRequestConfig) {
        return UserResourceApiFp(this.configuration).createEmbedConfig(id, embedConfigDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserDto} userDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserResourceApi
     */
    public createUser(userDto: UserDto, options?: AxiosRequestConfig) {
        return UserResourceApiFp(this.configuration).createUser(userDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} configId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserResourceApi
     */
    public deleteEmbedConfig(id: string, configId: string, options?: AxiosRequestConfig) {
        return UserResourceApiFp(this.configuration).deleteEmbedConfig(id, configId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserResourceApi
     */
    public getEmbedConfig(id: string, options?: AxiosRequestConfig) {
        return UserResourceApiFp(this.configuration).getEmbedConfig(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserResourceApi
     */
    public getUser(id: string, options?: AxiosRequestConfig) {
        return UserResourceApiFp(this.configuration).getUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserResourceApi
     */
    public getUserFolders(id: string, options?: AxiosRequestConfig) {
        return UserResourceApiFp(this.configuration).getUserFolders(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserResourceApi
     */
    public getUserFonts(id: string, options?: AxiosRequestConfig) {
        return UserResourceApiFp(this.configuration).getUserFonts(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserResourceApi
     */
    public getUserRenders(id: string, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return UserResourceApiFp(this.configuration).getUserRenders(id, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserResourceApi
     */
    public getUserTemplates(id: string, options?: AxiosRequestConfig) {
        return UserResourceApiFp(this.configuration).getUserTemplates(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserResourceApi
     */
    public getUserUploads(id: string, options?: AxiosRequestConfig) {
        return UserResourceApiFp(this.configuration).getUserUploads(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} configId 
     * @param {EmbedConfigDto} embedConfigDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserResourceApi
     */
    public updateEmbedConfig(id: string, configId: string, embedConfigDto: EmbedConfigDto, options?: AxiosRequestConfig) {
        return UserResourceApiFp(this.configuration).updateEmbedConfig(id, configId, embedConfigDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UserDto} userDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserResourceApi
     */
    public updateUser(id: string, userDto: UserDto, options?: AxiosRequestConfig) {
        return UserResourceApiFp(this.configuration).updateUser(id, userDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserResourceApi
     */
    public upload1(userId: string, file: any, options?: AxiosRequestConfig) {
        return UserResourceApiFp(this.configuration).upload1(userId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserResourceApi
     */
    public uploadFont(userId: string, file: any, options?: AxiosRequestConfig) {
        return UserResourceApiFp(this.configuration).uploadFont(userId, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WebhookResourceApi - axios parameter creator
 * @export
 */
export const WebhookResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} stripeSignature 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleStripeWebhook: async (stripeSignature: string, body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stripeSignature' is not null or undefined
            assertParamExists('handleStripeWebhook', 'stripeSignature', stripeSignature)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('handleStripeWebhook', 'body', body)
            const localVarPath = `/webhooks/stripe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stripeSignature !== undefined && stripeSignature !== null) {
                localVarHeaderParameter['Stripe-Signature'] = String(stripeSignature);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebhookResourceApi - functional programming interface
 * @export
 */
export const WebhookResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebhookResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} stripeSignature 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleStripeWebhook(stripeSignature: string, body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleStripeWebhook(stripeSignature, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebhookResourceApi - factory interface
 * @export
 */
export const WebhookResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebhookResourceApiFp(configuration)
    return {
        /**
         * 
         * @param {string} stripeSignature 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleStripeWebhook(stripeSignature: string, body: string, options?: any): AxiosPromise<string> {
            return localVarFp.handleStripeWebhook(stripeSignature, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebhookResourceApi - object-oriented interface
 * @export
 * @class WebhookResourceApi
 * @extends {BaseAPI}
 */
export class WebhookResourceApi extends BaseAPI {
    /**
     * 
     * @param {string} stripeSignature 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhookResourceApi
     */
    public handleStripeWebhook(stripeSignature: string, body: string, options?: AxiosRequestConfig) {
        return WebhookResourceApiFp(this.configuration).handleStripeWebhook(stripeSignature, body, options).then((request) => request(this.axios, this.basePath));
    }
}


