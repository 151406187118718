import { AxiosPromise } from "axios";
import { AuthResourceApi, LoginDto, GoogleAuthDto, AuthDto, UserDto } from "../generated/edithor-client";
import { apiConfig, ops } from "./config";
import Storage from "../util/Storage";

const api = new AuthResourceApi(apiConfig);

const AuthApi = {
  signup(dto: UserDto): AxiosPromise<AuthDto> {
    return api.signup(dto, ops());
  },
  login(dto: LoginDto): AxiosPromise<AuthDto> {
    return api.login(dto, ops());
  },
  googleAuth(dto: GoogleAuthDto): AxiosPromise<AuthDto> {
    return api.googleAuth(dto, ops());
  },
  logout() {
    Storage.removeToken();
  },
  auth(): AxiosPromise<UserDto> {
    return api.auth(ops());
  },
  resetPassword(email: string): AxiosPromise<void> {
    return api.resetPassword({email}, ops());
  },
};

export default AuthApi;
