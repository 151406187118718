import { Crisp } from "crisp-sdk-web";
import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import AuthApi from "../../api/AuthApi";
import { showSurveyState, tourState, userState } from "../../state/atoms";
import Storage from "../../util/Storage";
import Logo from "../Logo";
import AccountPage from "./AccountPage";
import UpgradePage from "./UpgradePage";
import UserTemplatesPage from "./UserTemplatesPage";
import DashboardHeader from "../DashboardHeader";
import DashboardAside from "../DashboardAside";
import APIIntegrationPage from "./APIIntegrationPage";
import NoCodeIntegrationPage from "./NoCodeIntegrationPage";
import { Helmet } from "react-helmet";
import UserRendersPage from "./UserRendersPage";
import Joyride, { CallBackProps } from "react-joyride";
import EmbedConfigPage from "./EmbedConfigPage";
import TypeformSurvey from "../TypeformSurvey";
import { usePostHog } from "posthog-js/react";

export interface DashboardPageProps {
  isAppLoading: boolean,
  accessRemoved: boolean
}

export default function DashboardPage({ isAppLoading, accessRemoved }: DashboardPageProps) {
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userState);
  const [tour, setTour] = useRecoilState(tourState);
  const [showSurvey, setShowSurvey] = useRecoilState(showSurveyState);
  const posthog = usePostHog();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (Storage.getToken()) {
      // setAppLoading(true);

      if (!user.id) {
        AuthApi.auth().then(resp => {
          if (resp.data) {
            const user = resp.data
            if (user.id) {
              setUser(user);

              // Identify the user on Fullstory
              // @ts-ignore
              // window['FS']('setIdentity', {
              //   uid: user.id,
              //   properties: {
              //     displayName: user.name,
              //     email: user.email,
              //     api_usage: user.apiUsage,
              //     api_quota: user.apiQuota,
              //     plan: user.plan,
              //     created_at: user.createdAt,
              //   },
              //   schema: {
              //     properties: {
              //       createdAt: 'date',
              //     },
              //   },
              // });

              // Identify the user on PostHog
              posthog.identify(user.id, {
                name: user.name,
                email: user.email,
                api_usage: user.apiUsage,
                api_quota: user.apiQuota,
                plan: user.plan,
                created_at: user.createdAt,
              });
            }
          } else {
            navigate('/signin');
          }
        }).catch(e => {
          AuthApi.logout();
          navigate('/signin');
        })
      }
    } else {
      navigate('/signin');
    };
  });

  useEffect(() => {
    if (user.id) {
      try {
        Crisp.user.setEmail(user.email!);
        Crisp.user.setNickname(user.name!);
        Crisp.session.setData({
          id: user.id,
          plan: user.plan || "",
          api_usage: user.apiUsage!,
          api_quota: user.apiQuota!
        });
      } catch (err) {
        console.error(err)
      }
    }
  });

  const tourSteps = [
    {
      target: ".create-template-btn",
      content: <>
        Welcome to Templated!<br /><br />

        Start here by creating a template that will be the base design to be automated.
      </>,
      disableBeacon: true,
    },
    {
      target: ".dashboard-aside .api-integration",
      content: "Then here you will find how to integrate with our API.",
      disableBeacon: true,
    },
    {
      target: ".api-key-container",
      content: <>
        That is your <b className="font-mono">API KEY</b>. <br />
        Store it safely and use it with the API and generate your images and PDFs.
      </>,
      disableBeacon: true,
    },
    {
      target: ".dashboard-aside .templates",
      content: <>
        And here you can find, create and manage your templates.<br /><br />

        That's it!<br />
        Happy automating!
      </>,
      disableBeacon: true,
    },]

  const handleTourCallback = (data: CallBackProps) => {
    const { action, index, lifecycle, type, step } = data;

    console.log("> index: " + index);
    console.log("> action: " + action);
    console.log("> type: " + type);
    console.log("> step.target: " + step.target);

    if (action === "close" || action === "stop") {
      setTour({ run: false });
      return;
    }

    if (step.target === '.dashboard-aside .api-integration') {
      navigate("/api-integration");
    } else if (step.target === '.dashboard-aside .templates') {
      navigate("/");
    }

    // if (type === "step:after" && (index === 3 || (action === "prev" && index === 3))) {
    //   setTour({ run: false });
    //   navigate("/api-integration");
    // } else if (type === "step:after" && index === 1) {
    //   if (action === "next") {
    //     setTour({ run: false });
    //     navigate("/");
    //   } else {
    //     navigate("/");
    //     setTour({ run: true, stepIndex: 0 });
    //   }
    // } else if (action === "reset" || lifecycle === "complete") {
    //   setTour({ run: false, stepIndex: 0, tourActive: false });
    // }
  };

  return isAppLoading ? (
    <div className={"app-loading"}>
      <Logo />
    </div>
  ) : (
    <div className="flex h-screen">
      <Helmet>
        <title>Home | Templated</title>
      </Helmet>

      <Joyride
        steps={tourSteps}
        continuous={true}
        run={tour.run}
        disableScrollParentFix={true}
        disableScrolling={true}
        callback={handleTourCallback}
        showProgress={true}
        locale={{ last: "Start automating" }}
        styles={{
          tooltipContainer: {
            textAlign: "left",
          },
        }}
      // getHelpers={(helpers) => {
      //   setTourState(helpers);
      //   // window.joyrideHelpers = helpers;
      // }}
      />

      <DashboardAside />

      <div className="flex-1 flex flex-col overflow-hidden">
        <DashboardHeader />

        {showSurvey && user && user.name && <TypeformSurvey user={user} />}
        {/* {user && user.name && <TypeformSurvey user={user}/>} */}

        <div className="p-6 relative overflow-y-auto overflow-x-hidden">
          <Routes>
            <Route index element={<UserTemplatesPage isMobile={isMobile} />} />
            <Route path="/templates" element={<UserTemplatesPage isMobile={isMobile} />} />
            <Route path="/renders" element={<UserRendersPage />} />
            <Route path="/upgrade" element={<UpgradePage />} />
            <Route path="/account" element={<AccountPage />} />
            <Route path="/api-integration" element={<APIIntegrationPage />} />
            <Route path="/no-code-integration" element={<NoCodeIntegrationPage />} />
            <Route path="/embed" element={<EmbedConfigPage />} />

            {/* <Route index element={<DashboardHome />} />
            <Route path="profile" element={<UserProfile />} />
            <Route path="settings" element={<DashboardSettings />} /> */}
          </Routes>
        </div>
      </div>
      {/* </main > */}
    </div>
  );
}
