import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
// import "antd/dist/antd.css";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import App from "./App";
import Loader from "./components/AppLoader";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { PostHogProvider } from 'posthog-js/react'

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>

  <BrowserRouter>
    <Suspense fallback={<Loader />}>
      <RecoilRoot>
        {/* <StyleProvider hashPriority="low"> */}
        <GoogleOAuthProvider clientId="94620694793-8oc2ursh6799o7ppe9mo0flgm5bnpjb7.apps.googleusercontent.com">
          <PostHogProvider apiKey={"phc_tpo00e3tU7rWXQpek8pKtv3nDqeel8tpWpOOWY8iXjq"} options={{ api_host: "https://us.i.posthog.com" }}>
            <App />
          </PostHogProvider>
        </GoogleOAuthProvider>
        {/* </StyleProvider> */}
      </RecoilRoot>
    </Suspense>
  </BrowserRouter>
  // </React.StrictMode>
);