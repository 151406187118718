export const env = {
  env: 'prod',
  debug: false,
  sitePath: "https://templated.io",
  apiPath: "https://api.templated.io",
  s3Path: "https://templated-assets.s3.amazonaws.com/",
  plans: {
    hobby: {
      monthlyId: "price_1PiLifCL60mvOF8rMu6K86iv",
      yearlyId: "price_1PiLifCL60mvOF8rewSytgJq"
    },
    starter: {
      monthlyId: "price_1OUcrqCL60mvOF8rwlrwjQGr",
      yearlyId: "price_1O7gsxCL60mvOF8r813yR4Zt"
    },
    scale: {
      monthlyId: "price_1O7gt1CL60mvOF8rXKcImV0P",
      yearlyId: "price_1O7gt1CL60mvOF8rGHaypa1y"
    },
    enterprise: {
      monthlyId: "price_1O7gt2CL60mvOF8rZ73HB3Zy",
      yearlyId: "price_1O7gt2CL60mvOF8rN4jf2YCl"
    }
  }
};
