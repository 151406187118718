import { LoadingOutlined } from "@ant-design/icons";
import { IconCloudUpload } from "@tabler/icons-react";
import { Flex, message } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { useEffect, useRef, useState } from "react";
import Masonry from "react-responsive-masonry";
import { useRecoilState } from "recoil";
import UserApi from "../../api/UserApi";
import { env } from "../../env/env";
import { UploadDto } from "../../generated/edithor-client";
import { userState } from "../../state/atoms";
import "./AsideImages.scss";


export interface Props {
  addComponent: any;
}

export default function AsideUploads({ addComponent }: Props) {
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [user, setUser] = useRecoilState(userState);
  const [uploads, setUploads] = useState([] as UploadDto[]);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    loadUploads();
  }, []);

  function loadUploads() {
    setLoading(true);

    UserApi.getUserUploads(user.id!).then((resp) => {
      setUploads(resp.data);
      setLoading(false);
    });
  }

  async function addImageComponent(ev: any, image: any) {
    addComponent(ev, "image");
  }

  function upload(file: File) {
    const isImage = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp' || file.type === 'image/svg+xml';
    if (!isImage) {
      messageApi.open({
        key: 'uploading',
        type: 'warning',
        content: 'You can only upload image files.',
        duration: 3,
      });
      return;
    }

    if (!(file.size / 1024 / 1024 < 15)) {
      messageApi.open({
        key: 'uploading',
        type: 'warning',
        content: 'File must smaller than 15MB.',
        duration: 3,
      });
      return;
    }

    messageApi.open({
      key: 'uploading',
      type: 'loading',
      content: <>Uploading <b>{file.name}</b> file... <br /></>,
      duration: 0,
    });

    setUploading(true);

    UserApi.upload(user.id!, file).then(resp => {
      setUploads([resp.data, ...uploads]);

      messageApi.open({
        key: 'uploading',
        type: 'success',
        content: <><b>{file.name}</b> file uploaded successfully.<br /></>,
        duration: 3,
      });
      setUploading(false);
    });
  }

  return (
    <div className="aside-images">
      {contextHolder}

      <Flex vertical gap={12} style={{ height: "100%" }}>
        <Dragger {...{
          name: 'file',
          multiple: false,
          className: "",
          disabled: uploading,
          uploading: uploading,
          accept: "image/png, image/jpeg, image/webp, image/svg+xml",
          rootClassName: "!text-white !hover:text-primary",
          showUploadList: false,
          // action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
          beforeUpload: (file: File) => {
            upload(file);
            return false;
          },
        }}>
          <div className="flex flex-col align-center justify-center">
            <p className="flex align-center justify-center mb-2">
              <IconCloudUpload stroke={1.5} size={40} />
            </p>
            <span className="text-white text-sm">Click or drag a file to this area to upload</span>
          </div>
        </Dragger>

        <div ref={containerRef} className="scroller">
          {loading ? (
            <div>
              <LoadingOutlined />
            </div>
          ) : uploads.length !== 0 &&
          (
            <>
              <Masonry gutter="4px">
                {uploads.map((upload: any) => (
                  <div key={upload.id} className="component-template image" onClick={(ev) => addImageComponent(ev, upload)}>
                    <img src={`${env.s3Path}${upload.path}`} alt={upload.name} draggable="false" />
                  </div>
                ))}
              </Masonry>
            </>
          )}
        </div>
      </Flex>
    </div>
  );
}
