let history = [];
let index = -1;

class Entry {
  constructor(data) {
    this.id = data.id;
    this.undo = data.undo;
    this.redo = data.redo;
    this.label = data.label || data.redo;
  }
}

const push = (undo, redo, label = '') => {
  index++;
  history.splice(index, 0, new Entry({
    // id: util.UUID.get(),
    undo,
    redo,
    label
  }));
  history.length = index + 1;

  // store.dispatch(actions.setHistoryPrev(true));
  // store.dispatch(actions.setHistoryNext(false));
};

const undo = () => {
  console.log('> undoing ' + index);

  if (index < 0 || history.length === 0) {
    // store.dispatch(actions.setHistoryPrev(false));
    return;
  }

  if (index === 0) {
    // store.dispatch(actions.setHistoryPrev(false));
  }

  const entry = history[index--];

  if (entry && entry.undo) {
    entry.undo({ history: true });
    // store.dispatch(actions.setHistoryNext(true));
  }
};

const redo = () => {
  console.log('> redoing ' + index);
  const entry = history[++index];

  if (!entry) {
    index--;
    return;
  }

  if (index === history.length - 1) {
    // store.dispatch(actions.setHistoryNext(false));
  }

  if (entry.redo) {
    entry.redo({ history: true });
  }
  // store.dispatch(actions.setHistoryPrev(true));
};

const clear = () => {
  history = [];
  index = -1;
  // store.dispatch(actions.setHistoryPrev(false));
  // store.dispatch(actions.setHistoryNext(false));

};

const showHistory = () => {
  history.forEach((it, i) => console.log(`=> ${i === index ? '* ' : ''}${it.label}`));
};

window.D = window.D || {};
window.D.showHistory = showHistory;

export default {
  push,
  undo,
  redo,
  clear,
  history,
  index
}
