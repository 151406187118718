import { useEffect, useState } from "react";
import { Button, Card, Space } from "antd";
import { getCookie } from "../util/utils";
import "./CookieNotification.scss";
import { NavLink } from "react-router-dom";

export default function CookieNotification() {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (getCookie("cookiesAccepted") !== "true") {
      // setVisible(true);
    }
  }, []);

  function hide() {
    setVisible(false);
    const now = new Date();
    now.setTime(now.getTime() + 365 * 24 * 60 * 60 * 1000);
    const expiration = "expires=" + now.toUTCString();
    document.cookie = "cookiesAccepted=true;" + expiration + ";path=/";
  }

  return !visible ? null : (
    <Card className={"cookie-notification"}>
      <Space direction="vertical">
        <p>
          We use cookies to enhance your browsing experience.<br />
          By clicking "Accept" you consent to the use of cookies on this website.<br />
          Check our <NavLink to="/privacy-policy">Privacy Policy</NavLink> to learn more about how we collect and use your data.
        </p>
        <Button type="primary" ghost onClick={hide}>Agree</Button>
      </Space>
    </Card>
  );
}
