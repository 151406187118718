import { TemplateDto, RenderDto, AssetResourceApi } from "../generated/edithor-client";
import { AxiosPromise } from "axios";
import { apiConfig, ops } from "./config";

const api = new AssetResourceApi(apiConfig);

const AssetApi = {

  uploadAsset(fromUrl: string, toPath: string) {
    return api.uploadAsset(fromUrl, toPath, ops());
  },
};

export default AssetApi;
