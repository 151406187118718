import React, { ReactNode, useEffect } from 'react';
import hljs from 'highlight.js/lib/core';
import 'highlight.js/styles/tokyo-night-dark.css';
// import 'highlight.js/styles/atom-one-dark.css';
import "./CodeBlock.scss";

hljs.registerLanguage('javascript', require('highlight.js/lib/languages/javascript'));
hljs.registerLanguage('json', require('highlight.js/lib/languages/json'));

interface CodeBlockProps {
  title?: string;
  action?: ReactNode;
  language: string;
  children: string;
  className?: ReactNode;
}

const CodeBlock: React.FC<CodeBlockProps> = ({ title, action, language, children, className }) => {
  useEffect(() => {
    hljs.highlightAll();
  }, [language, children]);

  return (
    <div className={`code-block ${className}`}>
      {title &&
        <div className="title">
          {title}

          {action}
        </div>
      }
      <pre>
        <code className={`${language} ${!title ? 'no-title' : ''}`}>
          {children}
        </code>
      </pre>
    </div>
  );
};

export default CodeBlock;
