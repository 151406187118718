import { CategoryDto, CategoryResourceApi } from "../generated/edithor-client";
import { AxiosPromise } from "axios";
import { apiConfig, ops } from "./config";

const api = new CategoryResourceApi(apiConfig);

const CategoryApi = {
  getAll(): AxiosPromise<CategoryDto[]> {
    return api.getAllCategories(ops());
  },
};

export default CategoryApi;
