import { Alert, Button, Card, Col, Form, Input, Layout, Row, message } from "antd";
import { Lock, Mail } from "lucide-react";
import { useEffect, useState } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import AuthApi from "../../api/AuthApi";
import { env } from "../../env/env";
import { userState } from "../../state/atoms";
import Storage from "../../util/Storage";
import Logo from "../Logo";
import "./AuthPage.scss";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { GoogleAuthDto } from "../../generated/edithor-client";
import { Helmet } from "react-helmet";

interface SignInPageProps {
  setAppLoading: (isLoading: boolean) => void;
}
export default function SignInPage({ setAppLoading }: SignInPageProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const setUser = useSetRecoilState(userState);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [form] = Form.useForm();

  useEffect(() => {
    if (Storage.getToken()) {
      navigate('/');
    }
  }, []);

  async function handleSubmit(values: any) {
    setLoading(true);
    setAppLoading(true);

    try {
      const resp = await AuthApi.login({ email: values.email, password: values.password, admin: searchParams.get("admin") === "true" ? true : false });
      const data = resp.data;

      if (!data.token) {
        let errMsg: any = "We couldn't find that login / password combination.";
        setError(errMsg);
        setLoading(false);
        return;
      }

      if (data.token) {
        Storage.setToken(data.token);

        const user = data.user!!
        setUser(user);
      }

      setAppLoading(false);
      navigate('/');
    } catch (err) {
      console.error(err);
      setError("We couldn't find that login / password combination.");
    }

    setLoading(false);
  }

  const responseMessage = (resp: any) => {
    setLoading(true);

    const decoded = jwtDecode(resp.credential) as any;
    console.log(decoded);

    const dto = {
      idToken: resp.credential,
      // name: `${decoded.given_name} ${decoded.family_name}`,
      // googleSub: decoded.sub
    } as GoogleAuthDto;

    AuthApi.googleAuth(dto)
      .then(async resp => {
        setLoading(false);

        if (resp.data.token) {
          Storage.setToken(resp.data.token);
          navigate('/')
        }
      }).catch((err) => {
        const key = err.response.data.message;
        setLoading(false);
        message.error(key === "email.in.use" ? "This email is in use." : "Something went wrong.");
      });
  };
  
  const errorMessage = () => {
    // console.log(error);
    setLoading(false);
  };

  return (
    <div className={"signin-page"}>
      <Helmet>
        <title>Sign In | Templated</title>
        <meta name="description" content="Sign In to your Templated account" />
      </Helmet>

      <Layout>
        <Row justify="center">
          <Col xl={8} lg={8} md={10} sm={24} xs={24}>
            <Card>
              <div className="flex justify-center mb-6 items-center">
                <Logo />
              </div>

              <h3 className="mb-2 tracking-tight">Welcome back!</h3>

              <div className="flex justify-center plausible-event-name=signin">
                <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
              </div>

              <hr className="my-8" />

              <Form form={form} layout="vertical" onFinish={handleSubmit}>
                <Form.Item name="email" rules={[{ required: true, message: 'Please enter your email' }]}>
                  <Input prefix={<Mail />} placeholder="Email" size="large" />
                </Form.Item>

                <Form.Item name="password" rules={[{ required: true, message: 'Please enter your password' }]}>
                  <Input prefix={<Lock />} placeholder="password" type="password" size="large" />
                </Form.Item>

                {error && <p><Alert type="error" message={error} /></p>}

                <Form.Item>
                  <Button size="large" htmlType="submit" type="primary" loading={loading} className="w-full md:w-auto !px-10 plausible-event-name=signin">
                    Sign In
                  </Button>
                </Form.Item>
              </Form>

              <div className={"f-pass"}>
                <NavLink to={"/forgot-password"}>Forgot your password?</NavLink>
              </div>

              <footer>
                <h4>
                  Don't have an account yet?<br />
                  <NavLink to={"/signup"}>Sign up</NavLink> for a free trial with 50 API credits
                </h4>
              </footer>
            </Card>
          </Col>
        </Row>
      </Layout>
    </div>
  );
}
