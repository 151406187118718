// export const TimberFonts = [
//   { name: 'ABeeZee', label: 'ABeeZee', class: 'node_family_abeezee' },
//   {
//     name: 'Abril Fatface',
//     label: 'Abril Fatface',
//     class: 'node_family_abril_fatface',
//   },
//   { name: 'Acme', label: 'Acme', class: 'node_family_acme' },
//   {
//     name: 'Album Cover JNL',
//     label: 'Album Cover',
//     classclass: 'node_family_album_cover',
//   },
//   { name: 'Alegreya', label: 'Alegreya', classclass: 'node_family_alegreya' },
//   { name: 'Allura', label: 'Allura', classclass: 'node_family_allura' },
//   { name: 'Alperton', label: 'Alperton', classclass: 'node_family_alperton' },
//   {
//     name: 'LHF Amarillo',
//     label: 'Amarillo',
//     classclass: 'node_family_lhf-amarillo',
//   },
//   {
//     name: 'Amatic SC',
//     label: 'Amatic SC',
//     classclass: 'node_family_amatic_sc',
//   },
//   {
//     name: 'American Uncial Pro',
//     label: 'American Uncial',
//     classclass: 'node_family_american_uncial_pro',
//   },
//   { name: 'Anaconda', label: 'Anaconda', classclass: 'node_family_anaconda' },
//   {
//     name: 'Architects Daughter',
//     label: 'Architects Daughter',
//     classclass: 'node_family_architects_daughter',
//   },
//   { name: 'Assistant', label: 'Assistant', class: 'node_family_assistant' },
//   { name: 'Avalon', label: 'Avalon', class: 'node_family_avalon' },
//   { name: 'Barlow', label: 'Barlow', class: 'node_family_barlow' },
//   { name: 'Baufra', label: 'Baufra', class: 'node_family_baufra' },
//   {
//     name: 'Berkshire Swash',
//     label: 'Berkshire Swash',
//     class: 'node_family_berkshire_swash',
//   },
//   { name: 'Beth Ellen', label: 'Beth Ellen', class: 'node_family_beth_ellen' },
//   { name: 'Bodoni', label: 'Bodoni', class: 'node_family_bodoni' },
//   { name: 'Bree Serif', label: 'Bree Serif', class: 'node_family_bree_serif' },
//   {
//     name: 'Calligraffitti',
//     label: 'Calligraffitti',
//     class: 'node_family_calligraffitti',
//   },
//   { name: 'Caslon FS', label: 'Caslon', class: 'node_family_caslon' },
//   {
//     name: 'Casual Hand',
//     label: 'Casual Hand',
//     class: 'node_family_casualhand',
//   },
//   { name: 'Caveat', label: 'Caveat', class: 'node_family_caveat' },
//   {
//     name: 'Cedarville Cursive',
//     label: 'Cedarville Cursive',
//     class: 'node_family_cedarville_cursive',
//   },
//   {
//     name: 'Cheddar Gothic Slab',
//     label: 'Cheddar Gothic Slab',
//     class: 'node_family_cheddar-gothic-slab',
//   },
//   {
//     name: 'Cherry Swash',
//     label: 'Cherry Swash',
//     class: 'node_family_cherry_swash',
//   },
//   { name: 'Chilanka', label: 'Chilanka', class: 'node_family_chilanka' },
//   { name: 'Cinzel', label: 'Cinzel', class: 'node_family_cinzel' },
//   { name: 'Clarendon FS', label: 'Clarendon', class: 'node_family_clarendon' },
//   {
//     name: 'ClearGothic Serial',
//     label: 'Clear Gothic',
//     class: 'node_family_clear_gothic_serial',
//   },
//   { name: 'Comfortaa', label: 'Comfortaa', class: 'node_family_comfortaa' },
//   { name: 'Cookie', label: 'Cookie', class: 'node_family_cookie' },
//   {
//     name: 'CopperplateFS',
//     label: 'Copperplate',
//     class: 'node_family_copperplate_fs',
//   },
//   { name: 'Courgette', label: 'Courgette', class: 'node_family_courgette' },
//   {
//     name: 'Dancing Script',
//     label: 'Dancing Script',
//     class: 'node_family_dancing_script',
//   },
//   {
//     name: 'Diplomata SC',
//     label: 'Diplomata SC',
//     class: 'node_family_diplomata_sc',
//   },
//   { name: 'Domine', label: 'Domine', class: 'node_family_domine' },
//   {
//     name: 'Downward Fall',
//     label: 'Downward Fall',
//     class: 'node_family_downward-fall',
//   },
//   {
//     name: 'Engravers Roman FS',
//     label: 'Engravers Roman',
//     class: 'node_family_engravers_roman',
//   },
//   { name: 'Ewert', label: 'Ewert', class: 'node_family_ewert' },
//   { name: 'Fira Sans', label: 'Fira Sans', class: 'node_family_fira_sans' },
//   { name: 'Fjalla One', label: 'Fjalla One', class: 'node_family_fjalla_one' },
//   { name: 'Flanders', label: 'Flanders', class: 'node_family_flanders' },
//   { name: 'Florida Serial', label: 'Florida', class: 'node_family_florida' },
//   {
//     name: 'Food Truck Chalkboard',
//     label: 'Food Truck Chalkboard',
//     class: 'node_family_food-truck-chalkboard',
//   },
//   {
//     name: 'Franklin Gothic FS',
//     label: 'Franklin Gothic',
//     class: 'node_family_franklin_gothic',
//   },
//   { name: 'Function Pro', label: 'Function', class: 'node_family_function' },
//   { name: 'Futura PT', label: 'Futura PT', class: 'node_family_futura-pt' },
//   {
//     name: 'Garamond Classic FS',
//     label: 'Garamond Classic',
//     class: 'node_family_garamond_classic',
//   },
//   {
//     name: 'Give You Glory',
//     label: 'Give You Glory',
//     class: 'node_family_give_you_glory',
//   },
//   { name: 'Glasgow Serial', label: 'Glasgow', class: 'node_family_glasgow' },
//   {
//     name: 'Gloria Hallelujah',
//     label: 'Gloria Hallelujah',
//     class: 'node_family_gloria_hallelujah',
//   },
//   { name: 'Gochi Hand', label: 'Gochi Hand', class: 'node_family_gochi_hand' },
//   {
//     name: 'Goudy Trajan Pro',
//     label: 'Goudy Trajan Pro',
//     class: 'node_family_goudy-trajan-pro',
//   },
//   {
//     name: 'GoudyCatalogueFS',
//     label: 'Goudy Catalogue',
//     class: 'node_family_goudy_catalogue_fs',
//   },
//   {
//     name: 'Grand Hotel',
//     label: 'Grand Hotel',
//     class: 'node_family_grand_hotel',
//   },
//   {
//     name: 'Great Vibes',
//     label: 'Great Vibes',
//     class: 'node_family_great_vibes',
//   },
//   {
//     name: 'Handmade Dropshadow JNL',
//     label: 'Handmade Dropshadow',
//     class: 'node_family_handmade',
//   },
//   {
//     name: 'Highest Praise',
//     label: 'Highest Praise',
//     class: 'node_family_highest-praise',
//   },
//   {
//     name: 'Homemade Apple',
//     label: 'Homemade Apple',
//     class: 'node_family_homemade_apple',
//   },
//   {
//     name: 'IM Fell DW Pica',
//     label: 'IM Fell DW Pica',
//     class: 'node_family_im_fell_dw_pica',
//   },
//   {
//     name: 'Jacques Francois Shadow',
//     label: 'Jacques Francois Shadow',
//     class: 'node_family_jacques_francois_shadow',
//   },
//   {
//     name: 'Josefin Slab',
//     label: 'Josefin Slab',
//     class: 'node_family_josefin_slab',
//   },
//   { name: 'Kalam', label: 'Kalam', class: 'node_family_kalam' },
//   {
//     name: 'Kaushan Script',
//     label: 'Kaushan Script',
//     class: 'node_family_kaushan_script',
//   },
//   {
//     name: 'Kirang Haerang',
//     label: 'Kirang Haerang',
//     class: 'node_family_kirang_haerang',
//   },
//   { name: 'Lalezar', label: 'Lalezar', class: 'node_family_lalezar' },
//   { name: 'Lancelot', label: 'Lancelot', class: 'node_family_lancelot' },
//   {
//     name: 'LanstonBell',
//     label: 'Lanston Bell',
//     class: 'node_family_lanstonbell',
//   },
//   {
//     name: 'Lemon Yellow Sun',
//     label: 'Lemon Yellow Sun',
//     class: 'node_family_lemon-yellow-sun',
//   },
//   {
//     name: 'Lexend Deca',
//     label: 'Lexend Deca',
//     class: 'node_family_lexend_deca',
//   },
//   { name: 'Lily Wang', label: 'Lily Wang', class: 'node_family_lilywang' },
//   { name: 'Lobster', label: 'Lobster', class: 'node_family_lobster' },
//   { name: 'Lora', label: 'Lora', class: 'node_family_lora' },
//   {
//     name: 'Luckiest Guy',
//     label: 'Luckiest Guy',
//     class: 'node_family_luckiest_guy',
//   },
//   { name: 'Machiarge', label: 'Machiarge', class: 'node_family_machiarge' },
//   {
//     name: 'Made Waffle Soft',
//     label: 'MADE Waffle Soft',
//     class: 'node_family_made-waffle-soft',
//   },
//   {
//     name: 'Made Waffle',
//     label: 'MADE Waffle',
//     class: 'node_family_made-waffle',
//   },
//   {
//     name: 'Magnel Display',
//     label: 'Magnel Display',
//     class: 'node_family_magnel-display',
//   },
//   { name: 'Marseille', label: 'Marseille', class: 'node_family_marseille' },
//   { name: 'Marta', label: 'Marta', class: 'node_family_marta' },
//   {
//     name: 'Merriweather',
//     label: 'Merriweather',
//     class: 'node_family_merriweather',
//   },
//   { name: 'Mess Hall JNL', label: 'Mess Hall', class: 'node_family_mess_hall' },
//   {
//     name: 'MicroSquare',
//     label: 'Micro Square',
//     class: 'node_family_micro_square',
//   },
//   {
//     name: 'Midnight Chalker',
//     label: 'Midnight Chalker',
//     class: 'node_family_midnight-chalker',
//   },
//   {
//     name: 'Miltonian Tattoo',
//     label: 'Miltonian Tattoo',
//     class: 'node_family_miltonian_tattoo',
//   },
//   {
//     name: 'Mocha Mattari',
//     label: 'Mocha Mattari',
//     class: 'node_family_mocha_mattari',
//   },
//   { name: 'Modern216', label: 'Modern 216', class: 'node_family_modern216' },
//   {
//     name: 'Molly Script',
//     label: 'Molly Script',
//     class: 'node_family_molly-script',
//   },
//   { name: 'Montserrat', label: 'Montserrat', class: 'node_family_montserrat' },
//   { name: 'Museo Slab', label: 'Museo Slab', class: 'node_family_museo-slab' },
//   {
//     name: 'Neato Serif',
//     label: 'Neato Serif',
//     class: 'node_family_neato-serif',
//   },
//   { name: 'Neucha', label: 'Neucha', class: 'node_family_neucha' },
//   { name: 'Neuton', label: 'Neuton', class: 'node_family_neuton' },
//   {
//     name: 'Nexa Rust Slab Black',
//     label: 'Nexa Rust',
//     class: 'node_family_nexa-rust-slab-black',
//   },
//   { name: 'Norican', label: 'Norican', class: 'node_family_norican' },
//   { name: 'Nothing', label: 'Nothing', class: 'node_family_nothing' },
//   {
//     name: 'Noticia Text',
//     label: 'Noticia Text',
//     class: 'node_family_noticia_text',
//   },
//   {
//     name: 'Nouveau Crayon',
//     label: 'Nouveau Crayon',
//     class: 'node_family_nouveau-crayon',
//   },
//   {
//     name: 'Old Standard TT',
//     label: 'Old Standard TT',
//     class: 'node_family_old_standard_tt',
//   },
//   {
//     name: 'Open Sans Condensed',
//     label: 'Open Sans Condensed',
//     class: 'node_family_open_sans_condensed',
//   },
//   { name: 'Openface FS', label: 'Openface', class: 'node_family_openface' },
//   { name: 'PT Mono', label: 'PT Mono', class: 'node_family_pt_mono' },
//   { name: 'Pacifico', label: 'Pacifico', class: 'node_family_pacifico' },
//   { name: 'Palladio FS', label: 'Palladio', class: 'node_family_palladio' },
//   { name: 'Parisienne', label: 'Parisienne', class: 'node_family_parisienne' },
//   {
//     name: 'Pathway Gothic One',
//     label: 'Pathway Gothic One',
//     class: 'node_family_pathway_gothic_one',
//   },
//   {
//     name: 'Patrick Hand',
//     label: 'Patrick Hand',
//     class: 'node_family_patrick_hand',
//   },
//   {
//     name: 'Petit Formal Script',
//     label: 'Petit Formal Script',
//     class: 'node_family_petit_formal_script',
//   },
//   {
//     name: 'Playfair Display',
//     label: 'Playfair Display',
//     class: 'node_family_playfair_display',
//   },
//   { name: 'Poiret One', label: 'Poiret One', class: 'node_family_poiret_one' },
//   { name: 'Prata', label: 'Prata', class: 'node_family_prata' },
//   {
//     name: 'Revista Script',
//     label: 'Revista Script',
//     class: 'node_family_revista-script',
//   },
//   { name: 'Rock Salt', label: 'Rock Salt', class: 'node_family_rock_salt' },
//   { name: 'Rockingham', label: 'Rockingham', class: 'node_family_rockingham' },
//   { name: 'Rokkitt', label: 'Rokkitt', class: 'node_family_rokkitt' },
//   {
//     name: 'Roman Wood Type JNL',
//     label: 'Roman Wood',
//     class: 'node_family_roman_wood',
//   },
//   { name: 'Rookie JNL', label: 'Rookie', class: 'node_family_rookie' },
//   { name: 'Roos Display SC', label: 'Roos', class: 'node_family_roos' },
//   {
//     name: 'Rouge Script',
//     label: 'Rouge Script',
//     class: 'node_family_rouge_script',
//   },
//   { name: 'Sacramento', label: 'Sacramento', class: 'node_family_sacramento' },
//   { name: 'Sana Sans Alt', label: 'Sana', class: 'node_family_sana-sans-alt' },
//   { name: 'Satisfy', label: 'Satisfy', class: 'node_family_satisfy' },
//   {
//     name: 'Shadows Into Light',
//     label: 'Shadows Into Light',
//     class: 'node_family_shadows_into_light',
//   },
//   { name: 'Signika', label: 'Signika', class: 'node_family_signika' },
//   { name: 'Sofia', label: 'Sofia', class: 'node_family_sofia' },
//   {
//     name: 'Sonder Sans',
//     label: 'Sonder Sans',
//     class: 'node_family_sonder-sans',
//   },
//   {
//     name: 'Sonder Script',
//     label: 'Sonder Script',
//     class: 'node_family_sonder-script',
//   },
//   {
//     name: 'Special Elite',
//     label: 'Special Elite',
//     class: 'node_family_special_elite',
//   },
//   { name: 'Struktur Caps', label: 'Struktur', class: 'node_family_struktur' },
//   { name: 'Sunset Serial', label: 'Sunset', class: 'node_family_sunset' },
//   { name: 'Suranna', label: 'Suranna', class: 'node_family_suranna' },
//   { name: 'Trailmade', label: 'Trailmade', class: 'node_family_trailmade' },
//   { name: 'HT Trattoria', label: 'Trattoria', class: 'node_family_trattoria' },
//   { name: 'Tremolo', label: 'Tremolo', class: 'node_family_tremolo' },
//   { name: 'Trocchi', label: 'Trocchi', class: 'node_family_trocchi' },
//   {
//     name: 'Typewriter FS',
//     label: 'Typewriter',
//     class: 'node_family_typewriter',
//   },
//   { name: 'Ultra', label: 'Ultra', class: 'node_family_ultra' },
//   {
//     name: 'Vast Shadow',
//     label: 'Vast Shadow',
//     class: 'node_family_vast_shadow',
//   },
//   {
//     name: 'Veneto Handwriting',
//     label: 'Veneto Handwriting',
//     class: 'node_family_veneto',
//   },
//   { name: 'Vidaloka', label: 'Vidaloka', class: 'node_family_vidaloka' },
//   {
//     name: 'Volkswagen Serial',
//     label: 'Volkswagen',
//     class: 'node_family_wolkswagen',
//   },
//   { name: 'Walbaum FS', label: 'Walbaum', class: 'node_family_walbaum' },
//   { name: 'Waverly', label: 'Waverly', class: 'node_family_waverly' },
//   { name: 'Work Sans', label: 'Work Sans', class: 'node_family_work_sans' },
//   { name: 'Yeon Sung', label: 'Yeon Sung', class: 'node_family_yeon_sung' },
//   { name: 'Noto Sans', label: 'Noto Sans', class: 'node_family_noto_sans' },
//   { name: 'Noto Serif', label: 'Noto Serif', class: 'node_family_noto_serif' },
// ];

export const GoogleFonts = [
  'ABeeZee',
  'Abel',
  'Abhaya Libre',
  'Abril Fatface',
  'Aclonica',
  'Agenda-Bold',
  'Bad Script',
  'Bahiana',
  'Bahianita',
  'Bai Jamjuree',
  'Baloo 2',
  'Cabin',
  'Cabin Condensed',
  'Cabin Sketch',
  'Caesar Dressing',
  'Cagliostro',
  'Co Headline Corp Regular',
  'Damion',
  'Dancing Script',
  'Dangrek',
  'Darker Grotesque',
  'David Libre',
  'Eagle Lake',
  'East Sea Dokdo',
  'Economica',
  'Eczar',
  'El Messiri',
  'Fanwood Text',
  'Farro',
  'Fascinate',
  'Fascinate Inline',
  'Faster One',
  'GFS Didot',
  'GFS Neohellenic',
  'Gabriela',
  'Gaegu',
  'Gafata',
  'Gobold',
  'Habibi',
  'Hachi Maru Pop',
  'Halant',
  'Hammersmith One',
  'Hanalei',
  'IBM Plex Mono',
  'IBM Plex Sans',
  'IBM Plex Sans Condensed',
  'IBM Plex Serif',
  'IM Fell DW Pica',
  'Jacques Francois',
  'Jacques Francois Shadow',
  'Jaldi',
  'JetBrains Mono',
  'Jim Nightshade',
  'K2D',
  'Kadwa',
  'Kalam',
  'Kameron',
  'Kanit',
  'Lacquer',
  'Laila',
  'Lakki Reddy',
  'Lalezar',
  'Lancelot',
  'Lexend',
  'M PLUS 1p',
  'M PLUS Rounded 1c',
  'Ma Shan Zheng',
  'Macondo',
  'Macondo Swash Caps',
  'Nanum Brush Script',
  'Nanum Gothic',
  'Nanum Gothic Coding',
  'Nanum Myeongjo',
  'Nanum Pen Script',
  'Noto Sans',
  'Odibee Sans',
  'Odor Mean Chey',
  'Offside',
  'Old Standard TT',
  'Oldenburg',
  'Padauk',
  'Palanquin',
  'Palanquin Dark',
  'Pangolin',
  'Paprika',
  'Poppins',
  'Quando',
  'Quantico',
  'Quattrocento',
  'Quattrocento Sans',
  'Questrial',
  'Racing Sans One',
  'Radley',
  'Rajdhani',
  'Rakkas',
  'Raleway',
  'Sacramento',
  'Sahitya',
  'Sail',
  'Saira',
  'Saira Condensed',
  'Tajawal',
  'Tangerine',
  'Taprom',
  'Tauri',
  'Taviraj',
  'Ubuntu',
  'Ubuntu Condensed',
  'Ubuntu Mono',
  'Ultra',
  'Uncial Antiqua',
  'Vampiro One',
  'Varela',
  'Varela Round',
  'Varta',
  'Vast Shadow',
  'Walter Turncoat',
  'Warnes',
  'Wellfleet',
  'Wendy One',
  'Wire One',
  'Xanh Mono',
  'Yanone Kaffeesatz',
  'Yantramanav',
  'Yatra One',
  'Yellowtail',
  'Yeon Sung',
  'ZCOOL KuaiLe',
  'ZCOOL QingKe HuangYou',
  'ZCOOL XiaoWei',
  'Zeyada',
  'Zilla Slab',
]

// export const GoogleFonts = [
//   'ABeeZee',
//   'Abel',
//   'Abhaya Libre',
//   'Abril Fatface',
//   'Aclonica',
//   'Acme',
//   'Actor',
//   'Adamina',
//   'Advent Pro',
//   'Aguafina Script',
//   'Akronim',
//   'Aladin',
//   'Alata',
//   'Alatsi',
//   'Aldrich',
//   'Alef',
//   'Alegreya',
//   'Alegreya SC',
//   'Alegreya Sans',
//   'Alegreya Sans SC',
//   'Aleo',
//   'Alex Brush',
//   'Alfa Slab One',
//   'Alice',
//   'Alike',
//   'Alike Angular',
//   'Allan',
//   'Allerta',
//   'Allerta Stencil',
//   'Allura',
//   'Almarai',
//   'Almendra',
//   'Almendra Display',
//   'Almendra SC',
//   'Amarante',
//   'Amaranth',
//   'Amatic SC',
//   'Amethysta',
//   'Amiko',
//   'Amiri',
//   'Amita',
//   'Anaheim',
//   'Andada',
//   'Andika',
//   'Andika New Basic',
//   'Angkor',
//   'Annie Use Your Telescope',
//   'Anonymous Pro',
//   'Antic',
//   'Antic Didone',
//   'Antic Slab',
//   'Anton',
//   'Arapey',
//   'Arbutus',
//   'Arbutus Slab',
//   'Architects Daughter',
//   'Archivo',
//   'Archivo Black',
//   'Archivo Narrow',
//   'Aref Ruqaa',
//   'Arima Madurai',
//   'Arimo',
//   'Arizonia',
//   'Armata',
//   'Arsenal',
//   'Artifika',
//   'Arvo',
//   'Arya',
//   'Asap',
//   'Asap Condensed',
//   'Asar',
//   'Asset',
//   'Assistant',
//   'Astloch',
//   'Asul',
//   'Athiti',
//   'Atma',
//   'Atomic Age',
//   'Aubrey',
//   'Audiowide',
//   'Autour One',
//   'Average',
//   'Average Sans',
//   'Averia Gruesa Libre',
//   'Averia Libre',
//   'Averia Sans Libre',
//   'Averia Serif Libre',
//   'B612',
//   'B612 Mono',
//   'Bad Script',
//   'Bahiana',
//   'Bahianita',
//   'Bai Jamjuree',
//   'Baloo 2',
//   'Baloo Bhai 2',
//   'Baloo Bhaina 2',
//   'Baloo Chettan 2',
//   'Baloo Da 2',
//   'Baloo Paaji 2',
//   'Baloo Tamma 2',
//   'Baloo Tammudu 2',
//   'Baloo Thambi 2',
//   'Balsamiq Sans',
//   'Balthazar',
//   'Bangers',
//   'Barlow',
//   'Barlow Condensed',
//   'Barlow Semi Condensed',
//   'Barriecito',
//   'Barrio',
//   'Basic',
//   'Baskervville',
//   'Battambang',
//   'Baumans',
//   'Bayon',
//   'Be Vietnam',
//   'Bebas Neue',
//   'Belgrano',
//   'Bellefair',
//   'Belleza',
//   'Bellota',
//   'Bellota Text',
//   'BenchNine',
//   'Bentham',
//   'Berkshire Swash',
//   'Beth Ellen',
//   'Bevan',
//   'Big Shoulders Display',
//   'Big Shoulders Inline Display',
//   'Big Shoulders Inline Text',
//   'Big Shoulders Stencil Display',
//   'Big Shoulders Stencil Text',
//   'Big Shoulders Text',
//   'Bigelow Rules',
//   'Bigshot One',
//   'Bilbo',
//   'Bilbo Swash Caps',
//   'BioRhyme',
//   'BioRhyme Expanded',
//   'Biryani',
//   'Bitter',
//   'Black And White Picture',
//   'Black Han Sans',
//   'Black Ops One',
//   'Blinker',
//   'Bodoni Moda',
//   'Bokor',
//   'Bonbon',
//   'Boogaloo',
//   'Bowlby One',
//   'Bowlby One SC',
//   'Brawler',
//   'Bree Serif',
//   'Bubblegum Sans',
//   'Bubbler One',
//   'Buda',
//   'Buenard',
//   'Bungee',
//   'Bungee Hairline',
//   'Bungee Inline',
//   'Bungee Outline',
//   'Bungee Shade',
//   'Butcherman',
//   'Butterfly Kids',
//   'Cabin',
//   'Cabin Condensed',
//   'Cabin Sketch',
//   'Caesar Dressing',
//   'Cagliostro',
//   'Cairo',
//   'Caladea',
//   'Calistoga',
//   'Calligraffitti',
//   'Cambay',
//   'Cambo',
//   'Candal',
//   'Cantarell',
//   'Cantata One',
//   'Cantora One',
//   'Capriola',
//   'Cardo',
//   'Carme',
//   'Carrois Gothic',
//   'Carrois Gothic SC',
//   'Carter One',
//   'Castoro',
//   'Catamaran',
//   'Caudex',
//   'Caveat',
//   'Caveat Brush',
//   'Cedarville Cursive',
//   'Ceviche One',
//   'Chakra Petch',
//   'Changa',
//   'Changa One',
//   'Chango',
//   'Charm',
//   'Charmonman',
//   'Chathura',
//   'Chau Philomene One',
//   'Chela One',
//   'Chelsea Market',
//   'Chenla',
//   'Cherry Cream Soda',
//   'Cherry Swash',
//   'Chewy',
//   'Chicle',
//   'Chilanka',
//   'Chivo',
//   'Chonburi',
//   'Cinzel',
//   'Cinzel Decorative',
//   'Clicker Script',
//   'Coda',
//   'Coda Caption',
//   'Codystar',
//   'Coiny',
//   'Combo',
//   'Comfortaa',
//   'Comic Neue',
//   'Coming Soon',
//   'Commissioner',
//   'Concert One',
//   'Condiment',
//   'Content',
//   'Contrail One',
//   'Convergence',
//   'Cookie',
//   'Copse',
//   'Corben',
//   'Cormorant',
//   'Cormorant Garamond',
//   'Cormorant Infant',
//   'Cormorant SC',
//   'Cormorant Unicase',
//   'Cormorant Upright',
//   'Courgette',
//   'Courier Prime',
//   'Cousine',
//   'Coustard',
//   'Covered By Your Grace',
//   'Crafty Girls',
//   'Creepster',
//   'Crete Round',
//   'Crimson Pro',
//   'Crimson Text',
//   'Croissant One',
//   'Crushed',
//   'Cuprum',
//   'Cute Font',
//   'Cutive',
//   'Cutive Mono',
//   'DM Mono',
//   'DM Sans',
//   'DM Serif Display',
//   'DM Serif Text',
//   'Damion',
//   'Dancing Script',
//   'Dangrek',
//   'Darker Grotesque',
//   'David Libre',
//   'Dawning of a New Day',
//   'Days One',
//   'Dekko',
//   'Delius',
//   'Delius Swash Caps',
//   'Delius Unicase',
//   'Della Respira',
//   'Denk One',
//   'Devonshire',
//   'Dhurjati',
//   'Didact Gothic',
//   'Diplomata',
//   'Diplomata SC',
//   'Do Hyeon',
//   'Dokdo',
//   'Domine',
//   'Donegal One',
//   'Doppio One',
//   'Dorsa',
//   'Dosis',
//   'Dr Sugiyama',
//   'Duru Sans',
//   'Dynalight',
//   'EB Garamond',
//   'Eagle Lake',
//   'East Sea Dokdo',
//   'Eater',
//   'Economica',
//   'Eczar',
//   'El Messiri',
//   'Electrolize',
//   'Elsie',
//   'Elsie Swash Caps',
//   'Emblema One',
//   'Emilys Candy',
//   'Encode Sans',
//   'Encode Sans Condensed',
//   'Encode Sans Expanded',
//   'Encode Sans Semi Condensed',
//   'Encode Sans Semi Expanded',
//   'Engagement',
//   'Englebert',
//   'Enriqueta',
//   'Epilogue',
//   'Erica One',
//   'Esteban',
//   'Euphoria Script',
//   'Ewert',
//   'Exo',
//   'Exo 2',
//   'Expletus Sans',
//   'Fahkwang',
//   'Fanwood Text',
//   'Farro',
//   'Farsan',
//   'Fascinate',
//   'Fascinate Inline',
//   'Faster One',
//   'Fasthand',
//   'Fauna One',
//   'Faustina',
//   'Federant',
//   'Federo',
//   'Felipa',
//   'Fenix',
//   'Finger Paint',
//   'Fira Code',
//   'Fira Mono',
//   'Fira Sans',
//   'Fira Sans Condensed',
//   'Fira Sans Extra Condensed',
//   'Fjalla One',
//   'Fjord One',
//   'Flamenco',
//   'Flavors',
//   'Fondamento',
//   'Fontdiner Swanky',
//   'Forum',
//   'Francois One',
//   'Frank Ruhl Libre',
//   'Fraunces',
//   'Freckle Face',
//   'Fredericka the Great',
//   'Fredoka One',
//   'Freehand',
//   'Fresca',
//   'Frijole',
//   'Fruktur',
//   'Fugaz One',
//   'GFS Didot',
//   'GFS Neohellenic',
//   'Gabriela',
//   'Gaegu',
//   'Gafata',
//   'Galada',
//   'Galdeano',
//   'Galindo',
//   'Gamja Flower',
//   'Gayathri',
//   'Gelasio',
//   'Gentium Basic',
//   'Gentium Book Basic',
//   'Geo',
//   'Geostar',
//   'Geostar Fill',
//   'Germania One',
//   'Gidugu',
//   'Gilda Display',
//   'Girassol',
//   'Give You Glory',
//   'Glass Antiqua',
//   'Glegoo',
//   'Gloria Hallelujah',
//   'Goblin One',
//   'Gochi Hand',
//   'Goldman',
//   'Gorditas',
//   'Gothic A1',
//   'Gotu',
//   'Goudy Bookletter 1911',
//   'Graduate',
//   'Grand Hotel',
//   'Grandstander',
//   'Gravitas One',
//   'Great Vibes',
//   'Grenze',
//   'Grenze Gotisch',
//   'Griffy',
//   'Gruppo',
//   'Gudea',
//   'Gugi',
//   'Gupter',
//   'Gurajada',
//   'Habibi',
//   'Hachi Maru Pop',
//   'Halant',
//   'Hammersmith One',
//   'Hanalei',
//   'Hanalei Fill',
//   'Handlee',
//   'Hanuman',
//   'Happy Monkey',
//   'Harmattan',
//   'Headland One',
//   'Heebo',
//   'Henny Penny',
//   'Hepta Slab',
//   'Herr Von Muellerhoff',
//   'Hi Melody',
//   'Hind',
//   'Hind Guntur',
//   'Hind Madurai',
//   'Hind Siliguri',
//   'Hind Vadodara',
//   'Holtwood One SC',
//   'Homemade Apple',
//   'Homenaje',
//   'IBM Plex Mono',
//   'IBM Plex Sans',
//   'IBM Plex Sans Condensed',
//   'IBM Plex Serif',
//   'IM Fell DW Pica',
//   'IM Fell DW Pica SC',
//   'IM Fell Double Pica',
//   'IM Fell Double Pica SC',
//   'IM Fell English',
//   'IM Fell English SC',
//   'IM Fell French Canon',
//   'IM Fell French Canon SC',
//   'IM Fell Great Primer',
//   'IM Fell Great Primer SC',
//   'Ibarra Real Nova',
//   'Iceberg',
//   'Iceland',
//   'Imbue',
//   'Imprima',
//   'Inconsolata',
//   'Inder',
//   'Indie Flower',
//   'Inika',
//   'Inknut Antiqua',
//   'Inria Sans',
//   'Inria Serif',
//   'Inter',
//   'Irish Grover',
//   'Istok Web',
//   'Italiana',
//   'Italianno',
//   'Itim',
//   'Jacques Francois',
//   'Jacques Francois Shadow',
//   'Jaldi',
//   'JetBrains Mono',
//   'Jim Nightshade',
//   'Jockey One',
//   'Jolly Lodger',
//   'Jomhuria',
//   'Jomolhari',
//   'Josefin Sans',
//   'Josefin Slab',
//   'Jost',
//   'Joti One',
//   'Jua',
//   'Judson',
//   'Julee',
//   'Julius Sans One',
//   'Junge',
//   'Jura',
//   'Just Another Hand',
//   'Just Me Again Down Here',
//   'K2D',
//   'Kadwa',
//   'Kalam',
//   'Kameron',
//   'Kanit',
//   'Kantumruy',
//   'Karla',
//   'Karma',
//   'Katibeh',
//   'Kaushan Script',
//   'Kavivanar',
//   'Kavoon',
//   'Kdam Thmor',
//   'Keania One',
//   'Kelly Slab',
//   'Kenia',
//   'Khand',
//   'Khmer',
//   'Khula',
//   'Kirang Haerang',
//   'Kite One',
//   'Knewave',
//   'KoHo',
//   'Kodchasan',
//   'Kosugi',
//   'Kosugi Maru',
//   'Kotta One',
//   'Koulen',
//   'Kranky',
//   'Kreon',
//   'Kristi',
//   'Krona One',
//   'Krub',
//   'Kufam',
//   'Kulim Park',
//   'Kumar One',
//   'Kumar One Outline',
//   'Kumbh Sans',
//   'Kurale',
//   'La Belle Aurore',
//   'Lacquer',
//   'Laila',
//   'Lakki Reddy',
//   'Lalezar',
//   'Lancelot',
//   'Langar',
//   'Lateef',
//   'Lato',
//   'League Script',
//   'Leckerli One',
//   'Ledger',
//   'Lekton',
//   'Lemon',
//   'Lemonada',
//   'Lexend Deca',
//   'Lexend Exa',
//   'Lexend Giga',
//   'Lexend Mega',
//   'Lexend Peta',
//   'Lexend Tera',
//   'Lexend Zetta',
//   'Libre Barcode 128',
//   'Libre Barcode 128 Text',
//   'Libre Barcode 39',
//   'Libre Barcode 39 Extended',
//   'Libre Barcode 39 Extended Text',
//   'Libre Barcode 39 Text',
//   'Libre Barcode EAN13 Text',
//   'Libre Baskerville',
//   'Libre Caslon Display',
//   'Libre Caslon Text',
//   'Libre Franklin',
//   'Life Savers',
//   'Lilita One',
//   'Lily Script One',
//   'Limelight',
//   'Linden Hill',
//   'Literata',
//   'Liu Jian Mao Cao',
//   'Livvic',
//   'Lobster',
//   'Lobster Two',
//   'Londrina Outline',
//   'Londrina Shadow',
//   'Londrina Sketch',
//   'Londrina Solid',
//   'Long Cang',
//   'Lora',
//   'Love Ya Like A Sister',
//   'Loved by the King',
//   'Lovers Quarrel',
//   'Luckiest Guy',
//   'Lusitana',
//   'Lustria',
//   'M PLUS 1p',
//   'M PLUS Rounded 1c',
//   'Ma Shan Zheng',
//   'Macondo',
//   'Macondo Swash Caps',
//   'Mada',
//   'Magra',
//   'Maiden Orange',
//   'Maitree',
//   'Major Mono Display',
//   'Mako',
//   'Mali',
//   'Mallanna',
//   'Mandali',
//   'Manjari',
//   'Manrope',
//   'Mansalva',
//   'Manuale',
//   'Marcellus',
//   'Marcellus SC',
//   'Marck Script',
//   'Margarine',
//   'Markazi Text',
//   'Marko One',
//   'Marmelad',
//   'Martel',
//   'Martel Sans',
//   'Marvel',
//   'Mate',
//   'Mate SC',
//   'Maven Pro',
//   'McLaren',
//   'Meddon',
//   'MedievalSharp',
//   'Medula One',
//   'Meera Inimai',
//   'Megrim',
//   'Meie Script',
//   'Merienda',
//   'Merienda One',
//   'Merriweather',
//   'Merriweather Sans',
//   'Metal',
//   'Metal Mania',
//   'Metamorphous',
//   'Metrophobic',
//   'Michroma',
//   'Milonga',
//   'Miltonian',
//   'Miltonian Tattoo',
//   'Mina',
//   'Miniver',
//   'Miriam Libre',
//   'Mirza',
//   'Miss Fajardose',
//   'Mitr',
//   'Modak',
//   'Modern Antiqua',
//   'Mogra',
//   'Molengo',
//   'Molle',
//   'Monda',
//   'Monofett',
//   'Monoton',
//   'Monsieur La Doulaise',
//   'Montaga',
//   'Montez',
//   'Montserrat',
//   'Montserrat Alternates',
//   'Montserrat Subrayada',
//   'Moul',
//   'Moulpali',
//   'Mountains of Christmas',
//   'Mouse Memoirs',
//   'Mr Bedfort',
//   'Mr Dafoe',
//   'Mr De Haviland',
//   'Mrs Saint Delafield',
//   'Mrs Sheppards',
//   'Mukta',
//   'Mukta Mahee',
//   'Mukta Malar',
//   'Mukta Vaani',
//   'Mulish',
//   'MuseoModerno',
//   'Mystery Quest',
//   'NTR',
//   'Nanum Brush Script',
//   'Nanum Gothic',
//   'Nanum Gothic Coding',
//   'Nanum Myeongjo',
//   'Nanum Pen Script',
//   'Nerko One',
//   'Neucha',
//   'Neuton',
//   'New Rocker',
//   'News Cycle',
//   'Niconne',
//   'Niramit',
//   'Nixie One',
//   'Nobile',
//   'Nokora',
//   'Norican',
//   'Nosifer',
//   'Notable',
//   'Nothing You Could Do',
//   'Noticia Text',
//   'Noto Sans',
//   'Noto Sans HK',
//   'Noto Sans JP',
//   'Noto Sans KR',
//   'Noto Sans SC',
//   'Noto Sans TC',
//   'Noto Serif',
//   'Noto Serif JP',
//   'Noto Serif KR',
//   'Noto Serif SC',
//   'Noto Serif TC',
//   'Nova Cut',
//   'Nova Flat',
//   'Nova Mono',
//   'Nova Oval',
//   'Nova Round',
//   'Nova Script',
//   'Nova Slim',
//   'Nova Square',
//   'Numans',
//   'Nunito',
//   'Nunito Sans',
//   'Odibee Sans',
//   'Odor Mean Chey',
//   'Offside',
//   'Old Standard TT',
//   'Oldenburg',
//   'Oleo Script',
//   'Oleo Script Swash Caps',
//   'Open Sans',
//   'Open Sans Condensed',
//   'Oranienbaum',
//   'Orbitron',
//   'Oregano',
//   'Orienta',
//   'Original Surfer',
//   'Oswald',
//   'Over the Rainbow',
//   'Overlock',
//   'Overlock SC',
//   'Overpass',
//   'Overpass Mono',
//   'Ovo',
//   'Oxanium',
//   'Oxygen',
//   'Oxygen Mono',
//   'PT Mono',
//   'PT Sans',
//   'PT Sans Caption',
//   'PT Sans Narrow',
//   'PT Serif',
//   'PT Serif Caption',
//   'Pacifico',
//   'Padauk',
//   'Palanquin',
//   'Palanquin Dark',
//   'Pangolin',
//   'Paprika',
//   'Parisienne',
//   'Passero One',
//   'Passion One',
//   'Pathway Gothic One',
//   'Patrick Hand',
//   'Patrick Hand SC',
//   'Pattaya',
//   'Patua One',
//   'Pavanam',
//   'Paytone One',
//   'Peddana',
//   'Peralta',
//   'Permanent Marker',
//   'Petit Formal Script',
//   'Petrona',
//   'Philosopher',
//   'Piazzolla',
//   'Piedra',
//   'Pinyon Script',
//   'Pirata One',
//   'Plaster',
//   'Play',
//   'Playball',
//   'Playfair Display',
//   'Playfair Display SC',
//   'Podkova',
//   'Poiret One',
//   'Poller One',
//   'Poly',
//   'Pompiere',
//   'Pontano Sans',
//   'Poor Story',
//   'Poppins',
//   'Port Lligat Sans',
//   'Port Lligat Slab',
//   'Potta One',
//   'Pragati Narrow',
//   'Prata',
//   'Preahvihear',
//   'Press Start 2P',
//   'Pridi',
//   'Princess Sofia',
//   'Prociono',
//   'Prompt',
//   'Prosto One',
//   'Proza Libre',
//   'Public Sans',
//   'Puritan',
//   'Purple Purse',
//   'Quando',
//   'Quantico',
//   'Quattrocento',
//   'Quattrocento Sans',
//   'Questrial',
//   'Quicksand',
//   'Quintessential',
//   'Qwigley',
//   'Racing Sans One',
//   'Radley',
//   'Rajdhani',
//   'Rakkas',
//   'Raleway',
//   'Raleway Dots',
//   'Ramabhadra',
//   'Ramaraja',
//   'Rambla',
//   'Rammetto One',
//   'Ranchers',
//   'Rancho',
//   'Ranga',
//   'Rasa',
//   'Rationale',
//   'Ravi Prakash',
//   'Recursive',
//   'Red Hat Display',
//   'Red Hat Text',
//   'Red Rose',
//   'Redressed',
//   'Reem Kufi',
//   'Reenie Beanie',
//   'Revalia',
//   'Rhodium Libre',
//   'Ribeye',
//   'Ribeye Marrow',
//   'Righteous',
//   'Risque',
//   'Roboto',
//   'Roboto Condensed',
//   'Roboto Mono',
//   'Roboto Slab',
//   'Rochester',
//   'Rock Salt',
//   'Rokkitt',
//   'Romanesco',
//   'Ropa Sans',
//   'Rosario',
//   'Rosarivo',
//   'Rouge Script',
//   'Rowdies',
//   'Rozha One',
//   'Rubik',
//   'Rubik Mono One',
//   'Ruda',
//   'Rufina',
//   'Ruge Boogie',
//   'Ruluko',
//   'Rum Raisin',
//   'Ruslan Display',
//   'Russo One',
//   'Ruthie',
//   'Rye',
//   'Sacramento',
//   'Sahitya',
//   'Sail',
//   'Saira',
//   'Saira Condensed',
//   'Saira Extra Condensed',
//   'Saira Semi Condensed',
//   'Saira Stencil One',
//   'Salsa',
//   'Sanchez',
//   'Sancreek',
//   'Sansita',
//   'Sansita Swashed',
//   'Sarabun',
//   'Sarala',
//   'Sarina',
//   'Sarpanch',
//   'Satisfy',
//   'Sawarabi Gothic',
//   'Sawarabi Mincho',
//   'Scada',
//   'Scheherazade',
//   'Schoolbell',
//   'Scope One',
//   'Seaweed Script',
//   'Secular One',
//   'Sedgwick Ave',
//   'Sedgwick Ave Display',
//   'Sen',
//   'Sevillana',
//   'Seymour One',
//   'Shadows Into Light',
//   'Shadows Into Light Two',
//   'Shanti',
//   'Share',
//   'Share Tech',
//   'Share Tech Mono',
//   'Shojumaru',
//   'Short Stack',
//   'Shrikhand',
//   'Siemreap',
//   'Sigmar One',
//   'Signika',
//   'Signika Negative',
//   'Simonetta',
//   'Single Day',
//   'Sintony',
//   'Sirin Stencil',
//   'Six Caps',
//   'Skranji',
//   'Slabo 13px',
//   'Slabo 27px',
//   'Slackey',
//   'Smokum',
//   'Smythe',
//   'Sniglet',
//   'Snippet',
//   'Snowburst One',
//   'Sofadi One',
//   'Sofia',
//   'Solway',
//   'Song Myung',
//   'Sonsie One',
//   'Sora',
//   'Sorts Mill Goudy',
//   'Source Code Pro',
//   'Source Sans Pro',
//   'Source Serif Pro',
//   'Space Grotesk',
//   'Space Mono',
//   'Spartan',
//   'Special Elite',
//   'Spectral',
//   'Spectral SC',
//   'Spicy Rice',
//   'Spinnaker',
//   'Spirax',
//   'Squada One',
//   'Sree Krushnadevaraya',
//   'Sriracha',
//   'Srisakdi',
//   'Staatliches',
//   'Stalemate',
//   'Stalinist One',
//   'Stardos Stencil',
//   'Stint Ultra Condensed',
//   'Stint Ultra Expanded',
//   'Stoke',
//   'Strait',
//   'Stylish',
//   'Sue Ellen Francisco',
//   'Suez One',
//   'Sulphur Point',
//   'Sumana',
//   'Sunflower',
//   'Sunshiney',
//   'Supermercado One',
//   'Sura',
//   'Suranna',
//   'Suravaram',
//   'Suwannaphum',
//   'Swanky and Moo Moo',
//   'Syncopate',
//   'Syne',
//   'Syne Mono',
//   'Syne Tactile',
//   'Tajawal',
//   'Tangerine',
//   'Taprom',
//   'Tauri',
//   'Taviraj',
//   'Teko',
//   'Telex',
//   'Tenali Ramakrishna',
//   'Tenor Sans',
//   'Text Me One',
//   'Texturina',
//   'Thasadith',
//   'The Girl Next Door',
//   'Tienne',
//   'Tillana',
//   'Timmana',
//   'Tinos',
//   'Titan One',
//   'Titillium Web',
//   'Tomorrow',
//   'Trade Winds',
//   'Trirong',
//   'Trispace',
//   'Trocchi',
//   'Trochut',
//   'Trykker',
//   'Tulpen One',
//   'Turret Road',
//   'Ubuntu',
//   'Ubuntu Condensed',
//   'Ubuntu Mono',
//   'Ultra',
//   'Uncial Antiqua',
//   'Underdog',
//   'Unica One',
//   'UnifrakturCook',
//   'UnifrakturMaguntia',
//   'Unkempt',
//   'Unlock',
//   'Unna',
//   'VT323',
//   'Vampiro One',
//   'Varela',
//   'Varela Round',
//   'Varta',
//   'Vast Shadow',
//   'Vesper Libre',
//   'Viaoda Libre',
//   'Vibes',
//   'Vibur',
//   'Vidaloka',
//   'Viga',
//   'Voces',
//   'Volkhov',
//   'Vollkorn',
//   'Vollkorn SC',
//   'Voltaire',
//   'Waiting for the Sunrise',
//   'Wallpoet',
//   'Walter Turncoat',
//   'Warnes',
//   'Wellfleet',
//   'Wendy One',
//   'Wire One',
//   'Work Sans',
//   'Xanh Mono',
//   'Yanone Kaffeesatz',
//   'Yantramanav',
//   'Yatra One',
//   'Yellowtail',
//   'Yeon Sung',
//   'Yeseva One',
//   'Yesteryear',
//   'Yrsa',
//   'Yusei Magic',
//   'ZCOOL KuaiLe',
//   'ZCOOL QingKe HuangYou',
//   'ZCOOL XiaoWei',
//   'Zeyada',
//   'Zhi Mang Xing',
//   'Zilla Slab',
//   'Zilla Slab Highlight',
// ];
