import { Button, Input } from "antd";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { userState } from "../../state/atoms";
// import MembersApi from "../api/MembersApi";
import { useNavigate } from "react-router-dom";
import { homePath } from "../../util/constants";
import { LogRegLogo } from "../util/LogRegLogo";

export default function PasswordResetPage() {
  const navigate = useNavigate();

  const [user] = useRecoilState(userState);
  const [pass, setPass] = useState("");
  const [confirm, setConfirm] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPassErr, setPassErr] = useState(false);

  function onPasswordChange(password: string) {
    setPass(password);
    setPassErr(password.length < 8);
  }

  async function submit() {
    setLoading(true);
    try {
      // await MembersApi.updatePassword({
      //   email: member.email,
      //   password: pass
      // });
      navigate(homePath);
    } catch (ex) {
      setLoading(true);
      // error("Something went wrong");
    }
  }

  return (
    <div className={"forgot-page land-page"}>
      <div className="cvr" />
      <div className={"step-password step"}>
        <LogRegLogo />
        <h2>Select a new password</h2>

        <div className={"form"}>
          <div className={"blk"}>
            <label className={"lbl"}>Enter new password</label>
            <Input type={"password"} placeholder={"New password"} value={pass} size={"large"} onChange={ev => onPasswordChange(ev.target.value)} />
            <label className={`pass ${isPassErr ? 'err' : ''}`}>8+ characters with a mix of letters, numbers and symbols.</label>
          </div>

          <div className={"blk"}>
            <label className={"lbl"}>Confirm new password</label>
            <Input type={"password"} placeholder={"Confirm new password"} value={confirm} size={"large"} onChange={ev => setConfirm(ev.target.value)} />
          </div>

          <Button
            size={"large"}
            className={"primary"}
            onClick={submit}
            loading={loading}
            disabled={!pass || !confirm || pass !== confirm || isPassErr}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
}
