import * as React from "react";
import { format } from "date-fns";
import { isFirefox } from "../../util/utils.js";

interface DateTimeStampProps {
  date: number | string | Date;
  withYear?: boolean;
  lightWithYear?: boolean;
  light?: boolean;
  lightWithDay?: boolean;
  dateOnly?: boolean;
  shortDateOnly?: boolean;
}

const LIGHT = "MMM d, yyyy";
const LIGHT_WITH_DAY = "EEEE MMMM d, yyyy";
const FORMAT = "d MMM, h:mm a";
const YEAR_FORMAT = "d MMM yyyy, HH:mm";
const LIGHT_YEAR_FORMAT = "dd MMM yyyy";
const DATE_ONLY_FORMAT = "MM/dd/yyyy";
const SHORT_DATE_ONLY_FORMAT = "MM/dd/yy";
const IS_FIREFOX = isFirefox()

export default function DateTimeStamp({
  date,
  withYear,
  lightWithDay,
  light,
  dateOnly,
  lightWithYear,
  shortDateOnly,
}: DateTimeStampProps) {
  function isUnixTimestamp(timestamp: number) {
    if (typeof timestamp !== 'number') {
      return false;
    }
  
    const minUnixTimestamp = 0; // January 1, 1970 (UTC)
    const maxUnixTimestamp = 2147483647; // January 19, 2038 (UTC) - Maximum 32-bit Unix timestamp
  
    return timestamp >= minUnixTimestamp && timestamp <= maxUnixTimestamp;
  }

  const dateFormat = () => {
    if (lightWithDay) return LIGHT_WITH_DAY;
    if (light) return LIGHT;
    if (withYear) return YEAR_FORMAT;
    if (dateOnly) return DATE_ONLY_FORMAT;
    if (lightWithYear) return LIGHT_YEAR_FORMAT;
    if (shortDateOnly) return SHORT_DATE_ONLY_FORMAT;
    return FORMAT;
  };

  let val = "";
  try {
    if (date) {
      if (date.toString().length > 20 && !IS_FIREFOX) {
        date = date.toString().substring(0, 19)
      }
      if (typeof date === 'number' && isUnixTimestamp(date)) {
        date = date * 1000
      }
      val = format(new Date(date), dateFormat());
    }
  } catch (ex) {
    console.error(ex);
  }

  return <span className={"fdt"}>{val}</span>;
}
