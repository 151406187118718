import { Button, Card, Collapse, Input, Skeleton, Tooltip, message } from "antd";
import { Copy } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userState } from "../../state/atoms";
import CodeBlock from "../CodeBlock";
import { Helmet } from "react-helmet";
import { IconCopy, IconExternalLink } from "@tabler/icons-react";

export interface Props {
}

export default function NoCodeIntegrationPage({ }: Props) {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const user = useRecoilValue(userState);
  const [loading, setLoading] = useState(false);

  function copyApiKey() {
    navigator.clipboard.writeText(user.apiKey!).then(() => {
      messageApi.success("API KEY copied.");
    });
  }

  return loading || !user.apiKey ? (
    <>
      <Skeleton active paragraph={{ rows: 4 }} />
    </>
  ) : (
    <div>
      <Helmet>
        <title>No-code Integrations | Templated</title>
      </Helmet>

      {contextHolder}

      <Card className="w-[650px] mb-6">
        <p className="text-lg font-mono tracking-tighter mb-1.5">API Key</p>

        <p className="text-gray-500 leading-relaxed">
          Use your API Key to authenticate on Zapier, Make or other no-code platforms.<br />
          <span className="text-sm">Keep it private and safe.</span>
        </p>

        <div className="flex mt-3 items-center gap-2">
          <Input value={user.apiKey} readOnly size="large" className="w-96 font-mono" />
          <Tooltip title="Copy your API key">
            <Button size="large" icon={<IconCopy />} onClick={copyApiKey} />
          </Tooltip>
        </div>
      </Card>

      <div className="grid grid-cols-2 w-[650px] gap-5">
        <Card className="">
          <div className="flex items-center justify-between">
            <img src="/images/zapier-logo.png" className="inline w-36" />
            <Button type="text" icon={<IconExternalLink />} target="_blank" href="https://zapier.com/apps/templated/integrations"></Button>
          </div>

        </Card>

        <Card className="">
          <div className="flex items-center justify-between">
            <img src="/images/make-logo.png" className="inline w-36" />
            <Button type="text" icon={<IconExternalLink />} target="_blank" href="https://www.make.com/en/integrations/templated-io"></Button>
          </div>
        </Card>
      </div>

    </div>
  );
}
