import { atom } from "recoil";
import { TemplateDto, UserDto } from "../generated/edithor-client";

// export interface MemberState {
//   id: string;
//   internalId: number;
//   teamId: string;
//   logged: boolean;
//   plan: MemberDtoPlanEnum;
//   email: string;
//   name: string;
//   owner: boolean;
//   oldLoginKey: string;
// }

export interface CartState {
  menus: string[];
}

export const userState = atom({
  key: "userState",
  default: {} as UserDto
});

export const templateState = atom({
  key: "templateState",
  default: {} as TemplateDto,
});

export const tourState = atom({
  key: "tourState",
  default: {
    run: false,
    stepIndex: 0,
    steps: [],
    tourActive: false
  } as any
});

export const showSurveyState = atom({
  key: "showSurveyState",
  default: false
});
