import { AxiosPromise } from "axios";
import { PaymentResourceApi } from "../generated/edithor-client";
import { apiConfig, ops } from "./config";

const api = new PaymentResourceApi(apiConfig);

const PaymentApi = {
  createCheckoutSession(planId: string): AxiosPromise<string> {
    return api.createCheckoutSession(planId, ops());
  },

  cancelSubscription(): AxiosPromise<string> {
    return api.cancelSubscription(ops());
  },

  createPortalSession(): AxiosPromise<string> {
    return api.createPortalSession(ops());
  },
};

export default PaymentApi;