import { IconCloudDownload, IconCopy, IconCopyCheck, IconDots, IconDownload, IconEdit, IconFileTypePdf, IconFolderPlus, IconPhoto, IconSettings, IconStack2, IconStar, IconStarFilled, IconTrash } from "@tabler/icons-react";
import { Button, Card, Dropdown, Flex, Image, MenuProps, Popconfirm, Tooltip } from "antd";
import Meta from "antd/es/card/Meta";
import { AlertCircle } from "lucide-react";
import { RenderDto, TemplateDto, UserDto } from "../generated/edithor-client";
import DateTimeStamp from "./util/DateTimeStamp";
import { download } from "../util/utils";

interface Props {
  render: RenderDto;
  user: UserDto;
  loading: boolean;
  copyId: any;
  showTemplateName?: boolean;
  // remove: any;
  // downloadTemplate: any;
}

export default function RenderCard({ render, loading, copyId, showTemplateName = true }: Props) {

  function downloadRender(render: RenderDto) {
    download(`${render.url}`, render.id, () => {
    });
  }

  return (
    <div className="relative flex flex-col bg-white border rounded w-full h-fit gap-3">
      <div className="flex flex-col gap-2 border-b p-3">
        <div className="flex flex-col gap-1">
          <div className="h-fit w-full rounded flex items-center justify-center cursor-pointer bg-slate-50 p-2 border-2 transition-all ease-in-out duration-300 border-transparent hover:border-primary">
            {render.format === 'pdf' ?
              <div className="w-full h-48 flex items-center justify-center max-w-full max-h-full cursor-pointer relative">
                <a href={render.url} target="_blank" className="absolute w-full h-full z-10 cursor-pointer" rel="noopener noreferrer"></a>
                <iframe className="max-w-full max-h-full absolute border-none shadow-none" src={render.url} />
              </div>
              // <a href={render.url} target="_blank" rel="noreferrer" 
              //   className="w-full h-48 flex items-center justify-center max-w-full max-h-full">
              //   <IconFileTypePdf className="max-w-full max-h-full w-44 h-44 p-14 cursor-pointer" />
              // </a>
              :
              <Image
                className="max-w-full max-h-full"
                wrapperClassName="w-fit h-48 flex items-center justify-center max-w-full max-h-full"
                style={{ boxShadow: "0 0 40px rgba(0, 0, 0, .07)" }}
                alt={render.templateName}
                src={render.url || "/images/loading-thumbnail.gif"}
                fallback={"/images/loading-thumbnail.gif"}
                placeholder={<img src="/images/loading-thumbnail.gif" />}
              />
            }
          </div>

          <div className="mt-1 flex items-center gap-2">
            {/* <span className="text-sm leading-snug tracking-normal text-gray-400">
              {render.width} x {render.height} px
            </span> */}


          </div>
        </div>

        <div className="flex flex-col justify-between flex-1 gap-4">
          <p className="leading-snug truncate">
            {showTemplateName &&
              <>{render.templateName} <br /></>
            }

            <div className="flex items-center justify-between mt-1">
              <span className="text-gray-400"><DateTimeStamp date={render.createdAt!} withYear /></span>

              <Tooltip title="Download">
                <Button type="text" className="px-1 flex items-center" onClick={() => downloadRender(render)}>
                  <IconCloudDownload className="w-4 font-bold" />
                </Button>
              </Tooltip>

              <span className={`leading-snug tracking-normal text-xs font-medium px-2 py-0.5 rounded
                ${render.format === 'pdf' ? 'bg-blue-100 text-blue-800' : 'bg-green-100 text-green-800'}`
              }>
                {render.format?.toUpperCase()}
              </span>
            </div>
          </p>
        </div>
      </div>

      {/* <div className="flex flex-col justify-end gap-1 p-3 pt-0">
        <div className="flex justify-between gap-3">
          <div className="flex gap-3">
            <Button className="px-3 border-dashed" onClick={() => seeTemplateRenders(template)}>
              <div className="flex gap-1 items-center uppercase text-xs">
                <IconPhoto width={15} height={15} /> Renders
              </div>
            </Button>
          </div>

          <div className="flex gap-1">
            <Tooltip overlayInnerStyle={{ width: "fit-content" }} title={
              <div className="text-center w-80">
                Copy <span className="font-mono">ID</span> <br />
                <span className="font-mono font-bold">
                  {template.id}
                </span>
              </div>
            }>
              <Button type="text" className="px-1 font-bold text-lg flex items-center font-mono" onClick={() => copyId(template)}>
                ID
              </Button>
            </Tooltip>

            <Tooltip title="Layers">
              <Button type="text" className="px-1 flex items-center" onClick={() => seeTemplateLayers(template)}>
                <IconStack2 className="w-5 font-bold" />
              </Button>
            </Tooltip>

            <Tooltip title="More actions">
              <Dropdown
                overlayClassName=""
                className="p-2"
                trigger={["click"]}
                placement="topRight"
                menu={{
                  items: [
                    {
                      key: '1',
                      danger: true,
                      label: (
                        <Popconfirm
                          placement="top"
                          arrow={false}
                          title="Delete template"
                          icon={<AlertCircle style={{ color: 'red', marginRight: 10 }} width={15} />}
                          description={`Are you sure you want to delete ${template.name}?`}
                          onConfirm={() => remove(template)}
                          okText="Yes"
                          cancelText="No">
                          <Flex align="center" gap={8}>
                            <IconTrash />
                            Delete
                          </Flex>
                        </Popconfirm>
                      ),
                    },
                    {
                      key: '2',
                      label: (
                        <Popconfirm
                          placement="top"
                          arrow={false}
                          title="Duplicate template"
                          icon={false}
                          description={`Are you sure you want to duplicate ${template.name}?`}
                          onConfirm={() => copy(template)}
                          okText="Yes"
                          cancelText="No">
                          <Flex align="center" gap={8}>
                            <IconCopy />
                            Duplicate
                          </Flex>
                        </Popconfirm>
                      ),
                    },
                    {
                      key: '3',
                      disabled: true,
                      label: (
                        <Flex align="center" gap={8} onClick={() => addToFolder(template)}>
                          <IconFolderPlus />
                          Add to folder
                        </Flex>
                      ),
                    },

                    {
                      key: '4',
                      label: (
                        <Flex align="center" gap={8} onClick={() => downloadTemplate(template)}>
                          <IconCloudDownload />
                          Download
                        </Flex>
                      ),
                    },

                    {
                      key: '6',
                      style: { display: user.isAdmin ? 'block' : 'none' },
                      label: (
                        <Popconfirm
                          placement="top"
                          arrow={false}
                          title="Master"
                          icon={<AlertCircle style={{ color: 'red', marginRight: 10 }} width={15} />}
                          description={`Are you sure?`}
                          onConfirm={() => toggleMaster(template)}
                          okText="Yes"
                          cancelText="No">
                          <Flex align="center" gap={8} className="text-purple-600">
                            {template.isMaster ? <IconStarFilled /> : <IconStar />}
                            {template.isMaster ? 'Remove Master' : 'Turn Master'}
                          </Flex>
                        </Popconfirm>
                      ),
                    },

                    {
                      key: '7',
                      style: { display: user.isAdmin && template.isMaster ? 'block' : 'none' },
                      label: (
                        <Flex align="center" gap={8} onClick={() => configMasterTemplate(template)}>
                          <IconSettings />
                          Config
                        </Flex>
                      ),
                    },
                  ] as MenuProps['items']
                }}
              >
                <Button type="text" className="px-1 font-bold text-lg flex items-center font-mono">
                  <IconDots />
                </Button>
              </Dropdown>
            </Tooltip>
          </div>
        </div>
      </div> */}
    </div>
  );
}
